 	 import {useEffect} from 'react';
import './catalyst-card.css';
import people from '../../assets/avatar.png';

const CatalystCard = (props) => {
	const { item = {}, onCatalystClick } = props;

	const {catalystDetails = {}, name = "", about = ""} = item;
	const {publicName = "", profilePic = ""} = item?.profileDescription || {}

	const onClickCatalystCard = () => {
		onCatalystClick(item);
	}

	const feature_array = catalystDetails?.serviceCategory.slice(0, 4).map((item) => item.name);
	const location_array = catalystDetails?.locations.filter(item => item?.city !== "").map(item => item?.city);
	
	return (
		<>
			<div className="text-center catalyst_container" key={name} onClick = {() => onClickCatalystCard()} >
          		<img src={item?.profileDescription?.profilePic || people} className="catalyst_grid_item_img" />
	            
	            <div className="text-center w-100 catalyst_container_content_wrapper">
	            	
	            	<h5 title={publicName || name} className="capitalize heading-5 color-primary-50 text-center catalyst_title font-400">{publicName || name}</h5>
	            	<div className="body-CAP color-primary-20 capitalize text-center w-100">{feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>

	            	<div className="body-CAP color-primary-20 text-center w-100">{location_array.length > 0 && location_array.join(" | ") || "-"}</div>
	            </div>

	            {/*<div className="home_card_sub_text pt-2 color-primary-50">
	            	{about}	
	            </div>*/}
	        </div>
		</>
	)
}

export default CatalystCard;