import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker  } from 'google-maps-react';
import mapStyle from './mapStyle.js';
import {GOOGLE_API} from '../../actions/Constants';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const MapView = (props) => {
  const {serviceLocation = {} } = props;
 
  const [lat, setLat] = useState(serviceLocation?.coordinate?.lat);
  const [lng, setLng] = useState(serviceLocation?.coordinate?.lng);

  useEffect(() => {
    const {lat = 0, lng = 0} = serviceLocation.coordinate || {};

    if(lat !== 0) {
      setLat(lat);
      setLng(lng);
    }
  }, [serviceLocation?.coordinate]);

  return (
    <div 
	    style={{
			position: "relative",
			width: "100%",
			height: "500px"
		}} 
		className="map"
	>
      <Map
        google={props.google}
        zoom={14}
		    styles={mapStyle.styles}
        initialCenter={{lat: lat, lng: lng}}
      >
      	<Marker
          name={'Kenyatta International Convention Centre'}
        />
        {/*<InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>*/}
       </Map>
      </div>
    );
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API
})(MapView);

/*https://snazzymaps.com/style/15/subtle-grayscale*/