import React, {useState, useEffect} from 'react';
import UpdatePolicy from './settings-view/UpdatePolicy';
import {InitialPolicyDetails} from '../../../../utils/DefaultInitialValues';

const PolicyDetails = () => {
	const list = [
		{
			id : "1",
			policyName : "Policy 1",
		  	rescheduleType : "allowed",
		  	rescheduleList : [
			  	{
			  		reschedulePermitHr: "10",
	      			rescheduleMaxCount: "2"
	      		}
		  	],
		  	cancellationType : "allowed",
		  	cancellationList : [
		  		{
		  			cancelPermitHr: "10",
      				cancelRefundMoney: "2"
		  		}
		  	]
		},
		{
			id : "2",
			policyName : "Policy 2",
		  	rescheduleType : "allowed",
		  	rescheduleList : [
			  	{
			  		reschedulePermitHr: "10",
	      			rescheduleMaxCount: ""
	      		}
		  	],
		  	cancellationType : "allowed",
		  	cancellationList : [
		  		{
		  			cancelPermitHr: "10",
      				cancelRefundMoney: "2"
		  		}
		  	]
		},
	]

	const [isShowPolicyDialog, setShowPolicyDialog] = useState(false);
	const [policyDetails, setPolicyDetails] = useState(InitialPolicyDetails);
	const [PolicyList, setPolicyList] = useState(list);

	const addPolicy = () => {
		setPolicyDetails(InitialPolicyDetails);
		setShowPolicyDialog(true);
	}

	const onPolicyClick = (item = {}) => {
		const filterItemArray = PolicyList.filter((policy, index) => policy.id === item.id);

		if(filterItemArray.length > 0) {
			setPolicyDetails(filterItemArray[0]);
			setShowPolicyDialog(true);
		}
	} 

	const fetchPackages = () => {

	}

	const onUpdateSuccess = () => {
		fetchPackages();
	}

	useEffect(() => {
		fetchPackages();
	}, []);

	return (
		<div className="row">
			<div className="col-lg-8 m-auto">
				<div className="bg-white rounded-3 p-4 my-5">
					
					{PolicyList.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center my-5">
						<div className="color-primary-50 body-R">There is no policy added yet. Please add a policy!</div>
					</div>}
					
					<h4 className="heading-4 color-primary-50">Policy</h4>

					<div className="w-100 d-flex justify-content-center my-3">
						<button className="tertiary_btn capitalize width-50 m-auto color-primary-50" onClick={addPolicy}>Add Policy</button>
					</div>

					{PolicyList.length > 0 && PolicyList.map((policy, index) => 
						<div>
							<div className="my-1 p-2">
			                   
			                    <div className="d-flex justify-content-between align-items-center">
				                    <button className="ax-button d-flex flex-column " onClick = {() => onPolicyClick(policy)}>
				                    	<h5 className="heading-5 color-primary-50">{policy?.policyName || "-"}</h5>
					                    {policy.rescheduleList.map((item, index) => <div>  
					                      <div className="color-primary-50 body-R">{item?.reschedulePermitHr} hrs - Max reschedule {item?.rescheduleMaxCount} times</div>
					                    </div>)}

					                    {policy.cancellationList.map((item, index) => <div>  
					                      <div className="color-primary-50 body-R">{item?.cancelPermitHr} hrs - Max reschedule {item?.cancelRefundMoney} EUR</div>
					                    </div>)}
				                    </button>
				                    <div>
				                      <button className="ax-button color-secondary-100 fw-bold fs-5" onClick= {() => setPolicyDetails(policy)}>...</button>
				                    </div>
			                  	</div>
	            			</div>

	        				{(index !== PolicyList.length - 1) && <hr />}
						</div>
					)}
					
				</div>
			</div>
			{isShowPolicyDialog && <UpdatePolicy
				isOpen = {isShowPolicyDialog} 
				details = {policyDetails}
				onUpdateSuccess = {onUpdateSuccess}
				handleClose = {() => setShowPolicyDialog(false)} />}
		</div>
	)
}

export default PolicyDetails;
