import React, {useEffect, useState} from 'react';
import Dropdown from '../../../components/dropdown/Dropdown';
import {getBookingQuantityValue, isEmptyJson, getQuantity} from '../../../utils/util';

const SelectPlanView = (props) => {
  const { 
    selectedPackage = {}, 
    ticketDetails = [], 
    bookingDetails = {}, 
    setBookingDetails, 
    onTicketSelection,
    bookingList = [],
    isShowTicketError = false 
  } = props || {};

  const [ticketInfoList, setTicketsInfoList] = useState([]);
  const [pckInfoList, setPackagesInfoList] = useState([]);
  const [plansInfoList, setPlansInfoList] = useState([]);

  const [isDisableTicket, setDisableTickets] = useState(false);
  const [isDisablePackage, setDisablePackages] = useState(false);
  const [isDisablePlan, setDisablePlans] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  useEffect(() => {
    if(selectedPackage && !isEmptyJson(selectedPackage) && selectedPackage?.package?.id !== "") {
      const pcks = ticketDetails.filter(item => (item.pricingType === "package" && item?.id === selectedPackage?.package?.id));
      setPackagesInfoList(pcks);
    } else {
      const tcks  =   ticketDetails.filter(item => item.pricingType === "ticket");
      const pcks  =   ticketDetails.filter(item => item.pricingType === "package");
      const plans =   ticketDetails.filter(item => item.pricingType === "plan");

      setTicketsInfoList(tcks);
      setPackagesInfoList(pcks);
      setPlansInfoList(plans);
    }
  }, [ticketDetails]);

  useEffect(() => {
    if(Array.isArray(bookingList)) {
      const tcks  =   bookingList.filter(item => item.pricingType === "ticket");
      const pcks  =   bookingList.filter(item => item.pricingType === "package");
      const plans =   bookingList.filter(item => item.pricingType === "plan");

      setDisableTickets(tcks.length > 0);
      setDisablePackages(pcks.length > 0);
      setDisablePlans(plans.length > 0);
    }
  }, [bookingList]);

  return (
    <div className="container dialog_padding">
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="card-container">
            {ticketInfoList.length > 0 && <h5 className="heading-5 color-primary-50">Tickets</h5>}
            {ticketInfoList?.map((item, i) => 
                <>
                  <div key = {item?.packageName} className="card-view">
                    <div>
                      <div className="color-primary-50 caption-R">{item?.pricingType.toUpperCase() || "-"}</div>
                      <div className="color-primary-50 subtitle-B my-1">{item?.tickets?.ticketName || "-"}</div>
                      <div className="color-primary-50 body-R my-2">{item?.tickets.ticketDescription || "-"}</div>
                      <hr />

                      {item?.tickets?.ticketList.map((eachTicket, inx) => 
                        <>
                          <div>
                            <h5 className="body-R color-primary-50">{eachTicket?.packageName}</h5>
                          </div>

                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="heading-5 color-primary-50">
                              {eachTicket?.packagePrice} {item?.ticket?.priceCurrency || "EUR"} | {eachTicket.availableTicket}
                            </h5>
                           
                            <Dropdown
                              className = "w-100 color-primary-50"
                              placeHolder = "Quantity"
                              name = "quantity"
                              value={getQuantity(bookingList, item?.tickets.id, item?.pricingType)}
                              onSelectChange = {(name, val) => onTicketSelection(item, name, val, inx)}
                              isDisabled = {(eachTicket.availableTicket === "Sold Out") || (isDisablePackage || isDisablePlan)}
                              options = {[
                                {
                                  "label" : 0,
                                  "value" : 0
                                },
                                {
                                  "label" : 1,
                                  "value" : 1
                                }, 
                                {
                                  "label" : 2,
                                  "value" : 2
                                },
                                {
                                  "label" : 3,
                                  "value" : 3
                                },
                                {
                                  "label" : 4,
                                  "value" : 4
                                },
                                {
                                  "label" : 5,
                                  "value" : 5
                                },
                                {
                                  "label" : 6,
                                  "value" : 6
                                },
                                {
                                  "label" : 7,
                                  "value" : 7
                                }, 
                                {
                                  "label" : 8,
                                  "value" : 8
                                },
                                {
                                  "label" : 9,
                                  "value" : 9
                                },
                                {
                                  "label" : 10,
                                  "value" : 10
                                }
                              
                              ]}
                            />
                          </div>
                          
                          {/*<div className="color-primary-50 body-R">
                            + {eachTicket?.serviceFee}{eachTicket?.serviceFeeType} € service fee
                          </div>*/}

                          {inx !== (item?.tickets?.ticketList.length - 1) && <hr />}
                        </>
                      )}
                    </div>
                  </div>
              </>
            )}

            {pckInfoList.length > 0 && <h5 className="heading-5 color-primary-50">Packages</h5>}

            {pckInfoList?.map((item, i) => 
              <div key = {item?.packageName} className="card-view">
                <div>
                  <div className="color-primary-50 caption-R">{item?.pricingType.toUpperCase() || "-"}</div>
                  <div className="color-primary-50 subtitle-B my-1">{item?.packageName || "-"}</div>
                  <div className="color-primary-50 body-R my-2">{item?.packageDescription || "-"}</div>
                  <div className="color-primary-50 body-R my-2">{item?.noOfSessions} sessions - valid for {item?.packageValidityDuration || "-"} months</div>
                  <hr />
                      
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="heading-5 color-primary-50">{item?.packagePrice} {item?.priceCurrency || "EUR"} | {item.availableTicket}</h5>
                   
                    <Dropdown
                      className = "w-100"
                      placeHolder = "Quantity"
                      name = "quantity"
                      value={item.quantity}
                      onSelectChange = {(name, val) => onTicketSelection(item, name, val, i)}
                      isDisabled = {(isDisableTicket || isDisablePlan || bookingList.some(bck => bck.id !== item.id))}
                      options = {[
                        {
                          "label" : 0,
                          "value" : 0
                        },
                        {
                          "label" : 1,
                          "value" : 1
                        }
                      ]}
                    />
                  </div>
                      
                  {/*<div className="color-primary-50 body-R">
                    + {item?.serviceFee}{item?.serviceFeeType} € service fee
                  </div>*/}
                </div>
              </div>
            )}

            {plansInfoList.length > 0 && <h5 className="heading-5 color-primary-50">Plans</h5>}

            {plansInfoList?.map((item, i) =>
              <div key = {item?.id} className="card-view">
                <div>
                  <div className="color-primary-50 caption-R">{item?.pricingType.toUpperCase() || "-"}</div>
                  <div className="color-primary-50 subtitle-B my-1">{item?.planName || "-"}</div>
                  <div className="color-primary-50 body-R my-2">{item?.planDescription || "-"}</div>
                  <div className="color-primary-50 body-R my-2">{item?.planSessionPerMonth} monthly sessions - valid for {item?.planValidityDuration || "-"} months</div>
                  <hr />
                      

                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="heading-5 color-primary-50">{item?.planPrice} {item?.priceCurrency || "EUR"} | {item.availableTicket}</h5>
                   
                    <Dropdown
                      className = "w-100"
                      placeHolder = "Quantity"
                      name = "quantity"
                      value={item.quantity}
                      onSelectChange = {(name, val) => onTicketSelection(item, name, val, i)}
                      isDisabled = {(isDisableTicket || isDisablePackage || bookingList.some(bck => bck.id !== item.id))}
                      options = {[
                        {
                          "label" : 0,
                          "value" : 0
                        },
                        {
                          "label" : 1,
                          "value" : 1
                        }
                      ]}
                    />
                  </div>
                      
                  {/*<div className="color-primary-50 body-R">
                    + {item?.serviceFee}{item?.serviceFeeType} € service fee
                  </div>*/}
                </div>
              </div> 
            )}

          </div>
          {isShowTicketError && <div className="text-danger body-R">! Please select atleast one ticket</div>}
        </div>
      </div>
    </div>
  )
}

export default SelectPlanView;