import {getEachTicketTotalServiceFee, getEachTicketTotalPrice, getTicketPrice, getTicketServiceFee} from '../../../utils/util';

const ConfirmationView = (props) => {
      const {onHandleScheduleNow,  bookingDetails = {}, handleClose} = props;

      const {
            purchaseDetails = [],
            purchaseAdditionalDetails = {}
      } =  bookingDetails || {};

      const {
            serviceName = "",
            serviceLocation = {},
            mode = "physical",
            bookingList = [],
            serviceCategory = ""
      } = purchaseAdditionalDetails || {};

	return (
		
		<div className="container dialog_padding">
      		<div className="row w-100">
      			<div className="col-lg-8 m-auto w-100">
      				<div className="confirmation_container align-items-center w-50 m-auto">
      					<div className="card_item p-5 m-auto w-100 ">
      						<div className="card_item_content m-auto">
      						      <h5 className="heading-5 color-primary-50">
                                                      {serviceName} | {serviceCategory} {mode === "physical" && <span>| {serviceLocation?.city}</span>}
                                                </h5>

                                                {mode !== "physical" && <h5 className="heading-5 color-primary-50">Online</h5>}
      							<hr />
      						</div>
                                          <hr />
      						{bookingList.map((eachBook, index) => 
                                                <>
                                                      {eachBook.pricingType === "ticket" &&
                                                            <>
                                                                  {eachBook.tickets.ticketList.map((item, idx) => {
                                                                        return(
                                                                        <div className="d-flex justify-content-between py-2 w-100">
                                                                             <div className="body-B color-primary-50">{eachBook?.tickets?.ticketName} x {item?.quantity}</div>
                                                                             <div className="body-R color-primary-50">{getTicketPrice(item) + getTicketServiceFee(item)} EUR</div>
                                                                        </div>)   
                                                                  }
                                                                  )}
                                                            </>
                                                      }

                                                      {eachBook.pricingType === "package" && <>
                                                            <div className="d-flex justify-content-between py-2 w-100">
                                                                  <div className="body-R color-primary-50">{eachBook?.packageName}  x  {eachBook?.quantity}</div>
                                                                  <div className="body-R color-primary-50">{(getTicketPrice(eachBook) +  getTicketServiceFee(eachBook))} EUR</div>
                                                            </div>
                                                      </>
                                                      }
                                                </>
                                          )}
                                          <hr />
                                          {/*<div className="d-flex justify-content-between">
                                                <div className="body-B color-primary-50">Total</div>
                                                <div className="body-B color-primary-50">{''} EUR</div>
                                          </div>*/}
      					</div>

      					<div>
      						<h5 className="heading-5 color-primary-50 text-center">Yay! You've successfully made a purchase!</h5>
      						<div className="body-R color-primary-50 text-center">The ticket has been sent to your email.</div>
      					</div>

      					{bookingList && bookingList[0] && bookingList[0].pricingType === "package" && 
                                          <div className="responsive_flex w-100 justify-content-center align-items-center">
            						<div className="body-R color-primary-50 my-2">Want to schedule your session? </div>
            						
                                                <div className="px-4">
      	      						<button className="capitalize tertiary_btn" onClick={onHandleScheduleNow}>
      	      						     <span className="tertiary_text my-2">Schedule Now</span>
      	      						</button>
            						</div>
            					</div>
                                    }

                                    {bookingList && bookingList[0] && bookingList[0].pricingType === "ticket" &&  
                                    <div>
                                          <button className="primary_btn capitalize" onClick={handleClose}>Done</button>
                                    </div>
                                    }
      				</div>
      			</div>
      		</div>
      	</div>
	)
}

export default ConfirmationView;