import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Noresult from '../../../../../../../components/no-result/Noresult';
import CheckPickerView from '../../../../../../../components/checkPicker/CheckPickerView';
import CustomizedSnackbars from  '../../../../../../../components/CustomizedSnackbars';
import Loading from '../../../../../../../components/loading/Loading';
import ListView from '../../../../ListView';
import TabView from '../../../../../../../components/tabView/TabView';
import location from '../../../../../../../assets/Location pin.png';
import {Service_Tab_List, Service_Status_Array} from '../../../../../../../utils/DefaultInitialValues';

import {
	CalculateDuration,
	ConvertDaysArray,
	ConvertPrivateTimesArray, 
	getAuthenticateHeader, 
	generateEventsDropdownList, 
	getMinimumTicketPrice,
	getLocationLink,
	getWeekDays, 
	getTypeFromActiveTab, 
	isEmptyJson
} from '../../../../../../../utils/util';

import ClassListView from '../../../../../../../components/classListView/ClassListView';
import {getServices} from '../../../../../../../actions/services.actions';

const ServicesView = (props) => {
	const {userDetails = {}} = props;

	const dispatch = useDispatch();
	const getServicesAction = getServices;

	const [allServices, setAllServices] = useState([]);
	const [serviceLocationList, setServiceLocationList] = useState([]);
	const [activeServiceTab, setActiveServiceTab] = useState(0);
	const [startEpoc, setStartEpoc] = useState("");
	const [endEpoc, setEndEpoc] = useState("");
	const [service_active_Type, setActiveServiceType] = useState("CLASS");
  
    const {
       details,
       loading,
       error
    } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));


	useEffect(() => {
		//getActiveServicesByUserId();
	}, []); 

    const TabPanel = (service_Type = "CLASS") => {
		
		return (
			<div className="list_view ">
				
				{allServices.length === 0 && <div> <Noresult serviceName = {service_Type} onCTAClick = {() => console.log("Classes")} /> </div>}
				
				{allServices.length > 0 && allServices.map((eachCls, indexVal) => {
					const convertedDaysArray = ConvertDaysArray(eachCls, service_Type);
					const timeVal = ConvertPrivateTimesArray(eachCls, service_Type);
					const minPrice = getMinimumTicketPrice(eachCls);
					
					return (
						<>
							<div className="responsive_flex justify-content-between">
								<div>
									
									{eachCls?.serviceScheduleDetails?.serviceMode === "physical" && 
										<div className="body-R color-primary-50 py-2 d-flex align-items-center">
											<img src={location} style={{width: '24px', height: '24px', position: 'relative', top: '0px'}} />
											<span className="body-R color-primary-50">{eachCls?.serviceLocation?.city || "-"}</span>
										</div>
									}

									{eachCls?.serviceScheduleDetails?.serviceMode === "online" && 
										<div className="body-R color-primary-50 py-2">
											Online
										</div>
									}
									
									<Link to={`/events-classes/service-details/${eachCls.serviceId}`}
										className="ax-button subtitle-B color-primary-50 text-left" 
									>
										{eachCls?.serviceName}
									</Link>
									
									{service_Type !== "PRIVATE" && <div className="body-R color-primary-50 py-2">{CalculateDuration(eachCls?.startTime,eachCls?.endTime)} - {convertedDaysArray.join(", ")} </div>}
									{service_Type === "PRIVATE" && <div className="body-R color-primary-50 py-2">{timeVal} - {convertedDaysArray.join(", ")} </div>}
								
								</div>

								<h6 className="heading-6 color-primary-50">{minPrice === "Free" ? minPrice : `Price from ${minPrice}`} </h6>
								
							</div>
						
							{(indexVal !== (allServices.length - 1)) && 
								<div className="">
									<hr />
								</div>
							}
						</>
					)
				})}
			</div>
		)
	}

	useEffect(() => {
		setAllServices([]);
		const serviceType = getTypeFromActiveTab(activeServiceTab);
		setActiveServiceType(serviceType);
		dispatch(getServicesAction(userDetails?.userId, "", serviceType, "", "", "", "active"));
	}, [activeServiceTab]);
  
    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllServices(details);
    	}
    }, [details]);

	return (
		<>
			<div className="container-fluid">
				<div className="card-details rounded-4 bg-white p-5">
					<div className="d-flex justify-content-between align-items-center">
						{allServices.length === 0 && <div className="d-flex justify-content-center w-100"> <Noresult serviceName = "Classes" onCTAClick = {() => console.log("Classes")} /> </div>}					
					</div>


					{allServices.length > 0 && 
						<div className="row">
							<div className="col-lg-12 col-md-12">
								
								<div className="w-100 rounded-4">
									
									<TabView 
										children = {(service_Type) => TabPanel(service_Type)} 
										service_Type = {service_active_Type} 
										tabs = {Service_Tab_List} 
										onTabClick = {(val) => setActiveServiceTab(val)} 
									/>

								</div>
							</div>
						</div>
					}
				</div>

				
			</div>
		</>
	);

}
export default ServicesView;