import React, {useState, useEffect} from 'react';
import OTPView from './OTPView';
import {UserTypeArray} from '../../utils/DefaultInitialValues';
import Input from '../input/Input';
import partner_icon from '../../assets/Partners.png';
import yellow_logo from "../../assets/yellow_logo.png";
import yellow_minded_text from '../../assets/yellow_minded_text.png'; 
import './auth.css';

const Auth = (props) => {

  const {
  	isShowOTP = false, 
  	setSignupView, 
  	isSignupView = false, 
  	userDetails = {}, 
  	errorMsg = "", 
  	onHandleChange,
  	otp = "",
  	setOtp = "",
  	isInvalidOTP = false,
  	resendOTP
  } = props;

  const {role = "customer"} = userDetails;

  const sub_text = isSignupView ? 'Already registered?' : 'Not Registered?';
  const cta_text = isSignupView ? 'Join Now' : 'Sign up';

  return (
    <div>
        <div className="login">
        	<div className="container dialog_padding">
		        <div className="row">
			        <div className="col-lg-6 col-md-10 m-auto">
			          	<div className="flex_container m-auto">
			            	<div className="rounded-3 w-100 ">

			            		<div className="d-flex justify-content-center align-items-center pb-3">
			            			<img src= {yellow_logo} style={{width: "55px", height: "auto"}} />
			            			<img src={yellow_minded_text} className="px-2" style={{width: "180px", height: "auto"}} />
			            		</div>

			            		<div className="auth_container">
				              		{isSignupView && <div className="subtitle-B white_text text-center py-1">{cta_text} to Minded for a more personalised experience as</div>}

					              	<div className="auth_card_container w-100 pt-1 px-lg-5 px-md-3">
						                {isSignupView && UserTypeArray.map((item, i) => 
						                    <>
							                  <input 
								                  	type="radio" 
								                  	value={item?.value} 
								                  	name="role" 
								                  	id={item?.label} 
								                  	className="a11y" 
								                  	checked = {item?.value === role} 
								                  	onChange = {e => onHandleChange(e)}
								                  	disabled={i==2}
							                	/>

							                  	<label for={item.label} className="auth_card m-2 d-flex flex-column justify-content-center">
								                    <div className="d-flex justify-content-center">
								                  		<img src={partner_icon} style={{width: "20px", height: "20px"}} />
								                  	</div>
								                    <div className="subtitle-R color-primary-50 text-center">{item?.label}</div>
								                    <div className="caption-R color-primary-50 text-center">{item?.sub_text}</div>
							                  	</label>
						                    </>
						                )}
					              	</div>
					              	
				              	</div>
			            	</div>
			           		<OTPView 
			           			userDetails = {userDetails} 
			           			isSignupView = {isSignupView} 
			           			isShowOTP = {isShowOTP}
			           			otp = {otp}
			           			setOtp = {setOtp}
			           			onHandleChange = {e => onHandleChange(e)} 
			           			errorMsg = {errorMsg} 
			           			setSignupView = {setSignupView} 
			           			isInvalidOTP = {isInvalidOTP}
  								resendOTP = {resendOTP}
			           		/>

			           		<div className="w-100 d-flex justify-content-center subtitle-R color-primary-50 white_text text-center capitalize">{sub_text} &nbsp;
					            <button className="ax-button join_now_btn" onClick = {() => setSignupView(!isSignupView)}>{cta_text}</button>
					        </div>
			        	</div>
		        	</div>
		        </div>
			</div> 
        </div>
    </div>
  );
}


export default Auth;