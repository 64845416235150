import React from 'react';
import { useTimer } from 'react-timer-hook';
import {addZeroBefore} from '../../utils/util';

const MyTimer = ({ expiryTimestamp, onExpire }) => {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => onExpire() });

    return (
        <span >
            <span>
                <span><span>{addZeroBefore(minutes)}</span>:<span>{addZeroBefore(seconds)}</span></span>
            </span>
        </span>
      );
}

export default MyTimer


