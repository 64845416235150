import React, {useEffect, useState} from 'react';
import { useLocation, Link } from 'react-router-dom';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import {getHelmetDetails} from '../../utils/util';
import { Breadcrumb } from "rsuite"; 
import "rsuite/dist/rsuite.min.css";
import './helmetText.css';
import right_arrow from '../../assets/thin arrow - right.png';

const HelmetText = (props) => {
	const {H1text = ""} = props;

	const [helmetStyleDetails, setHelmetDetails] = useState({});
	const [crumb, setCrumb] = useState([]);
	const location = useLocation();

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "events-classes":
					obj.path = "/events-classes";
					obj.label = "Events & Classes";
					crumbArray.push(obj);
					break;
				case "service-details":
					obj.path = "/events-classes";
					obj.label = "Service details";
					crumbArray.push(obj);
					break;
				case "schedule-details":
					obj.path = "/events-classes";
					obj.label = "Schedule details";
					crumbArray.push(obj);
					break;
				case "news":
					obj.path = "/news";
					obj.label = "News";
					crumbArray.push(obj);
					break;
				case "catalysts":
					obj.path = "/catalysts";
					obj.label = "Catalysts";
					crumbArray.push(obj);
					break;
				case "catalyst":
					obj.path = "/catalysts";
					obj.label = "Catalysts";
					crumbArray.push(obj);
					break;
				case "catalyst/":
					obj.path = "/catalyst";
					obj.label = "Catalyst";
					crumbArray.push(obj);
					break;
				case "about":
					obj.path = "/about";
					obj.label = "About minded";
					crumbArray.push(obj);
					break;
				case "contact":
					obj.path = "/contact";
					obj.label = "Contact";
					crumbArray.push(obj);
					break;
				default :
				if(location.pathname.includes("catalyst/")) {
					obj.path = "/";
					obj.label = "Catalyst details";
					crumbArray.push(obj);
				}
			}

			setCrumb(crumbArray);
		});
	}

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
       
        const helmetDetails = getHelmetDetails(locationVal);
        setHelmetDetails(helmetDetails);
    }, [location]);

    const {helmetBgColor = "", h1Text = "", h1TextColor = "", breadcrumbLinkColor = "", breadcrumbEndLinkColor = "", arrow_color = "white_text"} = helmetStyleDetails || {};

	return (
		<div className="desktop_view">
			<div className={`${helmetBgColor} helmet_container common_padding`}>
				
				<Breadcrumb > 
	                {crumb.map((item, index) => {
	                	return (index !== crumb.length - 1) ? (
		                	<>
			                	<Link to={item.path}>
			                		<span className={`body-R ${breadcrumbLinkColor}`}>{item.label}</span>
			                	</Link>
			                	
			                	<i className={`${arrow_color} fa-solid fa-chevron-right`} style={{width: '12px', height: '12px'}}></i>
		                	</>
		                	) : (<span className={`body-R ${breadcrumbEndLinkColor}`}>{item.label}</span>)
	                	}
	                )}  
	            </Breadcrumb> 
	           
	            <h1 className={`heading-1 ${h1TextColor}`}>{H1text || h1Text}</h1>
			</div>
		</div>
	)
}

export default HelmetText;

