import health from '../../assets/health_feature.png';
import yoga from '../../assets/yoga.png';
import nidra from '../../assets/nidra.png';
import yoni from '../../assets/yoni_feature.png';

import './feature-card.css';

const FeatureCard = (props) => {
	const {name = ""} = props;

	const getIcon = () => {
		switch(name) {
			case "Yoga":
			case "Selfhealing":
				return yoga;
			case "Thetahealing":
				return yoni;
			case "Nidra":
				return nidra;
			case "Health Activation":
				return health;
			case "Yoni Therapy":
				return yoni;
			default :
			return nidra;

		}
	}

	const icon = getIcon();

	return (
		<div className="d-flex flex-column align-items-center justify-content-center catalyst-details-feature-card py-3 my-1">
			{/*<img src={icon} alt = "" className="catalyst-details-feature-card-img" />*/}
			<div className="color-primary-50 caption-B text-center capitalize">{name}</div>
		</div>
							
	)
	
}

export default FeatureCard;