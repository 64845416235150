const TimezoneList = [
  {
    "text": "Dateline Standard Time",
    "value": "Etc/GMT+12",
    "standard": "DST",
    "offset": -12,
    "isdst": false,
    "label": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "text": "UTC-11",
    "value": "Pacific/Midway",
    "standard": "U",
    "offset": -11,
    "isdst": false,
    "label": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "text": "Hawaiian Standard Time",
    "value": "Pacific/Honolulu",
    "standard": "HST",
    "offset": -10,
    "isdst": false,
    "label": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "text": "Alaskan Standard Time",
    "value": "America/Anchorage",
    "standard": "AKDT",
    "offset": -8,
    "isdst": true,
    "label": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "text": "Pacific Standard Time (Mexico)",
    "value": "America/Santa_Isabel",
    "standard": "PDT",
    "offset": -7,
    "isdst": true,
    "label": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ]
  },
  {
    "text": "Pacific Daylight Time",
    "value": "America/Los_Angeles",
    "standard": "PDT",
    "offset": -7,
    "isdst": true,
    "label": "(UTC-07:00) Pacific Daylight Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver"
    ]
  },
  {
    "text": "Pacific Standard Time",
    "value": "America/Los_Angeles",
    "standard": "PST",
    "offset": -8,
    "isdst": false,
    "label": "(UTC-08:00) Pacific Standard Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT"
    ]
  },
  {
    "text": "US Mountain Standard Time",
    "value": "America/Creston",
    "standard": "UMST",
    "offset": -7,
    "isdst": false,
    "label": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7"
    ]
  },
  {
    "text": "Mountain Standard Time (Mexico)",
    "value": "America/Chihuahua",
    "standard": "MDT",
    "offset": -6,
    "isdst": true,
    "label": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "text": "Mountain Standard Time",
    "value": "America/Boise",
    "standard": "MDT",
    "offset": -6,
    "isdst": true,
    "label": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "text": "Central America Standard Time",
    "value": "America/Belize",
    "standard": "CAST",
    "offset": -6,
    "isdst": false,
    "label": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "text": "Central Standard Time",
    "value": "America/Chicago",
    "standard": "CDT",
    "offset": -5,
    "isdst": true,
    "label": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "text": "Central Standard Time (Mexico)",
    "value": "America/Bahia_Banderas",
    "standard": "CDT",
    "offset": -5,
    "isdst": true,
    "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    "text": "Canada Central Standard Time",
    "value": "America/Regina",
    "standard": "CCST",
    "offset": -6,
    "isdst": false,
    "label": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "text": "SA Pacific Standard Time",
    "value": "America/Bogota",
    "standard": "SPST",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    "text": "Eastern Standard Time",
    "value": "America/Detroit",
    "standard": "EST",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
  {
    "text": "Eastern Daylight Time",
    "value": "America/Detroit",
    "standard": "EDT",
    "offset": -4,
    "isdst": true,
    "label": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
  {
    "text": "US Eastern Standard Time",
    "value": "America/Indiana/Marengo",
    "standard": "UEDT",
    "offset": -5,
    "isdst": false,
    "label": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    "text": "Venezuela Standard Time",
    "value": "Asia/Ho_Chi_Minh",
    "standard": "VST",
    "offset": -4.5,
    "isdst": false,
    "label": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "text": "Paraguay Standard Time",
    "value": "America/Asuncion",
    "standard": "PYT",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ]
  },
  {
    "text": "Atlantic Standard Time",
    "value": "America/Glace_Bay",
    "standard": "ADT",
    "offset": -3,
    "isdst": true,
    "label": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "text": "Central Brazilian Standard Time",
    "value": "America/Cuiaba",
    "standard": "CBST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Cuiaba"
    ]
  },
  {
    "text": "SA Western Standard Time",
    "value": "America/Anguilla",
    "standard": "SWST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    "text": "Pacific SA Standard Time",
    "value": "America/Santiago",
    "standard": "PSST",
    "offset": -4,
    "isdst": false,
    "label": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "text": "Newfoundland Standard Time",
    "value": "America/St_Johns",
    "standard": "NST",
    "offset": -2.5,
    "isdst": true,
    "label": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "text": "E. South America Standard Time",
    "value": "America/Sao_Paulo",
    "standard": "ESAST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "text": "Argentina Standard Time",
    "value": "America/Argentina/La_Rioja",
    "standard": "AST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "text": "SA Eastern Standard Time",
    "value": "America/Araguaina",
    "standard": "SEST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    "text": "Greenland Standard Time",
    "value": "America/Godthab",
    "standard": "GDT",
    "offset": -2,
    "isdst": true,
    "label": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ]
  },
  {
    "text": "Montevideo Standard Time",
    "value": "America/Montevideo",
    "standard": "MST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ]
  },
  {
    "text": "Bahia Standard Time",
    "value": "America/Bahia",
    "standard": "BST",
    "offset": -3,
    "isdst": false,
    "label": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ]
  },
  {
    "text": "UTC-02",
    "value": "America/Noronha",
    "standard": "U-2",
    "offset": -2,
    "isdst": false,
    "label": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "text": "Azores Standard Time",
    "value": "Atlantic/Azores",
    "standard": "AZOST",
    "offset": 0,
    "isdst": true,
    "label": "(UTC-01:00) Azores",
    "utc": [
      "Atlantic/Azores"
    ]
  },
  {
    "text": "Cape Verde Standard Time",
    "value": "Atlantic/Cape_Verde",
    "standard": "CVST",
    "offset": -1,
    "isdst": false,
    "label": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "text": "Morocco Standard Time",
    "value": "Africa/Casablanca",
    "standard": "MST",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+00:00) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "text": "UTC",
    "value": "America/Danmarkshavn",
    "standard": "UTC",
    "offset": 0,
    "isdst": false,
    "label": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ]
  },
  {
    "text": "GMT Standard Time",
    "value": "Europe/Guernsey",
    "standard": "GMT",
    "offset": 0,
    "isdst": false,
    "label": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "text": "British Summer Time",
    "value": "Europe/London",
    "standard": "BST",
    "offset": 1,
    "isdst": true,
    "label": "(UTC+01:00) Edinburgh, London",
    "utc": [
      "Europe/London"
    ]
  },
  {
    "text": "GMT Standard Time",
    "value": "Africa/Abidjan",
    "standard": "GMT",
    "offset": 0,
    "isdst": false,
    "label": "(UTC+00:00) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    "text": "Greenwich Standard Time",
    "value": "Europe/London",
    "standard": "GST",
    "offset": 0,
    "isdst": false,
    "label": "(UTC+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    "utc": [
      "Europe/London"
    ]
  },
  {
    "text": "W. Europe Standard Time",
    "value": "Arctic/Longyearbyen",
    "standard": "WET",
    "offset": 1,
    "isdst": true,
    "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/San_Marino",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    "text": "Central Europe Standard Time",
    "value": "Europe/Belgrade",
    "standard": "CET",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    "text": "Romance Standard Time",
    "value": "Africa/Ceuta",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ],
    "standard": "RDT"
  },
  {
    "text": "Central European Standard Time",
    "value": "Europe/Sarajevo",
    "offset": 2,
    "isdst": true,
    "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ],
    "standard": "CEDT"
  },
  {
    "text": "W. Central Africa Standard Time",
    "value": "Africa/Algiers",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ],
    "standard": "WCAST"
  },
  {
    "text": "Namibia Standard Time",
    "value": "Africa/Windhoek",
    "offset": 1,
    "isdst": false,
    "label": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ],
    "standard": "NST"
  },
  {
    "text": "GTB Standard Time",
    "value": "Asia/Nicosia",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ],
    "standard": "GDT"
  },
  {
    "text": "Middle East Standard Time",
    "value": "Asia/Beirut",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ],
    "standard": "MEDT"
  },
  {
    "text": "Egypt Standard Time",
    "value": "Africa/Cairo",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ],
    "standard": "EST"
  },
  {
    "text": "Syria Standard Time",
    "value": "Asia/Damascus",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ],
    "standard": "SDT"
  },
  {
    "text": "E. Europe Standard Time",
    "value": "Europe/Athens",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Vilnius"
    ],
    "standard": "EEDT"
  },
  {
    "text": "South Africa Standard Time",
    "value": "Africa/Johannesburg",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ],
    "standard": "SAST"
  },
  {
    "text": "FLE Standard Time",
    "value": "Europe/Helsinki",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Vilnius"
    ],
    "standard": "FDT"
  },
  {
    "text": "Turkey Standard Time",
    "value": "Europe/Istanbul",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ],
    "standard": "TDT"
  },
  {
    "text": "Israel Standard Time",
    "value": "Asia/Jerusalem",
    "offset": 3,
    "isdst": true,
    "label": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ],
    "standard": "JDT"
  },
  {
    "text": "Libya Standard Time",
    "value": "Africa/Tripoli",
    "offset": 2,
    "isdst": false,
    "label": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ],
    "standard": "LST"
  },
  {
    "text": "Jordan Standard Time",
    "value": "Asia/Amman",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ],
    "standard": "JST"
  },
  {
    "text": "Arabic Standard Time",
    "value": "Asia/Baghdad",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ],
    "standard": "AST"
  },
  {
    "text": "Kaliningrad Standard Time",
    "value": "Europe/Kaliningrad",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+02:00) Kaliningrad",
    "utc": [
      "Europe/Kaliningrad"
    ],
    "standard": "KST"
  },
  {
    "text": "Arab Standard Time",
    "value": "Asia/Kuwait",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ],
    "standard": "AST"
  },
  {
    "text": "E. Africa Standard Time",
    "value": "Africa/Kampala",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ],
    "standard": "EAST"
  },
  {
    "text": "Moscow Standard Time",
    "value": "Europe/Moscow",
    "offset": 3,
    "isdst": false,
    "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    "utc": [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk"
    ],
    "standard": "MSK"
  },
  {
    "text": "Samara Time",
    "value": "Europe/Astrakhan",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Ulyanovsk"
    ],
    "standard": "SAMT"
  },
  {
    "text": "Iran Standard Time",
    "value": "Asia/Tehran",
    "offset": 4.5,
    "isdst": true,
    "label": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ],
    "standard": "IDT"
  },
  {
    "text": "Arabian Standard Time",
    "value": "Asia/Dubai",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ],
    "standard": "AST"
  },
  {
    "text": "Azerbaijan Standard Time",
    "value": "Asia/Baku",
    "offset": 5,
    "isdst": true,
    "label": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ],
    "standard": "ADT"
  },
  {
    "text": "Mauritius Standard Time",
    "value": "Indian/Mahe",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ],
    "standard": "MST"
  },
  {
    "text": "Georgian Standard Time",
    "value": "Asia/Tbilisi",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ],
    "standard": "GET"
  },
  {
    "text": "Caucasus Standard Time",
    "value": "Asia/Yerevan",
    "offset": 4,
    "isdst": false,
    "label": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ],
    "standard": "CST"
  },
  {
    "text": "Afghanistan Standard Time",
    "value": "Asia/Kabul",
    "offset": 4.5,
    "isdst": false,
    "label": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ],
    "standard": "AST"
  },
  {
    "text": "West Asia Standard Time",
    "value": "Indian/Maldives",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ],
    "standard": "WAST"
  },
  {
    "text": "Yekaterinburg Time",
    "value": "Asia/Yekaterinburg",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Yekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ],
    "standard": "YEKT"
  },
  {
    "text": "Pakistan Standard Time",
    "value": "Asia/Karachi",
    "offset": 5,
    "isdst": false,
    "label": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ],
    "standard": "PKT"
  },
  {
    "text": "India Standard Time",
    "value": "Asia/Kolkata",
    "offset": 5.5,
    "isdst": false,
    "label": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata",
      "Asia/Calcutta"
    ],
    "standard": "IST"
  },
  {
    "text": "Sri Lanka Standard Time",
    "value": "Asia/Colombo",
    "offset": 5.5,
    "isdst": false,
    "label": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ],
    "standard": "SLST"
  },
  {
    "text": "Nepal Standard Time",
    "value": "Asia/Kathmandu",
    "offset": 5.75,
    "isdst": false,
    "label": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Kathmandu"
    ],
    "standard": "NST"
  },
  {
    "text": "Central Asia Standard Time",
    "value": "Antarctica/Vostok",
    "offset": 6,
    "isdst": false,
    "label": "(UTC+06:00) Nur-Sultan (Astana)",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ],
    "standard": "CAST"
  },
  {
    "text": "Bangladesh Standard Time",
    "value": "Asia/Dhaka",
    "offset": 6,
    "isdst": false,
    "label": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ],
    "standard": "BST"
  },
  {
    "text": "Myanmar Standard Time",
    "value": "Asia/Rangoon",
    "offset": 6.5,
    "isdst": false,
    "label": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ],
    "standard": "MST"
  },

  {
    "text": "SE Asia Standard Time",
    "value": "Asia/Bangkok",
    "standard": "SAST",
    "offset": 7,
    "isdst": false,
    "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    "text": "N. Central Asia Standard Time",
    "value": "Asia/Novokuznetsk",
    "standard": "NCAST",
    "offset": 7,
    "isdst": false,
    "label": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "text": "China Standard Time",
    "value": "Asia/Hong_Kong",
    "standard": "CST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ]
  },
  {
    "text": "North Asia Standard Time",
    "value": "Asia/Krasnoyarsk",
    "standard": "NAST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ]
  },
  {
    "text": "Singapore Standard Time",
    "value": "Asia/Singapore",
    "standard": "MPST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    "text": "W. Australia Standard Time",
    "value": "Australia/Perth",
    "standard": "WAST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ]
  },
  {
    "text": "Taipei Standard Time",
    "value": "Asia/Taipei",
    "standard": "TST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ]
  },
  {
    "text": "Ulaanbaatar Standard Time",
    "value": "Asia/Choibalsan",
    "standard": "UST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ]
  },
  {
    "text": "North Asia East Standard Time",
    "value": "Asia/Irkutsk",
    "standard": "NAEST",
    "offset": 8,
    "isdst": false,
    "label": "(UTC+08:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "text": "Japan Standard Time",
    "value": "Asia/Tokyo",
    "standard": "JST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    "text": "Korea Standard Time",
    "value": "Asia/Seoul",
    "standard": "KST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "text": "Cen. Australia Standard Time",
    "value": "Australia/Adelaide",
    "standard": "CAST",
    "offset": 9.5,
    "isdst": false,
    "label": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "text": "AUS Central Standard Time",
    "value": "Australia/Darwin",
    "standard": "ACST",
    "offset": 9.5,
    "isdst": false,
    "label": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ]
  },
  {
    "text": "E. Australia Standard Time",
    "value": "Australia/Brisbane",
    "standard": "EAST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "text": "AUS Eastern Standard Time",
    "value": "Australia/Melbourne",
    "standard": "AEST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ]
  },
  {
    "text": "West Pacific Standard Time",
    "value": "Antarctica/DumontDUrville",
    "standard": "WPST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },

  {
    "text": "Tasmania Standard Time",
    "value": "Australia/Currie",
    "standard": "TST",
    "offset": 10,
    "isdst": false,
    "label": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "text": "Yakutsk Standard Time",
    "value": "Asia/Chita",
    "standard": "YST",
    "offset": 9,
    "isdst": false,
    "label": "(UTC+09:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ]
  },
  {
    "text": "Central Pacific Standard Time",
    "value": "Antarctica/Macquarie",
    "standard": "CPST",
    "offset": 11,
    "isdst": false,
    "label": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    "text": "Vladivostok Standard Time",
    "value": "Asia/Sakhalin",
    "standard": "VST",
    "offset": 11,
    "isdst": false,
    "label": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "text": "New Zealand Standard Time",
    "value": "Pacific/Auckland",
    "standard": "NZST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ]
  },
  {
    "text": "UTC+12",
    "value": "Pacific/Wallis",
    "standard": "U",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "text": "Fiji Standard Time",
    "value": "Pacific/Fiji",
    "standard": "FST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ]
  },
  {
    "text": "Magadan Standard Time",
    "value": "Asia/Kamchatka",
    "standard": "MST",
    "offset": 12,
    "isdst": false,
    "label": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "text": "Kamchatka Standard Time",
    "value": "Asia/Kamchatka",
    "standard": "KDT",
    "offset": 13,
    "isdst": true,
    "label": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ]
  },
  {
    "text": "Tonga Standard Time",
    "value": "Pacific/Enderbury",
    "standard": "TST",
    "offset": 13,
    "isdst": false,
    "label": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    "text": "Samoa Standard Time",
    "value": "Pacific/Guadalcanal",
    "standard": "SST",
    "offset": 13,
    "isdst": false,
    "label": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ]
  }
];

/*

const TimezoneList = [
  {
    "label": "ACT",
    "value": "Australia/Darwin"
  },
  {
    "label": "AET",
    "value": "Australia/Sydney",
    
  },
  {
    "label": "AGT",
    "value": "America/Argentina/Buenos_Aires"
  },
  {
    "label": "ART",
    "value": "Africa/Cairo" 
  },
  {
    "label": "AST",
    "value": "America/Anchorage" 
  },
  {
    "label": "BET",
    "value": "America/Sao_Paulo" 
  },
  {
    "label": "BST",
    "value": "Asia/Dhaka" 
  },
  {
    "label": "CAT",
    "value": "Africa/Harare" 
  },
  {
    "label": "CNT",
    "value": "America/St_Johns" 
  },
  {
    "label": "CST",
    "value": "America/Chicago" 
  },
  {
    "label": "CTT",
    "value": "Asia/Shanghai" 
  },
  {
    "label": "EAT",
    "value": "Africa/Addis_Ababa" 
  },
  {
    "label": "ECT",
    "value": "Europe/Paris" 
  },
  {
    "label": "IET",
    "value": "America/Indiana/Indianapolis" 
  },
  {
    "label": "IST",
    "value": "Asia/Kolkata" 
  },
  {
    "label": "JST",
    "value": "Asia/Tokyo" 
  },
  {
    "label": "MIT",
    "value": "Pacific/Apia" 
  },
  {
    "label": "NET",
    "value": "Asia/Yerevan" 
  },
  {
    "label": "NST",
    "value": "Pacific/Auckland" 
  },
  {
    "label": "PLT",
    "value": "Asia/Karachi" 
  },
  {
    "label": "PNT",
    "value": "America/Phoenix" 
  },
  {
    "label": "PRT",
    "value": "America/Puerto_Rico" 
  },
  {
    "label": "PST",
    "value": "America/Los_Angeles" 
  },
  {
    "label": "SST",
    "value": "Pacific/Guadalcanal" 
  },
  {
    "label": "VST",
    "value": "Asia/Ho_Chi_Minh"
  }
];
*/

const LangList = [
  {
    "id": "af",
    "name": "Afrikaans"
  },
  {
    "id": "af-ZA",
    "name": "Afrikaans (South Africa)"
  },
  {
    "id": "ar",
    "name": "Arabic"
  },
  {
    "id": "ar-AE",
    "name": "Arabic (U.A.E.)"
  },
  {
    "id": "ar-BH",
    "name": "Arabic (Bahrain)"
  },
  {
    "id": "ar-DZ",
    "name": "Arabic (Algeria)"
  },
  {
    "id": "ar-EG",
    "name": "Arabic (Egypt)"
  },
  {
    "id": "ar-IQ",
    "name": "Arabic (Iraq)"
  },
  {
    "id": "ar-JO",
    "name": "Arabic (Jordan)"
  },
  {
    "id": "ar-KW",
    "name": "Arabic (Kuwait)"
  },
  {
    "id": "ar-LB",
    "name": "Arabic (Lebanon)"
  },
  {
    "id": "ar-LY",
    "name": "Arabic (Libya)"
  },
  {
    "id": "ar-MA",
    "name": "Arabic (Morocco)"
  },
  {
    "id": "ar-OM",
    "name": "Arabic (Oman)"
  },
  {
    "id": "ar-QA",
    "name": "Arabic (Qatar)"
  },
  {
    "id": "ar-SA",
    "name": "Arabic (Saudi Arabia)"
  },
  {
    "id": "ar-SY",
    "name": "Arabic (Syria)"
  },
  {
    "id": "ar-TN",
    "name": "Arabic (Tunisia)"
  },
  {
    "id": "ar-YE",
    "name": "Arabic (Yemen)"
  },
  {
    "id": "az",
    "name": "Azeri (Latin)"
  },
  {
    "id": "az-AZ",
    "name": "Azeri (Latin) (Azerbaijan)"
  },
  {
    "id": "az-AZ",
    "name": "Azeri (Cyrillic) (Azerbaijan)"
  },
  {
    "id": "be",
    "name": "Belarusian"
  },
  {
    "id": "be-BY",
    "name": "Belarusian (Belarus)"
  },
  {
    "id": "bg",
    "name": "Bulgarian"
  },
  {
    "id": "bg-BG",
    "name": "Bulgarian (Bulgaria)"
  },
  {
    "id": "bs-BA",
    "name": "Bosnian (Bosnia and Herzegovina)"
  },
  {
    "id": "ca",
    "name": "Catalan"
  },
  {
    "id": "ca-ES",
    "name": "Catalan (Spain)"
  },
  {
    "id": "cs",
    "name": "Czech"
  },
  {
    "id": "cs-CZ",
    "name": "Czech (Czech Republic)"
  },
  {
    "id": "cy",
    "name": "Welsh"
  },
  {
    "id": "cy-GB",
    "name": "Welsh (United Kingdom)"
  },
  {
    "id": "da",
    "name": "Danish"
  },
  {
    "id": "da-DK",
    "name": "Danish (Denmark)"
  },
  {
    "id": "de",
    "name": "German"
  },
  {
    "id": "de-AT",
    "name": "German (Austria)"
  },
  {
    "id": "de-CH",
    "name": "German (Switzerland)"
  },
  {
    "id": "de-DE",
    "name": "German (Germany)"
  },
  {
    "id": "de-LI",
    "name": "German (Liechtenstein)"
  },
  {
    "id": "de-LU",
    "name": "German (Luxembourg)"
  },
  {
    "id": "dv",
    "name": "Divehi"
  },
  {
    "id": "dv-MV",
    "name": "Divehi (Maldives)"
  },
  {
    "id": "el",
    "name": "Greek"
  },
  {
    "id": "el-GR",
    "name": "Greek (Greece)"
  },
  {
    "id": "en",
    "name": "English"
  },
  {
    "id": "en-AU",
    "name": "English (Australia)"
  },
  {
    "id": "en-BZ",
    "name": "English (Belize)"
  },
  {
    "id": "en-CA",
    "name": "English (Canada)"
  },
  {
    "id": "en-CB",
    "name": "English (Caribbean)"
  },
  {
    "id": "en-GB",
    "name": "English (United Kingdom)"
  },
  {
    "id": "en-IE",
    "name": "English (Ireland)"
  },
  {
    "id": "en-JM",
    "name": "English (Jamaica)"
  },
  {
    "id": "en-NZ",
    "name": "English (New Zealand)"
  },
  {
    "id": "en-PH",
    "name": "English (Republic of the Philippines)"
  },
  {
    "id": "en-TT",
    "name": "English (Trinidad and Tobago)"
  },
  {
    "id": "en-US",
    "name": "English (United States)"
  },
  {
    "id": "en-ZA",
    "name": "English (South Africa)"
  },
  {
    "id": "en-ZW",
    "name": "English (Zimbabwe)"
  },
  {
    "id": "eo",
    "name": "Esperanto"
  },
  {
    "id": "es",
    "name": "Spanish"
  },
  {
    "id": "es-AR",
    "name": "Spanish (Argentina)"
  },
  {
    "id": "es-BO",
    "name": "Spanish (Bolivia)"
  },
  {
    "id": "es-CL",
    "name": "Spanish (Chile)"
  },
  {
    "id": "es-CO",
    "name": "Spanish (Colombia)"
  },
  {
    "id": "es-CR",
    "name": "Spanish (Costa Rica)"
  },
  {
    "id": "es-DO",
    "name": "Spanish (Dominican Republic)"
  },
  {
    "id": "es-EC",
    "name": "Spanish (Ecuador)"
  },
  {
    "id": "es-ES",
    "name": "Spanish (Castilian)"
  },
  {
    "id": "es-ES",
    "name": "Spanish (Spain)"
  },
  {
    "id": "es-GT",
    "name": "Spanish (Guatemala)"
  },
  {
    "id": "es-HN",
    "name": "Spanish (Honduras)"
  },
  {
    "id": "es-MX",
    "name": "Spanish (Mexico)"
  },
  {
    "id": "es-NI",
    "name": "Spanish (Nicaragua)"
  },
  {
    "id": "es-PA",
    "name": "Spanish (Panama)"
  },
  {
    "id": "es-PE",
    "name": "Spanish (Peru)"
  },
  {
    "id": "es-PR",
    "name": "Spanish (Puerto Rico)"
  },
  {
    "id": "es-PY",
    "name": "Spanish (Paraguay)"
  },
  {
    "id": "es-SV",
    "name": "Spanish (El Salvador)"
  },
  {
    "id": "es-UY",
    "name": "Spanish (Uruguay)"
  },
  {
    "id": "es-VE",
    "name": "Spanish (Venezuela)"
  },
  {
    "id": "et",
    "name": "Estonian"
  },
  {
    "id": "et-EE",
    "name": "Estonian (Estonia)"
  },
  {
    "id": "eu",
    "name": "Basque"
  },
  {
    "id": "eu-ES",
    "name": "Basque (Spain)"
  },
  {
    "id": "fa",
    "name": "Farsi"
  },
  {
    "id": "fa-IR",
    "name": "Farsi (Iran)"
  },
  {
    "id": "fi",
    "name": "Finnish"
  },
  {
    "id": "fi-FI",
    "name": "Finnish (Finland)"
  },
  {
    "id": "fo",
    "name": "Faroese"
  },
  {
    "id": "fo-FO",
    "name": "Faroese (Faroe Islands)"
  },
  {
    "id": "fr",
    "name": "French"
  },
  {
    "id": "fr-BE",
    "name": "French (Belgium)"
  },
  {
    "id": "fr-CA",
    "name": "French (Canada)"
  },
  {
    "id": "fr-CH",
    "name": "French (Switzerland)"
  },
  {
    "id": "fr-FR",
    "name": "French (France)"
  },
  {
    "id": "fr-LU",
    "name": "French (Luxembourg)"
  },
  {
    "id": "fr-MC",
    "name": "French (Principality of Monaco)"
  },
  {
    "id": "gl",
    "name": "Galician"
  },
  {
    "id": "gl-ES",
    "name": "Galician (Spain)"
  },
  {
    "id": "gu",
    "name": "Gujarati"
  },
  {
    "id": "gu-IN",
    "name": "Gujarati (India)"
  },
  {
    "id": "he",
    "name": "Hebrew"
  },
  {
    "id": "he-IL",
    "name": "Hebrew (Israel)"
  },
  {
    "id": "hi",
    "name": "Hindi"
  },
  {
    "id": "hi-IN",
    "name": "Hindi (India)"
  },
  {
    "id": "hr",
    "name": "Croatian"
  },
  {
    "id": "hr-BA",
    "name": "Croatian (Bosnia and Herzegovina)"
  },
  {
    "id": "hr-HR",
    "name": "Croatian (Croatia)"
  },
  {
    "id": "hu",
    "name": "Hungarian"
  },
  {
    "id": "hu-HU",
    "name": "Hungarian (Hungary)"
  },
  {
    "id": "hy",
    "name": "Armenian"
  },
  {
    "id": "hy-AM",
    "name": "Armenian (Armenia)"
  },
  {
    "id": "id",
    "name": "Indonesian"
  },
  {
    "id": "id-ID",
    "name": "Indonesian (Indonesia)"
  },
  {
    "id": "is",
    "name": "Icelandic"
  },
  {
    "id": "is-IS",
    "name": "Icelandic (Iceland)"
  },
  {
    "id": "it",
    "name": "Italian"
  },
  {
    "id": "it-CH",
    "name": "Italian (Switzerland)"
  },
  {
    "id": "it-IT",
    "name": "Italian (Italy)"
  },
  {
    "id": "ja",
    "name": "Japanese"
  },
  {
    "id": "ja-JP",
    "name": "Japanese (Japan)"
  },
  {
    "id": "ka",
    "name": "Georgian"
  },
  {
    "id": "ka-GE",
    "name": "Georgian (Georgia)"
  },
  {
    "id": "kk",
    "name": "Kazakh"
  },
  {
    "id": "kk-KZ",
    "name": "Kazakh (Kazakhstan)"
  },
  {
    "id": "kn",
    "name": "Kannada"
  },
  {
    "id": "kn-IN",
    "name": "Kannada (India)"
  },
  {
    "id": "ko",
    "name": "Korean"
  },
  {
    "id": "ko-KR",
    "name": "Korean (Korea)"
  },
  {
    "id": "kok",
    "name": "Konkani"
  },
  {
    "id": "kok-IN",
    "name": "Konkani (India)"
  },
  {
    "id": "ky",
    "name": "Kyrgyz"
  },
  {
    "id": "ky-KG",
    "name": "Kyrgyz (Kyrgyzstan)"
  },
  {
    "id": "lt",
    "name": "Lithuanian"
  },
  {
    "id": "lt-LT",
    "name": "Lithuanian (Lithuania)"
  },
  {
    "id": "lv",
    "name": "Latvian"
  },
  {
    "id": "lv-LV",
    "name": "Latvian (Latvia)"
  },
  {
    "id": "mi",
    "name": "Maori"
  },
  {
    "id": "mi-NZ",
    "name": "Maori (New Zealand)"
  },
  {
    "id": "mk",
    "name": "FYRO Macedonian"
  },
  {
    "id": "mk-MK",
    "name": "FYRO Macedonian (Former Yugoslav Republic of Macedonia)"
  },
  {
    "id": "mn",
    "name": "Mongolian"
  },
  {
    "id": "mn-MN",
    "name": "Mongolian (Mongolia)"
  },
  {
    "id": "mr",
    "name": "Marathi"
  },
  {
    "id": "mr-IN",
    "name": "Marathi (India)"
  },
  {
    "id": "ms",
    "name": "Malay"
  },
  {
    "id": "ms-BN",
    "name": "Malay (Brunei Darussalam)"
  },
  {
    "id": "ms-MY",
    "name": "Malay (Malaysia)"
  },
  {
    "id": "mt",
    "name": "Maltese"
  },
  {
    "id": "mt-MT",
    "name": "Maltese (Malta)"
  },
  {
    "id": "nb",
    "name": "Norwegian (Bokm?l)"
  },
  {
    "id": "nb-NO",
    "name": "Norwegian (Bokm?l) (Norway)"
  },
  {
    "id": "nl",
    "name": "Dutch"
  },
  {
    "id": "nl-BE",
    "name": "Dutch (Belgium)"
  },
  {
    "id": "nl-NL",
    "name": "Dutch (Netherlands)"
  },
  {
    "id": "nn-NO",
    "name": "Norwegian (Nynorsk) (Norway)"
  },
  {
    "id": "ns",
    "name": "Northern Sotho"
  },
  {
    "id": "ns-ZA",
    "name": "Northern Sotho (South Africa)"
  },
  {
    "id": "pa",
    "name": "Punjabi"
  },
  {
    "id": "pa-IN",
    "name": "Punjabi (India)"
  },
  {
    "id": "pl",
    "name": "Polish"
  },
  {
    "id": "pl-PL",
    "name": "Polish (Poland)"
  },
  {
    "id": "ps",
    "name": "Pashto"
  },
  {
    "id": "ps-AR",
    "name": "Pashto (Afghanistan)"
  },
  {
    "id": "pt",
    "name": "Portuguese"
  },
  {
    "id": "pt-BR",
    "name": "Portuguese (Brazil)"
  },
  {
    "id": "pt-PT",
    "name": "Portuguese (Portugal)"
  },
  {
    "id": "qu",
    "name": "Quechua"
  },
  {
    "id": "qu-BO",
    "name": "Quechua (Bolivia)"
  },
  {
    "id": "qu-EC",
    "name": "Quechua (Ecuador)"
  },
  {
    "id": "qu-PE",
    "name": "Quechua (Peru)"
  },
  {
    "id": "ro",
    "name": "Romanian"
  },
  {
    "id": "ro-RO",
    "name": "Romanian (Romania)"
  },
  {
    "id": "ru",
    "name": "Russian"
  },
  {
    "id": "ru-RU",
    "name": "Russian (Russia)"
  },
  {
    "id": "sa",
    "name": "Sanskrit"
  },
  {
    "id": "sa-IN",
    "name": "Sanskrit (India)"
  },
  {
    "id": "se",
    "name": "Sami (Northern)"
  },
  {
    "id": "se-FI",
    "name": "Sami (Northern) (Finland)"
  },
  {
    "id": "se-FI",
    "name": "Sami (Skolt) (Finland)"
  },
  {
    "id": "se-FI",
    "name": "Sami (Inari) (Finland)"
  },
  {
    "id": "se-NO",
    "name": "Sami (Northern) (Norway)"
  },
  {
    "id": "se-NO",
    "name": "Sami (Lule) (Norway)"
  },
  {
    "id": "se-NO",
    "name": "Sami (Southern) (Norway)"
  },
  {
    "id": "se-SE",
    "name": "Sami (Northern) (Sweden)"
  },
  {
    "id": "se-SE",
    "name": "Sami (Lule) (Sweden)"
  },
  {
    "id": "se-SE",
    "name": "Sami (Southern) (Sweden)"
  },
  {
    "id": "sk",
    "name": "Slovak"
  },
  {
    "id": "sk-SK",
    "name": "Slovak (Slovakia)"
  },
  {
    "id": "sl",
    "name": "Slovenian"
  },
  {
    "id": "sl-SI",
    "name": "Slovenian (Slovenia)"
  },
  {
    "id": "sq",
    "name": "Albanian"
  },
  {
    "id": "sq-AL",
    "name": "Albanian (Albania)"
  },
  {
    "id": "sr-BA",
    "name": "Serbian (Latin) (Bosnia and Herzegovina)"
  },
  {
    "id": "sr-BA",
    "name": "Serbian (Cyrillic) (Bosnia and Herzegovina)"
  },
  {
    "id": "sr-SP",
    "name": "Serbian (Latin) (Serbia and Montenegro)"
  },
  {
    "id": "sr-SP",
    "name": "Serbian (Cyrillic) (Serbia and Montenegro)"
  },
  {
    "id": "sv",
    "name": "Swedish"
  },
  {
    "id": "sv-FI",
    "name": "Swedish (Finland)"
  },
  {
    "id": "sv-SE",
    "name": "Swedish (Sweden)"
  },
  {
    "id": "sw",
    "name": "Swahili"
  },
  {
    "id": "sw-KE",
    "name": "Swahili (Kenya)"
  },
  {
    "id": "syr",
    "name": "Syriac"
  },
  {
    "id": "syr-SY",
    "name": "Syriac (Syria)"
  },
  {
    "id": "ta",
    "name": "Tamil"
  },
  {
    "id": "ta-IN",
    "name": "Tamil (India)"
  },
  {
    "id": "te",
    "name": "Telugu"
  },
  {
    "id": "te-IN",
    "name": "Telugu (India)"
  },
  {
    "id": "th",
    "name": "Thai"
  },
  {
    "id": "th-TH",
    "name": "Thai (Thailand)"
  },
  {
    "id": "tl",
    "name": "Tagalog"
  },
  {
    "id": "tl-PH",
    "name": "Tagalog (Philippines)"
  },
  {
    "id": "tn",
    "name": "Tswana"
  },
  {
    "id": "tn-ZA",
    "name": "Tswana (South Africa)"
  },
  {
    "id": "tr",
    "name": "Turkish"
  },
  {
    "id": "tr-TR",
    "name": "Turkish (Turkey)"
  },
  {
    "id": "tt",
    "name": "Tatar"
  },
  {
    "id": "tt-RU",
    "name": "Tatar (Russia)"
  },
  {
    "id": "ts",
    "name": "Tsonga"
  },
  {
    "id": "uk",
    "name": "Ukrainian"
  },
  {
    "id": "uk-UA",
    "name": "Ukrainian (Ukraine)"
  },
  {
    "id": "ur",
    "name": "Urdu"
  },
  {
    "id": "ur-PK",
    "name": "Urdu (Islamic Republic of Pakistan)"
  },
  {
    "id": "uz",
    "name": "Uzbek (Latin)"
  },
  {
    "id": "uz-UZ",
    "name": "Uzbek (Latin) (Uzbekistan)"
  },
  {
    "id": "uz-UZ",
    "name": "Uzbek (Cyrillic) (Uzbekistan)"
  },
  {
    "id": "vi",
    "name": "Vietnamese"
  },
  {
    "id": "vi-VN",
    "name": "Vietnamese (Viet Nam)"
  },
  {
    "id": "xh",
    "name": "Xhosa"
  },
  {
    "id": "xh-ZA",
    "name": "Xhosa (South Africa)"
  },
  {
    "id": "zh",
    "name": "Chinese"
  },
  {
    "id": "zh-CN",
    "name": "Chinese (S)"
  },
  {
    "id": "zh-HK",
    "name": "Chinese (Hong Kong)"
  },
  {
    "id": "zh-MO",
    "name": "Chinese (Macau)"
  },
  {
    "id": "zh-SG",
    "name": "Chinese (Singapore)"
  },
  {
    "id": "zh-TW",
    "name": "Chinese (T)"
  },
  {
    "id": "zu",
    "name": "Zulu"
  },
  {
    "id": "zu-ZA",
    "name": "Zulu (South Africa)"
  }
];


const CountryList = [
  {"label": "Afghanistan", "value": "AF"}, 
  {"label": "land Islands", "value": "AX"}, 
  {"label": "Albania", "value": "AL"}, 
  {"label": "Algeria", "value": "DZ"}, 
  {"label": "American Samoa", "value": "AS"}, 
  {"label": "Andorra", "value": "AD"}, 
  {"label": "Angola", "value": "AO"}, 
  {"label": "Anguilla", "value": "AI"}, 
  {"label": "Antarctica", "value": "AQ"}, 
  {"label": "Antigua and Barbuda", "value": "AG"}, 
  {"label": "Argentina", "value": "AR"}, 
  {"label": "Armenia", "value": "AM"}, 
  {"label": "Aruba", "value": "AW"}, 
  {"label": "Australia", "value": "AU"}, 
  {"label": "Austria", "value": "AT"}, 
  {"label": "Azerbaijan", "value": "AZ"}, 
  {"label": "Bahamas", "value": "BS"}, 
  {"label": "Bahrain", "value": "BH"}, 
  {"label": "Bangladesh", "value": "BD"}, 
  {"label": "Barbados", "value": "BB"}, 
  {"label": "Belarus", "value": "BY"}, 
  {"label": "Belgium", "value": "BE"}, 
  {"label": "Belize", "value": "BZ"}, 
  {"label": "Benin", "value": "BJ"}, 
  {"label": "Bermuda", "value": "BM"}, 
  {"label": "Bhutan", "value": "BT"}, 
  {"label": "Bolivia", "value": "BO"}, 
  {"label": "Bosnia and Herzegovina", "value": "BA"}, 
  {"label": "Botswana", "value": "BW"}, 
  {"label": "Bouvet Island", "value": "BV"}, 
  {"label": "Brazil", "value": "BR"}, 
  {"label": "British Indian Ocean Territory", "value": "IO"}, 
  {"label": "Brunei Darussalam", "value": "BN"}, 
  {"label": "Bulgaria", "value": "BG"}, 
  {"label": "Burkina Faso", "value": "BF"}, 
  {"label": "Burundi", "value": "BI"}, 
  {"label": "Cambodia", "value": "KH"}, 
  {"label": "Cameroon", "value": "CM"}, 
  {"label": "Canada", "value": "CA"}, 
  {"label": "Cape Verde", "value": "CV"}, 
  {"label": "Cayman Islands", "value": "KY"}, 
  {"label": "Central African Republic", "value": "CF"}, 
  {"label": "Chad", "value": "TD"}, 
  {"label": "Chile", "value": "CL"}, 
  {"label": "China", "value": "CN"}, 
  {"label": "Christmas Island", "value": "CX"}, 
  {"label": "Cocos (Keeling) Islands", "value": "CC"}, 
  {"label": "Colombia", "value": "CO"}, 
  {"label": "Comoros", "value": "KM"}, 
  {"label": "Congo", "value": "CG"}, 
  {"label": "Congo, The Democratic Republic of the", "value": "CD"}, 
  {"label": "Cook Islands", "value": "CK"}, 
  {"label": "Costa Rica", "value": "CR"}, 
  {"label": "Cote D\"Ivoire", "value": "CI"}, 
  {"label": "Croatia", "value": "HR"}, 
  {"label": "Cuba", "value": "CU"}, 
  {"label": "Cyprus", "value": "CY"}, 
  {"label": "Czech Republic", "value": "CZ"}, 
  {"label": "Denmark", "value": "DK"}, 
  {"label": "Djibouti", "value": "DJ"}, 
  {"label": "Dominica", "value": "DM"}, 
  {"label": "Dominican Republic", "value": "DO"}, 
  {"label": "Ecuador", "value": "EC"}, 
  {"label": "Egypt", "value": "EG"}, 
  {"label": "El Salvador", "value": "SV"}, 
  {"label": "Equatorial Guinea", "value": "GQ"}, 
  {"label": "Eritrea", "value": "ER"}, 
  {"label": "Estonia", "value": "EE"}, 
  {"label": "Ethiopia", "value": "ET"}, 
  {"label": "Falkland Islands (Malvinas)", "value": "FK"}, 
  {"label": "Faroe Islands", "value": "FO"}, 
  {"label": "Fiji", "value": "FJ"}, 
  {"label": "Finland", "value": "FI"}, 
  {"label": "France", "value": "FR"}, 
  {"label": "French Guiana", "value": "GF"}, 
  {"label": "French Polynesia", "value": "PF"}, 
  {"label": "French Southern Territories", "value": "TF"}, 
  {"label": "Gabon", "value": "GA"}, 
  {"label": "Gambia", "value": "GM"}, 
  {"label": "Georgia", "value": "GE"}, 
  {"label": "Germany", "value": "DE"}, 
  {"label": "Ghana", "value": "GH"}, 
  {"label": "Gibraltar", "value": "GI"}, 
  {"label": "Greece", "value": "GR"}, 
  {"label": "Greenland", "value": "GL"}, 
  {"label": "Grenada", "value": "GD"}, 
  {"label": "Guadeloupe", "value": "GP"}, 
  {"label": "Guam", "value": "GU"}, 
  {"label": "Guatemala", "value": "GT"}, 
  {"label": "Guernsey", "value": "GG"}, 
  {"label": "Guinea", "value": "GN"}, 
  {"label": "Guinea-Bissau", "value": "GW"}, 
  {"label": "Guyana", "value": "GY"}, 
  {"label": "Haiti", "value": "HT"}, 
  {"label": "Heard Island and Mcdonald Islands", "value": "HM"}, 
  {"label": "Holy See (Vatican City State)", "value": "VA"}, 
  {"label": "Honduras", "value": "HN"}, 
  {"label": "Hong Kong", "value": "HK"}, 
  {"label": "Hungary", "value": "HU"}, 
  {"label": "Iceland", "value": "IS"}, 
  {"label": "India", "value": "IN"}, 
  {"label": "Indonesia", "value": "value"}, 
  {"label": "Iran, Islamic Republic Of", "value": "IR"}, 
  {"label": "Iraq", "value": "IQ"}, 
  {"label": "Ireland", "value": "IE"}, 
  {"label": "Isle of Man", "value": "IM"}, 
  {"label": "Israel", "value": "IL"}, 
  {"label": "Italy", "value": "IT"}, 
  {"label": "Jamaica", "value": "JM"}, 
  {"label": "Japan", "value": "JP"}, 
  {"label": "Jersey", "value": "JE"}, 
  {"label": "Jordan", "value": "JO"}, 
  {"label": "Kazakhstan", "value": "KZ"}, 
  {"label": "Kenya", "value": "KE"}, 
  {"label": "Kiribati", "value": "KI"}, 
  {"label": "Korea, Democratic People\"S Republic of", "value": "KP"}, 
  {"label": "Korea, Republic of", "value": "KR"}, 
  {"label": "Kuwait", "value": "KW"}, 
  {"label": "Kyrgyzstan", "value": "KG"}, 
  {"label": "Lao People\"S Democratic Republic", "value": "LA"}, 
  {"label": "Latvia", "value": "LV"}, 
  {"label": "Lebanon", "value": "LB"}, 
  {"label": "Lesotho", "value": "LS"}, 
  {"label": "Liberia", "value": "LR"}, 
  {"label": "Libyan Arab Jamahiriya", "value": "LY"}, 
  {"label": "Liechtenstein", "value": "LI"}, 
  {"label": "Lithuania", "value": "LT"}, 
  {"label": "Luxembourg", "value": "LU"}, 
  {"label": "Macao", "value": "MO"}, 
  {"label": "Macedonia, The Former Yugoslav Republic of", "value": "MK"}, 
  {"label": "Madagascar", "value": "MG"}, 
  {"label": "Malawi", "value": "MW"}, 
  {"label": "Malaysia", "value": "MY"}, 
  {"label": "Maldives", "value": "MV"}, 
  {"label": "Mali", "value": "ML"}, 
  {"label": "Malta", "value": "MT"}, 
  {"label": "Marshall Islands", "value": "MH"}, 
  {"label": "Martinique", "value": "MQ"}, 
  {"label": "Mauritania", "value": "MR"}, 
  {"label": "Mauritius", "value": "MU"}, 
  {"label": "Mayotte", "value": "YT"}, 
  {"label": "Mexico", "value": "MX"}, 
  {"label": "Micronesia, Federated States of", "value": "FM"}, 
  {"label": "Moldova, Republic of", "value": "MD"}, 
  {"label": "Monaco", "value": "MC"}, 
  {"label": "Mongolia", "value": "MN"}, 
  {"label": "Montenegro", "value": "ME"},
  {"label": "Montserrat", "value": "MS"},
  {"label": "Morocco", "value": "MA"}, 
  {"label": "Mozambique", "value": "MZ"}, 
  {"label": "Myanmar", "value": "MM"}, 
  {"label": "Namibia", "value": "NA"}, 
  {"label": "Nauru", "value": "NR"}, 
  {"label": "Nepal", "value": "NP"}, 
  {"label": "Netherlands", "value": "NL"}, 
  {"label": "Netherlands Antilles", "value": "AN"}, 
  {"label": "New Caledonia", "value": "NC"}, 
  {"label": "New Zealand", "value": "NZ"}, 
  {"label": "Nicaragua", "value": "NI"}, 
  {"label": "Niger", "value": "NE"}, 
  {"label": "Nigeria", "value": "NG"}, 
  {"label": "Niue", "value": "NU"}, 
  {"label": "Norfolk Island", "value": "NF"}, 
  {"label": "Northern Mariana Islands", "value": "MP"}, 
  {"label": "Norway", "value": "NO"}, 
  {"label": "Oman", "value": "OM"}, 
  {"label": "Pakistan", "value": "PK"}, 
  {"label": "Palau", "value": "PW"}, 
  {"label": "Palestinian Territory, Occupied", "value": "PS"}, 
  {"label": "Panama", "value": "PA"}, 
  {"label": "Papua New Guinea", "value": "PG"}, 
  {"label": "Paraguay", "value": "PY"}, 
  {"label": "Peru", "value": "PE"}, 
  {"label": "Philippines", "value": "PH"}, 
  {"label": "Pitcairn", "value": "PN"}, 
  {"label": "Poland", "value": "PL"}, 
  {"label": "Portugal", "value": "PT"}, 
  {"label": "Puerto Rico", "value": "PR"}, 
  {"label": "Qatar", "value": "QA"}, 
  {"label": "Reunion", "value": "RE"}, 
  {"label": "Romania", "value": "RO"}, 
  {"label": "Russian Federation", "value": "RU"}, 
  {"label": "RWANDA", "value": "RW"}, 
  {"label": "Saint Helena", "value": "SH"}, 
  {"label": "Saint Kitts and Nevis", "value": "KN"}, 
  {"label": "Saint Lucia", "value": "LC"}, 
  {"label": "Saint Pierre and Miquelon", "value": "PM"}, 
  {"label": "Saint Vincent and the Grenadines", "value": "VC"}, 
  {"label": "Samoa", "value": "WS"}, 
  {"label": "San Marino", "value": "SM"}, 
  {"label": "Sao Tome and Principe", "value": "ST"}, 
  {"label": "Saudi Arabia", "value": "SA"}, 
  {"label": "Senegal", "value": "SN"}, 
  {"label": "Serbia", "value": "RS"}, 
  {"label": "Seychelles", "value": "SC"}, 
  {"label": "Sierra Leone", "value": "SL"}, 
  {"label": "Singapore", "value": "SG"}, 
  {"label": "Slovakia", "value": "SK"}, 
  {"label": "Slovenia", "value": "SI"}, 
  {"label": "Solomon Islands", "value": "SB"}, 
  {"label": "Somalia", "value": "SO"}, 
  {"label": "South Africa", "value": "ZA"}, 
  {"label": "South Georgia and the South Sandwich Islands", "value": "GS"}, 
  {"label": "Spain", "value": "ES"}, 
  {"label": "Sri Lanka", "value": "LK"}, 
  {"label": "Sudan", "value": "SD"}, 
  {"label": "Suriname", "value": "SR"}, 
  {"label": "Svalbard and Jan Mayen", "value": "SJ"}, 
  {"label": "Swaziland", "value": "SZ"}, 
  {"label": "Sweden", "value": "SE"}, 
  {"label": "Switzerland", "value": "CH"}, 
  {"label": "Syrian Arab Republic", "value": "SY"}, 
  {"label": "Taiwan, Province of China", "value": "TW"}, 
  {"label": "Tajikistan", "value": "TJ"}, 
  {"label": "Tanzania, United Republic of", "value": "TZ"}, 
  {"label": "Thailand", "value": "TH"}, 
  {"label": "Timor-Leste", "value": "TL"}, 
  {"label": "Togo", "value": "TG"}, 
  {"label": "Tokelau", "value": "TK"}, 
  {"label": "Tonga", "value": "TO"}, 
  {"label": "Trinidad and Tobago", "value": "TT"}, 
  {"label": "Tunisia", "value": "TN"}, 
  {"label": "Turkey", "value": "TR"}, 
  {"label": "Turkmenistan", "value": "TM"}, 
  {"label": "Turks and Caicos Islands", "value": "TC"}, 
  {"label": "Tuvalu", "value": "TV"}, 
  {"label": "Uganda", "value": "UG"}, 
  {"label": "Ukraine", "value": "UA"}, 
  {"label": "United Arab Emirates", "value": "AE"}, 
  {"label": "United Kingdom", "value": "GB"}, 
  {"label": "United States", "value": "US"}, 
  {"label": "United States Minor Outlying Islands", "value": "UM"}, 
  {"label": "Uruguay", "value": "UY"}, 
  {"label": "Uzbekistan", "value": "UZ"}, 
  {"label": "Vanuatu", "value": "VU"}, 
  {"label": "Venezuela", "value": "VE"}, 
  {"label": "Viet Nam", "value": "VN"}, 
  {"label": "Virgin Islands, British", "value": "VG"}, 
  {"label": "Virgin Islands, U.S.", "value": "VI"}, 
  {"label": "Wallis and Futuna", "value": "WF"}, 
  {"label": "Western Sahara", "value": "EH"}, 
  {"label": "Yemen", "value": "YE"}, 
  {"label": "Zambia", "value": "ZM"}, 
  {"label": "Zimbabwe", "value": "ZW"} 
];

const getTimeZoneKey = (value = "") => {
  if(!value || value === "") return ""

  const filterItem = TimezoneList.filter((item, idx) => item.value === value);
  if(filterItem && filterItem.length > 0) {
    return filterItem[0]?.standard;
  }

  return "";
}

export {
    CountryList,
    TimezoneList,
    LangList,
    getTimeZoneKey
};