import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {ServiceTypeArray} from '../../../assets/data';
import partner_icon from '../../../assets/Partners.png';
import ChooseServiceView from './ChooseServiceView';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import brown_cancel from '../../../assets/brown_cancel.png';

const AddClassView = (props) => {
  const dialogRef = useRef();
  const {serviceType = "", setServiceType, handleClose, onHandleSubmit} = props;

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  const [type, setType] = useState(serviceType);
  const [serviceTypeError, setServiceTypeError] = useState(false);
  
  const handleSubmit = () => {
    if(type === "") {
      setServiceTypeError(true);
    } else {
      setServiceTypeError(false);
      onHandleSubmit(type);
    }
  }

  useEffect(() => {
    try {
        dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {

    }
  }, []);

  const validateLimitaion = (type = "") => {
    let currentServices = 5;
    if(loggedUser && loggedUser?.catalystDetails) {

      const details = loggedUser?.catalystDetails?.planDetails;
      if(details?.subscriptionName === "Free") {
        if(type === "Event") {
          if(details?.restrictedServices?.includes(type)) {
            return true;
          } else if(currentServices >= parseInt(details?.maxServicesAllowed)) {
            return true;
          }
        }
      } else if(details?.subscriptionName === "Advanced") {
        if(type === "Class" || type === "Private") {
          if(currentServices >= parseInt(details?.maxServicesAllowed)) {
            return true;
          }
        }
      }
    }
    return false;
  }

	return (
    <>
    <DialogActions className="w-100">
      <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3 p-4 mt-4" ref={dialogRef}>
        <div>
        </div>

        <h4 className="heading-4 color-primary-50">
          Choose a service
        </h4>

        <div>
          <button
            onClick={handleClose}
            aria-label="close"
            className="ax-button color-primary-50"
          >
            <img src={brown_cancel} className="ax-button close_icon" />
          </button>
        </div>
      </div>
    </DialogActions>

    <DialogContent>
      <div className="container dialog_padding my-3">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="w-100 my-4">
              <div className="guideline_container">
                {ServiceTypeArray.map((item, index) => 
                  <>
                    <input disabled = {index === 3 || validateLimitaion(item?.title)} type="radio" name="serviceType" id={item.title} className="a11y plan" value = {item.value} checked={type === item.value} onChange={(e) => setType(e.target.value)} />
                    <label className="choose_service_card p-5 w-100 h-100" for={item.title}>
                      <div className="d-flex justify-content-center">
                        <img src={partner_icon} style={{width: "30px", height: "30px"}} />
                      </div>
                      <div className="subtitle-B color-primary-50 text-center py-2">{item?.title}</div>
                      <div className="caption-R color-primary-50 text-center">{item?.details}</div>
                    </label>
                  </>
                )}
              </div>
              
            </div>
            {serviceTypeError && <div className="text-danger text-center pt-1">! Please select a service type</div>}
          </div>
        </div>
      </div>
    </DialogContent>

    <DialogActions className="dialog_footer d-flex justify-content-between align-items-center bg-white">
      <div className="container-fluid">
        <div className=" d-flex justify-content-between align-items-center dialog_padding">
          <div className="d-flex ">
          </div>
          
          <div>
            <button type="button" className="mx-3 secondary_btn capitalize" onClick={() => handleSubmit()}>Next</button>
          </div>
        </div>
      </div>
    </DialogActions>
    </>
	)
}

export default AddClassView;