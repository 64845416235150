import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import avatar from '../../assets/ic_avatar.png';
import help from '../../assets/help.png';
import profile from '../../assets/profile.png';
import setting from '../../assets/setting.png';
import logout from '../../assets/logout.png';

import {truncateString} from '../../utils/util';

import './userProfile.css';

const UserProfile = (props) => {

	const {onMenuItemClick, userDetails= {}, LOGIN_BG_COLOR = "", LOGIN_TEXT_COLOR = ""} = props;
	const navigate = useNavigate();
	
	const toggleMenu = () => {
		let subMenu = document.getElementById("menu");
		subMenu.classList.toggle("open-menu");
	}

	let useClickOutside = (handler) => {
	    let domNode = useRef();
	    useEffect(() => {
	      let maybehandler = (event) => {
	        if (!domNode.current.contains(event.target) && event.target.id !== "img_trigger") {
	          handler();
	        }
	      };
	      document.addEventListener("mousedown", maybehandler);
	      return () => {
	        document.removeEventListener("mousedown", maybehandler);
	      };
	    });
	    return domNode;
  	};

	let userMenu = useClickOutside(() => {
	  	let subMenu = document.getElementById("menu");
	    subMenu.classList.remove("open-menu");
	});

	const onItemClick = (path = "") => {
		toggleMenu();
		onMenuItemClick(path);

		if(path === "dashboard") {
			navigate("/dashboard")
		}
	}

	const {
		name = "",
		email = "",
		profileDescription = {}
	} = userDetails || {};

	const {
		profilePic = ""
	} = profileDescription || {};

	return (
		<div className="user_profile">
			<div className="desktop_view">
	        	<div className={`d-flex align-items-center justify-content-between ${LOGIN_BG_COLOR}  py-2 px-4 rounded `} onClick={toggleMenu}>
	        		<img id="img_trigger" src={profilePic || avatar} className="user-pic px-1"  />
	        		<div className={`LINK ${LOGIN_TEXT_COLOR} capitalize px-1`}>{truncateString(name) || "-"}</div>
	        	</div>
        	</div>


        	<div className="mobile_view">
	        	<div className="d-flex align-items-center justify-content-between" onClick={toggleMenu}>
	        		<img id="img_trigger" src={profilePic || avatar} className="user-pic mx-2"  />
	        		
	        	</div>
        	</div>


        	<div className="sub-menu-wrap" id="menu" ref={userMenu}>
				<div className="sub-menu">
					<div className="white">
	 					
	 					<div className="sub-menu-link">
	 						<img src={profile} alt="" />
	 						<div onClick={() => onItemClick("dashboard")}>Dashboard</div>
	 					</div>

	 					<hr />

	 					<Link to="/logout" className="sub-menu-link">
 							<img src={logout} alt="" />
 							<div onClick={() => onItemClick("logout")}>Logout</div>
 						</Link>


	 				</div>
 				</div>
 			</div>
        </div>
	)
}

export default UserProfile;