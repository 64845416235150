import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Loading from '../../../../../../components/loading/Loading';
import TabView from '../../../../../../components/tabView/TabView';
import {InitialUserDetails, InitialUserErrors, Service_Tab_List} from '../../../../../../utils/DefaultInitialValues';
import brown_cancel from '../../../../../../assets/brown_cancel.png';

import {baseURL} from '../../../../../../actions/Constants';

import OverView from './views/OverView';
import ServicesView from './views/ServicesView';
import BookingView from './views/BookingView';

import './catalystDetailsDialog.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CatalystDetailsDialog = (props) => {
  	const {
	    details = InitialUserDetails,
	    isOpen = false, 
	    handleClose
  	} = props;

  	const [activeTab, setActiveTab] = useState(0);
  	const [userDetails, setUserDetails] = useState(details);

  	const [isShowDialog, setShowDialog] = useState(false);
  	
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	const getCatalystById = async () => {
        const URL= `${baseURL}/getCategoryDetails`;
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(userDetails), // body data type must match "Content-Type" header
		    headers: {
		    	'Content-Type': 'application/json'
		    }
        })
        .then((res) => {
            //Close Dialog
            setLoading(false);
            setError(false);
            //handleClose();
        })
        .catch((error) => {
            console.log('error updateUser/insertUser', error);
            setLoading(false);
            setError(true); 
        });
    }

  	const tabList = [
		{
			"label": "Overview",
			"value" : "Overview"
		},
		{
			"label": "Services",
			"value" : "Services"
		},
		{
			"label": "Bookings",
			"value" : "Bookings"
		}
	];
	
  	const TabPanel = () => {
		return (
			<>
				{activeTab === 0 && <OverView userDetails = {userDetails} />}
				{activeTab === 1 && <ServicesView userDetails = {userDetails}/>}
				{activeTab === 2 && <BookingView userDetails = {userDetails} />}
			</>
		);
	}

  	useEffect(() => {
  		getCatalystById();
  	}, []);

  	const {
	    role = "customer",
	    email = "",
	    status = "pending",
	    name = "",
	    phone = ""
  	} = userDetails || {};

  	const title =  "Catalyst details";

	return (
		<div className="full-dialog">
		    <Dialog
		        onClose={handleClose}
		        aria-labelledby="customized-dialog-title"
		        open={open}
		        fullScreen
		    >
			    <DialogActions className="w-100 p-4 bg-white">
			        <div className="w-100 d-flex justify-content-between align-items-center">
			          <div>
			   
			          </div>

			          <h6 className="heading-6 color-primary-50">
			            {title}
			          </h6>

			          <div>
			            <button     
			              onClick={handleClose}
			              aria-label="close"
			              className="ax-button close_icon"
			            >
			              <img src={brown_cancel} className="close_icon" />
			            </button>
			          </div>
				    </div>
			    </DialogActions>

		        <DialogContent className="bg-white">
		      		<div className="container-fluid">
		      			<div className="row">
		      				<div className="col-lg-6 m-auto">
							<TabView children = {TabPanel} tabs={tabList} onTabClick = {(val) => setActiveTab(val)}  />
							</div>
						</div>
					</div>
		        </DialogContent>
	    	</Dialog>

      	{isLoading && <Loading />}
    	</div>
	)
}

export default CatalystDetailsDialog;