import {getFormmatedServiceName} from '../../utils/util';
import Calendar from '../../assets/Calendar.png';

const Noresult = (props) => {
	const {serviceName = "Classes", onCTAClick} = props;

	const formattedText = getFormmatedServiceName(serviceName)
	return (
		<div className="flex_container_40 w-100 align-items-center">
			
			<img src={Calendar} />
			
			<h4 className="heading-4 color-primary-50">No {formattedText} yet</h4>
			
			{/*<div className="d-flex flex-column justify-content-center align-items-center">
				<div className="body-R color-primary-50 text-center">Already want to fill up your service information to enlist?</div>
				<button className="secondary_btn mt-3 capitalize" onClick={onCTAClick}>Add {formattedText}</button>
			</div>*/}
		</div>
	)
}

export default Noresult;