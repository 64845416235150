import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {baseURL} from '../../actions/Constants';

import Auth from './Auth';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import Loading from '../../components/loading/Loading';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {InitialUserDetails, headerOption} from '../../utils/DefaultInitialValues';

import {isEmailValid, getAuthenticateHeader} from '../../utils/util';
import brown_cancel from '../../assets/brown_cancel.png';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AuthDialog = (props) => {
  const {isOpen = false, onHandleClose, onLoginSuccess, isShowSignUp = false} = props;

  const [open, setOpen] = useState(false);
  const [isSignupView, setSignupView] = useState(false);
  const [isShowOTP, setShowOTP] = useState(false);
  const [userDetails, setUserDetails] = useState(InitialUserDetails);

  const [errorMsg, setErrorMsg] = useState("");
 
 // const [isInvalidEmail, setInvalidEmail] = useState(false);
  const [isInvalidOTP, setInvalidOTP] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));

  //const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const onHandleChange = (e) => {
    setUserDetails({...userDetails, [e.target.name] : e.target.value});
  }
  
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen]);

  useEffect(() => {
    setShowOTP(false);
    setUserDetails({...userDetails, ["email"]: ""});
  }, [isSignupView]);

  useEffect(() => {
    setSignupView(isShowSignUp);
  }, [isShowSignUp])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state = "") => {
    setOpen(false);
    onHandleClose(state);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //let isAllFilled = true;
    
    //setInvalidEmail(false);
    setInvalidOTP(false);

    if(!isShowOTP) {
      const isMailValid = isEmailValid(email);

      if(isMailValid) {
        isUserExist();
      } else {
        setErrorMsg("Email field is a required field");
      }
    } else {
      const emptyOTPArray = otp && otp.filter((d, idx) => (d === ''));
      if(emptyOTPArray.length !== 0) {
        setInvalidOTP(true);
      } else {
        verifyOTP(otp);
      }
    }
  }

  const isUserExist = async () => {
    const URL=`${baseURL}/minded-user/checkIfUserExists/${email}`;
    setLoading(true);

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      }

    })
    .then(response => {
      setLoading(false);
      if(isSignupView) {
        //Sign up - if user exist - show error
        if((response && response?.status === 200)) {
          return response.json().then(res => {
            if(res === false) {
              setErrorMsg("");
              getOTP();
            } else {
              setErrorMsg("User is already exist.. please login");
            }
          });
        } else {
          setErrorMsg("User is already exist.. please login");
        }
      } else {
        //Login page - if false, show error that user is not exist
        if(response && response?.status === 500) {
          setErrorMsg("User is not exist.. please sign up");
        } else {
          // login - if user exist, get the OTP
          //setErrorMsg("User not found!!! Please Signup");
          getOTP();
        }
      }
    })
    .catch((error) => {
      console.log('error isUserExist', error);
      setLoading(false);
      setError(true);
    });
  };
  
  const getUserByEmail = async () => {
    const URL=`${baseURL}/minded-user/getByEmail/${email}`;

    setLoading(true);
  
    return await fetch(URL, headerOption)
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

      setLoading(false);

      if(status === 200) {
        return response.json().then(res => { 
          setErrorMsg("");
          
          onLoginSuccess(isSignupView, res);
          handleClose("success");
        });
        
      } else {
        setErrorMsg("! User not found");
      }
    })
    .catch((error) => {
      console.log('error getUserByEmail', error);
      setLoading(false);
      setError(true);
    });
  };

  const verifyOTP = async (otp) => {

    const receivedOTP = otp.join("");
    const obj = {};
    obj.email = email;
    obj.otp = receivedOTP;

    const URL = `${baseURL}/auth/verifyEmailOTP`;

    return await fetch(URL, {
      method: 'POST', 
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res=> res.json())
    .then(res => {
      const {status = ""} = res;

      if(status === "success") {
        setInvalidOTP(false);
        //const token = setCookie("token", jwtToken);
        if(!isSignupView) {
          getUserByEmail();
        } else {
          onLoginSuccess(isSignupView, userDetails);
        }
      } else {
        setInvalidOTP(true);
      }
    })
    .catch(error => {
      console.log('error', error);
      setError(true);
    })
  }

  const getOTP = () => {
    const URL = `${baseURL}/auth/getEmailOTP/${email}`;
    fetch(URL)
    .then(res => res.json())
    .then(res => {
      setShowOTP(true);
    })
    .catch(error => {
      console.log('error', error);
    })
  };

  const resendOTP = () => {
    const URL = `${baseURL}/auth/resendEmailOTP/${email}`;

    fetch(URL)
    .then(res => res.json())
    .then(res => {
      setOtp(new Array(6).fill(''));
    })
    .catch(error => {
      console.log('error', error);
    })
  };

  const getText = () => {
    let text = "Login";

    if(isSignupView) {
      if(isShowOTP) {
        text = "Continue";
      } else {
        text = "Verify Email";
      }
    } else {
      if(isShowOTP) {
        text = "Login";
      } else {
        text = "Verify Email";
      }
    }
    return text;
  }

  const title = isSignupView ? "Sign up" : "Login";

  const submitText = getText();

  const {
    email = ""
  } = userDetails || {};

  return (
    <div className="full-dialog">
     
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        
        <DialogActions className="auth-dialog d-flex justify-content-between align-items-center p-4">
          <div> 
          </div>

          <h4 className="heading-4 white_text">
            {title}
          </h4>

          <div>
            <button     
              onClick={handleClose}
              aria-label="close"
              className="close_icon ax-button"
            >
              <img src={brown_cancel} className="close_icon" alt="" />
            </button>
          </div>
        </DialogActions>

        <DialogContent className="auth-dialog">
          <Auth 
            isShowOTP = {isShowOTP}
            isSignupView = {isSignupView}
            setSignupView = {(val) => setSignupView(val)}
            userDetails = {userDetails} 
            errorMsg = {errorMsg} 
            onHandleChange = {e => onHandleChange(e)}
            otp = {otp}
            setOtp = {setOtp}
            isInvalidOTP = {isInvalidOTP}
            resendOTP = {resendOTP}
          />
        </DialogContent>

        <DialogActions className="dialog_footer d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex justify-content-between align-items-center">
              </div>

              <div className="d-flex">
                <button type="submit" className="mx-3 px-5 py-3 capitalize Link secondary_btn" onClick={e => handleSubmit(e)}>{submitText}</button>
              </div>

            </div>
          </div>
        </DialogActions>
        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
      </Dialog>
  </div>
  );
}


export default AuthDialog;