import {
  GET_SERVICES_START,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  baseURL
} from './Constants';

import {headerOption} from '../utils/DefaultInitialValues';

import {getAuthenticateHeader} from '../utils/util';

export const getServices = (userId = "", serviceId = "", serviceType = "", serviceCategoryId = "",  serviceStartDate = "", serviceEndDate = "", serviceStatus = "") => async (dispatch) => { 
  let param = "";
  let isExist = false;

  //?userId=""&serviceCategoryId=""&serviceId=""&serviceType=""&serviceStartDate="EPOCH"&serviceEndDate="EPOCH"&serviceStatus=""
  if(userId) {
    isExist = true;
    param +=  `userId=${userId}`;
  }

  if(serviceCategoryId) {
    if(isExist) param += "&";
    param +=  `serviceCategoryId=${serviceCategoryId}`;
    isExist = true;
  }

  if(serviceId) {
    if(isExist) param += "&";
    param +=  `serviceId=${serviceId}`;
    isExist = true;
  }

  if(serviceType) {
    if(isExist) param += "&";
    param +=  `serviceType=${serviceType}`;
    isExist = true;
  }

  if(serviceStartDate) {
    if(isExist) param += "&";
    param +=  `serviceStartDate=${serviceStartDate}`;
    isExist = true;
  }

  if(serviceEndDate) {
    if(isExist) param += "&";
    param +=  `serviceEndDate=${serviceEndDate}`;
    isExist = true;
  }

  if(serviceStatus) {
    if(isExist) param += "&";
    param +=  `serviceStatus=${serviceStatus}`;
    isExist = true;
  }

  dispatch({
   type: GET_SERVICES_START,
    error: null
  });

  const URL = `${baseURL}/minded-services/getServiceDetails?${param}`;

  return await fetch(URL, headerOption)
  .then(response => {
    const resp = response;

    const {status = ""} = resp;

    if(status === 200 || status === 201) {
      return response.json().then(res => { 
        dispatch({
          type: GET_SERVICES_SUCCESS,
          payload: res,
          error: null
        });
      });
      
    } else {
      //Error
      dispatch({
        type: GET_SERVICES_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getServices', error);
    dispatch({
      type: GET_SERVICES_FAILURE,
      error: error
    });
  });
}








