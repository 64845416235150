import React, {useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel'; 
import img_placeholder from '../../assets/img_placeholder.png';
import './image_carousel.css';

const ImageCarousel = (props) => {

  const {images = []} = props;

  const [activeIndex, setAtiveIndex] = useState(0);

  return (
    <Carousel> 
      {images.map((item, index) => 
        <Carousel.Item interval={600000}> 
          <img className="service_img" src={item || img_placeholder} alt="Image One" />    
        </Carousel.Item>
      )}
    </Carousel> 
  )
}

export default ImageCarousel;