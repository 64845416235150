import React, {useState, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {useSelector} from 'react-redux';

const Protected = ({children}) => {

	const location = useLocation();

  	const {
    	details,
    	loading,
    	error
  	} = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  	const [currentLocation, setLocation] = useState("");

  	useEffect(() => {
      let locationVal = location?.pathname;
      setLocation(locationVal);
   }, [location]);

   const validatePath = () => {
    if(currentLocation === "/dashboard/catalysts" || currentLocation === "/dashboard/customers" || currentLocation === "/dashboard/services") {
      if(details && details?.role && details?.role.includes('admin')) {
        return true;
      }
    } else {
      if(details && details?.userId) {
      	return true;
      }
    }
    
    return false;
  }

  	//const isUserPresent = (details && details.role === "" && details.emp_phone_no !== "") ? true : false;

  const redirectPath = () => {
    if(details && details?.userId) {
        return "/dashboard";
    }
      return "/";
  	}

  	const isUserPresent = validatePath();
  	return isUserPresent ? children : <Navigate to={redirectPath()} replace />;
};

export default Protected;