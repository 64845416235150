import {
  GET_ADMIN_START,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  REMOVE_LOGGEDIN_ADMIN,
  SAVE_LOGGEDIN_ADMIN,
  baseURL
} from './Constants';

export const saveAdmin = (user) => (dispatch) => {

  dispatch({
    type: SAVE_LOGGEDIN_ADMIN,
    payload: user,
    error: null,
    loading: false
  });
};

export const getEmployeeByPhone = phone => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_START,
    error: null
  });

  let obj = {};
  obj.emp_phone_no = phone;
  
  const URL=`${baseURL}/getEmployeeByPhoneNumber`;
  
  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const resp = response;

    const {status = ""} = resp;

    if(status === 200 || status === 201) {
      return response.json().then(res => {
        dispatch({
          type: GET_ADMIN_SUCCESS,
          payload: res.data[0],
          error: null
        });
      });
      
    } else {
      //Error
      dispatch({
        type: GET_ADMIN_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getEmployeeByPhone', error);
    dispatch({
      type: GET_ADMIN_FAILURE,
      error: error
    });
  });
}

export const removeAdmin = () => (dispatch) => {
  dispatch({
    type: REMOVE_LOGGEDIN_ADMIN,
    payload: {},
    error: null
  });
};

