import view_1 from '../../assets/view-1.png';
import view_2 from '../../assets/view-2-old.png';

import view_3 from '../../assets/view-3.png';
import view_4 from '../../assets/view-4.png';

const FeatureDetails = () => {
	return (
		<div className="home_sub_upcoming_event">
			<div className="w-100">
				<img src={view_1} className="feature_img" alt = "" />
			</div>

			<div className="w-100 text-view primary_10_bg">
				<div>
				
					<div className="subtitle-B white_text fw-bold">CONNECTION</div>
					<div className="body-R py-2 white_text">
						The Minded Club is more than just networking; it's about forging meaningful relationships that transcend surface interactions. Our platform provides a space where individuals can connect on a deep level, fostering a sense of belonging and shared purpose.
					</div>
				</div>
			</div>
			<div className="w-100">
				<img src={view_2} className="feature_img" alt = "" />
			</div>

			<div className="w-100 text-view accent_100_bg">
				<div className="">
					<div className="">
						<div className="color-primary-50 subtitle-B fw-bold">GUIDANCE</div>
						<div className="body-R py-2 color-primary-50">
							We understand that the journey of self-discovery and personal growth can be complex, and having the right guidance can make all the difference. Our community is built on the principles of mentorship, peer support, and shared wisdom.
						</div>
					</div>
				</div>
					
			</div>

			<div className="w-100 text-view secondary_100_bg">
				<div className="">
					<div className="">
						<div className="subtitle-B white_text fw-bold">COMMUNITY</div>
						<div className="body-R py-2 white_text">
							Our community is a diverse blend of backgrounds, experiences, and perspectives, weaving together shared stories that encourage individuals to embrace authenticity and contribute to the collective journey of growth. We are here to support each other.
						</div>
					</div>
				</div>
			</div>

			<div className="w-100">
				<img src={view_3} className="feature_img" alt = "" />
			</div>

			<div className="w-100 text-view secondary_90_bg">
				<div className="">
					<div className="">
						<div className="color-primary-50 subtitle-B fw-bold">GROWTH</div>
						<div className="body-R py-2 color-primary-50">
							We provide a comprehensive range of resources, tools, and give access to experts in all fields of personal growth to help our users unlock their full potential and live a more fulfilling life.
						</div>
					</div>
				</div>
			</div>

			<div className="w-100">
				<img src={view_4} className="feature_img" alt = "" />
			</div>
		</div>
	)
}

export default FeatureDetails;