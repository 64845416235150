import React, {useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { Breadcrumb } from "rsuite";
import TabView from '../../../../../components/tabView/TabView';
import Table from '../../../../../components/table/Table';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import Loading from '../../../../../components/loading/Loading';
import {baseURL} from '../../../../../actions/Constants';
import {Catalyst_Tab_List} from '../../../../../utils/DefaultInitialValues';
import {getAuthenticateHeader, getCurrentMonthText} from '../../../../../utils/util';
import CustomerDetailsDialog from './customerDetailsDialog/CustomerDetailsDialog';
import right_arrow from '../../../../../assets/thin arrow - right.png';
import {getCustomers} from '../../../../../actions/customers.actions';

const AllCustomers = () => {
  	const tableRef = useRef(null);
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const dispatch = useDispatch();

	const currentMonth = getCurrentMonthText();

  	const getCustomersAction = getCustomers;
	const [allCustomers, setAllCustomers] = useState([]);
	const [allFilterCustomers, setAllFilterCustomers] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);

	const [isShowDialog, setShowDialog] = useState(false);
	const [customerDetails, setCustomerDetails] = useState({});

	const {
       details,
       loading,
       error
    } = useSelector(({ customers: { details, loading, error} }) => ({ details, loading, error }));

	const activeOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-outline-warning mx-3" onClick = {() => handleHoldingClick(selectedRows)}>
	        	Holding
	        </button>

	        <button className="btn btn-outline-danger mx-3" onClick = {() => handleRejectedClick(selectedRows)}>
	        	Reject
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  	const pendingOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-success mx-3" onClick = {() => handleApproveClick(selectedRows)}>
	    		Approve
	        </button>

	        <button className="btn btn-outline-danger mx-3" onClick = {() => handleRejectedClick(selectedRows)}>
	        	Reject
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  	const rejectOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-success mx-3" onClick = {() => handleApproveClick(selectedRows)}>
	    		Approve
	        </button>

	        <button className="btn btn-outline-warning mx-3" onClick = {() => handleHoldingClick(selectedRows)}>
	        	Holding
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  	const [option, setOption] = useState(activeOptions);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const changeOption = () => {
    	let newOption = activeOptions;
    	
    	switch(activeTab) {
    		case 0:
    			newOption = activeOptions;
    			break;
    		case 1:
    			newOption = pendingOptions;
    			break;
    		case 2:
    			newOption = rejectOptions;
    			break;
    		default : break;
    	}

    	setOption(newOption);
    }

    useEffect(() => {
    	applyFilter();
    }, [activeTab]);

    const applyFilter = () => {
    	let text = "active";

    	switch(activeTab) {
    		case 0:
    			text = "active";
    			break;
    		case 1:
    			text = "pending";
    			break;
    		case 2:
    			text = "blocked";
    			break;
    		default: break;
    	}

    	const filterData = allCustomers.filter((item, index) => item.status === text);
    	setAllFilterCustomers(filterData);
    }

    useEffect(() => {
    	changeOption();
    }, [allFilterCustomers]);

  	const handleApproveClick = async (selectedRows) => {
		if(allFilterCustomers && allFilterCustomers.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCustomers[item.index]);

			if(filterArray && filterArray.length) {
				await filterArray.map((item, index) => {
			      approveCatalyst(item, "active");
			    });

			    dispatch(getCustomersAction());
			    document.querySelector("input.PrivateSwitchBase-input").click();
			}
		}
	}

	const handleHoldingClick = async (selectedRows) => {
  		
  		if(allFilterCustomers && allFilterCustomers.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCustomers[item.index]);

			if(filterArray && filterArray.length) {
				await filterArray.map((item, index) => {
			      approveCatalyst(item, "pending");
			    });

			    dispatch(getCustomersAction());
			    document.querySelector("input.PrivateSwitchBase-input").click();
			    
			}
		}
  	}

  	const handleRejectedClick = async (selectedRows) => {

		if(allFilterCustomers && allFilterCustomers.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCustomers[item.index]);
			

			if(filterArray && filterArray.length) {
				await filterArray.map((item, index) => {
			      approveCatalyst(item, "blocked");
			    });

			    dispatch(getCustomersAction());
			    document.querySelector("input.PrivateSwitchBase-input").click();
	
			}
		}
  	}

	const approveCatalyst = async (item = {}, state = "active") => {
	    setLoading(true);

	    const obj = item;
	    obj.status = state;
	    
	    const URL =  `${baseURL}/minded-user/updateUserDetails`;
	    
	    return await fetch(URL, {
	      	method: 'POST',
	      	body: JSON.stringify(obj),
		    headers: {
		    	'Content-Type': 'application/json'
		    }
	    })
	    .then(response => {
	    	const resp = response;

	      	const {status = ""} = resp;

	      	setLoading(false);

	      	if(status === 201) {
		        return response.json().then(res => { 
		        });
	        
	      	} else {
	        	
	      	}
	    })
	    .catch((error) => {
	      console.log('error updateUser', error);
	      setLoading(false);
	    })
	}

	const onCatalystNameClick = (tableData = "") => {
		let item = {};
		
		const filterCatalystArray = allCustomers.filter((item, index) => item.email === tableData.rowData[1]);
   		if(filterCatalystArray && filterCatalystArray.length > 0) {
   			item = filterCatalystArray[0];
   			setCustomerDetails(item);
			setShowDialog(true);
   		}
	}

	const TabPanel = (service_type = [], data = []) => {
		return (
			<>
			</>
		)
	}

    const columns = [
	    
	    {
	      	name: "email",
	      	label: 'Name',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       			let val = "-";
	       			let name = "";
	       			
	       			const filterCustomerArray = allCustomers.filter((item, index) => item.email === value);
			   		if(filterCustomerArray && filterCustomerArray.length > 0) {
			   			const item = filterCustomerArray[0];
			   			name = item?.name;
			   			val = item?.profileDescription?.publicName || val;
			   			
			   		}
	              	return (
		                <div className="ax-button color-primary-50">
		                	<div onClick={() => onCatalystNameClick(tableMeta)}>{name}</div>
		                  	{(val !== "-" && val !== name) && <div>({val})</div>}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "email",
	      	label: 'Email',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value}
		                </div>
	              	);
          		}
	      	},
	    },
	    {
	      name: "phone",
	      label: 'Phone',
	      options: {
	       filter: true,
	       sort: true
	      }
	    },
	    {
	      name: "address",
	      label: 'City',
	      options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <button onClick={() => onCatalystNameClick(tableMeta)} className="ax-button red">
		                  {value?.city || "-"}
		                </button>
	              	);
          		}
	      	}
	    },
	    {
	      name: "address",
	      label: 'Country',
	      options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <button onClick={() => onCatalystNameClick(tableMeta)} className="ax-button red">
		                  {value?.country || "-"}
		                </button>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "status",
	      	label: `Booked in ${currentMonth}.`,
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <button onClick={() => onCatalystNameClick(tableMeta)} className="ax-button red">
		                  {"-"}
		                </button>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "status",
	      	label: `Attended in ${currentMonth}.`,
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <button onClick={() => onCatalystNameClick(tableMeta)} className="ax-button red">
		                  {"-"}
		                </button>
	              	);
          		}
	      	}
	    }  
	];

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "customers":
					obj.path = "/dashboard/customers";
					obj.label = "All Customers";
					crumbArray.push(obj);
					break;
				default: break;
			}

			setCrumb(crumbArray);
		});
	}

	useEffect(() => {
		if(Array.isArray(details)) {
			setAllCustomers(details);
		}
	}, [details]);


	useEffect(() => {
		applyFilter();
	}, [allCustomers]);

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		dispatch(getCustomersAction());
	}, []);

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Customers</h1>
			
			<div className="my-5">
				<div className="w-100">
					<TabView 
						children = {(service_Type) => TabPanel(service_Type)} 
						service_Type = {allFilterCustomers} 
						tabs={Catalyst_Tab_List} 
						onTabClick = {(val) => setActiveTab(val)}
						data = {allFilterCustomers}
					/>
				</div>
			</div>
			<div className="my-5">
				<Table ref = {tableRef} headers={columns} rowData = {allFilterCustomers} options = {option} />
			</div>
			{isShowDialog && <CustomerDetailsDialog isOpen = {isShowDialog} details = {customerDetails} handleClose = {() => setShowDialog(false)} />}
			{(loading || isLoading) && <Loading />}
			{(error|| isError) && <CustomizedSnackbars isShow={isError || error} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</div>
	)
}

export default AllCustomers;