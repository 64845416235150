export const SAVE_FILTER_DATA = "SAVE_FILTER_DATA";
export const GET_FILTER_DATA   = "GET_FILTER_DATA";
export const REMOVE_FILTER_DATA   = "REMOVE_FILTER_DATA";

export const SAVE_LOGGEDIN_ADMIN = "SAVE_LOGGEDIN_ADMIN";

export const GET_ADMIN_START = "GET_ADMIN_START";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const REMOVE_LOGGEDIN_ADMIN = "REMOVE_LOGGEDIN_ADMIN";

export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";

export const GET_CATALYSTS_START = "GET_CATALYSTS_START";
export const GET_CATALYSTS_SUCCESS = "GET_CATALYSTS_SUCCESS";
export const GET_CATALYSTS_FAILURE = "GET_CATALYSTS_FAILURE";

export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_SCHEDULES_START = "GET_SCHEDULES_START";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAILURE = "GET_SCHEDULES_FAILURE";


export const GET_BOOKINGS_START = "GET_BOOKINGS_START";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAILURE = "GET_BOOKINGS_FAILURE";

export const GOOGLE_API   = "AIzaSyBrsxPVyZnJaXVj5MjszTqEY2hzKeGXEyw";
export const baseURL = "https://api.weareminded.co";  //

// https://minded.smmc.dev:8443
//https://api.oneattendance.com
/*
Ticket Purchase Flow:
When the user purchased any type of tickets (fixed, variant, free), then 
1. Need API - To get all available slots for each ticket type applied for selected schedule - this is required to show the avl tickets in UI to purchase
2. Once the purchased has been placed, each of the purchased item must be added to the booking table with a pending state (change the state to the success once the payment is successful or else failure) - backend task to monitor the payment and update the state
Note: Better to delete the entry once it is failure

Before adding each entry to the table, make sure the slot availability validation is checked at the backend
As user already came to the purchased ticket flow after selecting any schedule, then there is no flow to reschdule it

Package/Plan Purchase Flow - for any service:
1. Need API - To get all the available slots for the selected date, for any schedule
2. Once the user choose a single or multiple slots, each entry will be added to the booking table

Note: Before adding each entry to the table, make sure the slot availability validation is checked at the backend
*/