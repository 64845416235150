import React, {useEffect, useState} from 'react';
import { DateRangePicker, Stack } from 'rsuite';
import {getEpocFromMoment, isEmptyStringArray} from '../utils/util';
import moment from 'moment';

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

const DateRangePickerView = (props) => {
  
  const {onHandleDateRangeUpdate, name = "", value = [], disabledDate = null, maxDateEpoc = ""} = props;

  const [selectedDate, setSelectedDate] = useState(isEmptyStringArray(value) ? [null, null] : [new Date(value[0]), new Date(value[1])]);

  useEffect(() => {
    if(isEmptyStringArray(selectedDate)) return ;
    onHandleDateRangeUpdate(name, selectedDate);
  },[selectedDate]);

  useEffect(() => {
    if(isEmptyStringArray(selectedDate)) return ;
    if(getEpocFromMoment(selectedDate[0]) !== getEpocFromMoment(new Date(value[0]))
      || getEpocFromMoment(selectedDate[0]) !== getEpocFromMoment(new Date(value[0]))
    ) 
    {
      setSelectedDate([new Date(value[0]), new Date(value[1])])
    }
    
  }, [value]);

  const ranges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
 
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },

];

/*
{
  label: 'Last 7 days',
  value: [subDays(new Date(), 6), new Date()],
  placement: 'left'
},
{
  label: 'Last 30 days',
  value: [subDays(new Date(), 29), new Date()],
  placement: 'left'
}
*/

  const isBeforeCurrentDate = date => {
    const currentDate = new Date();
    var previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    var maxDate = new Date();

    if(maxDateEpoc !== "") {
      maxDate = new Date(maxDateEpoc);
    } else {
      maxDate.setFullYear(currentDate.getFullYear() + 1);
    }

    date.setHours(0);
    date.setMinutes(0);

    if(date < previousDate) {
      return true;
    }
    else if(date > maxDate) {
      return true;
    }
  }

  // Function to determine whether a date should be disabled
  const disabledDates = date => isBeforeCurrentDate(date);

  return (
    <DateRangePicker
      value={selectedDate}
      onChange={setSelectedDate}
      cleanable={false} 
      format='dd-MM-yyyy'
      style={{width: '100%'}}
      ranges={ranges}
      blocked
      disabledDate={disabledDates}
    />
  );
};

export default DateRangePickerView;