import './buttonGroup.css';

const ButtonGroup = (props) => {

	const {onFilterChange, selectedFilter = "locations", isShow = true} = props;

	const onHandleSwitch = (e) => {
		const {value = ""} = e.target;
		onFilterChange(value);
	}

	return (
		<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
			
			{isShow && <input 
				type="radio"
				className="btn-check"
				name="filter" 
				id="filter_2" 
				autocomplete="off" 
				value="catalysts"
				checked={selectedFilter === "catalysts"}
				onChange={e => onHandleSwitch(e)} 
			/>}
			{isShow && <label className="btn btn-outline-primary" for="filter_2">Catalysts</label>}

			<input 
				type="radio" 
				className="btn-check" 
				name="filter" 
				id="filter_3" 
				autocomplete="off" 
				value="services"
				checked={selectedFilter === "services"}
				onChange={e => onHandleSwitch(e)}
			/>
			<label className="btn btn-outline-primary" for="filter_3">Services</label>

			<input 
				type="radio" 
				className="btn-check"
				name="filter" 
				id="filter_1" 
				autocomplete="off" 
				value="locations"
				checked={selectedFilter === "locations"}
				onChange={e => onHandleSwitch(e)} 
			/>
			<label className="btn btn-outline-primary" for="filter_1">Locations</label>

		</div>
	)
}

export default ButtonGroup;