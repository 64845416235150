import {
  GET_SERVICES_START,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE
} from '../../actions/Constants';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function servicesReducer(state = defaultState, action) {
  switch (action.type) {

    case GET_SERVICES_START:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: true
      };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}