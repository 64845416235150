import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {Link, useNavigate} from 'react-router-dom';

import ClassListView from '../../components/classListView/ClassListView';

import profile_img from '../../assets/catalyst_profile.png'; 
import arrow_forward from '../../assets/Arrow forward.png';
import arrow_backward from '../../assets/Arrow backward.png';
import './catalystDetails.css';

const UpdateCatalyst = (props) => {
	const {name ="ISA GUITANA"} = props;
	const navigate = useNavigate();

	const onHandleItemClick = (item) => {
    	navigate(`/booking-form/${item.name}`);
    }

	return (
		<div className="catalyst-details">
			<div className="catalyst-details-image-container">
				<img src={profile_img} className="catalyst-image" />
			</div>

			<div className="container-fluid py-5">
				<div className="heading-4 capitalize shadow_text">{name}</div>
				<div className="my-3">
					<button className=""><span className="px-2">+</span> ADD EXPEERTISE</button>
				</div>
				
			</div>

			<div className="container-fluid py-5">
				<div className="w-100 d-flex justify-content-between align-items-center">
				<div className="heading-5">About you</div>
				<div className="my-3">
					<button className="capitalize mx-5"><span className="px-2">+</span> Add Description</button>
					<button className="capitalize fs-6"><span className="px-2">+</span> Edit</button>
				</div>
				</div>
			</div>

			<div className="container-fluid py-5">
				<div className="w-100 d-flex justify-content-between align-items-center">
				<div className="heading-5">Classes</div>
				<div className="my-3">
					<button className="capitalize mx-5"><span className="px-2">+</span> Add Class</button>
					<button className="capitalize fs-6"><span className="px-2">+</span> Edit</button>
				</div>
				</div>
			</div>

			<div className="container-fluid py-5">
				<ClassListView  
					onHandleCTAClick = {(item) => console.log("item")}
					serviceList = {[]}
					
				/>
			</div>
		</div>
	)
}

export default UpdateCatalyst;