import React, {useState, useEffect} from 'react';
import Select from 'react-dropdown-select';
import './dropdown.css';

 const Dropdown = (props) => {
 	const {value = "", placeHolder = "All Locations", name="", options = [], onSelectChange, isDisabled = false } = props;

 	const [val, setVal] = useState([]);

 	const onHandleChange = (val) => {
 		onSelectChange(name, val[0].value);
 	}

 	useEffect(() => {
 		if(value) {
 			const filterInx = options.findIndex((item, inx) => item.value === value);
 			if(filterInx !== -1) {
 				const newArray = [];
 				newArray.push(options[filterInx]);
 				setVal(newArray);
 			}  	  
 		}
 	}, [value]);

 	return (
 		<Select
    		options={options}
    		values={val}
    		disabled={isDisabled}
    		onChange={(values) => onHandleChange(values)}
    		placeholder={placeHolder}
    		className = "color-primary-50"
  	/>
 	) 
};

export default Dropdown;
