import {useState, useEffect} from 'react';


const NewsCard = (props) => {
	const { item = {}, onCatalystClick } = props;
	const {blog_img = "", category = "", blog_date = "", blog_name = "", blog_description = ""} = item;

	const onClickCatalystCard = () => {
		onCatalystClick(item);
	}
	
	return (
		<>
			<div className="p-3 text-center">
          		<img src={blog_img} className="community_grid_item_img" />
	            <div className="pt-lg-4 pt-2 text-center">
	            	<div className="body-CaP color-primary-20">{blog_date}</div>
	            	<h5 className="py-2 capitalize heading-5 color-primary-50">{blog_name}</h5>
	            </div>
	            <div className="home_card_sub_text color-primary-50">
	            	{blog_description}
	            </div>
	            
			</div>
		</>
	)
}

export default NewsCard;