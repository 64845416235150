import React, {useState, useEffect} from 'react';
import UpdateCoupon from './settings-view/UpdateCoupon';
import {InitialCouponDetails} from '../../../../utils/DefaultInitialValues';

const CouponDetails = () => {
	const list = [
		{
			id: "1",
			couponType : "EUR",
		  	couponName : "Coupon 1",
		  	couponCode : "BB200",
		  	couponDiscount : "10",
		  	discountCurrency : "EUR",
		  	couponStartDate : "2024-02-02",
		  	couponEndDate : "2024-06-20",
		  	couponMaxUsageCount : 4,
		  	createdBy : ""
		},
		{
			id: "2",
			couponType : "percentage",
		  	couponName : "Coupon 2",
		  	couponCode : "CC100",
		  	couponDiscount : "20",
		  	discountCurrency : "EUR",
		  	couponStartDate : "",
		  	couponEndDate : "",
		  	couponMaxUsageCount : 10,
		  	createdBy : ""
		}
		
	];
	
	const [isShowCouponDialog, setShowCouponDialog] = useState(false);
	const [couponDetails, setCouponDetails] = useState(InitialCouponDetails);
	const [couponList, setCouponList] = useState([]);

	const addCoupon = () => {
		setCouponDetails(InitialCouponDetails);
		setShowCouponDialog(true);
	}

	const onCouponClick = (item = {}) => {
		const filterItemArray = couponList.filter((coupon, index) => coupon.id === item.id);

		if(filterItemArray.length > 0) {
			setCouponDetails(filterItemArray[0]);
			setShowCouponDialog(true);
		}
	}

	const fetchCoupons = () => {
	}

	const onUpdateSuccess = () => {
		fetchCoupons();
	}

	useEffect(() => {
		fetchCoupons();
	}, []);

	return (
		<div className="row">
			<div className="col-lg-8 m-auto">
				<div className="bg-white rounded-3 p-4 my-5">
					<h4 className="heading-4 color-primary-50">Coupons</h4>
					{couponList.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center my-5">
						{/*<div className="color-primary-50 body-R">There is no coupon added yet. Please add a coupon!</div>*/}
						<div className="color-primary-50 body-R">Upcoming Feature</div>
					</div>}
					
					<div className="w-100 d-flex justify-content-center my-3">
						<button className="tertiary_btn capitalize width-50 m-auto color-primary-50" onClick={addCoupon} disabled>Add New Coupon</button>
					</div>

					{couponList.length > 0 && couponList.map((coupon, index) =>

						<div>
							<div className="my-1 p-2">
			                   <div className="caption-2 py-1 color-primary-50">Valid till {coupon?.couponEndDate || "-"}</div>
				               
			                    <div className="d-flex justify-content-between align-items-center">
				                    <button className="ax-button d-flex flex-column justify-content-start " onClick = {() => onCouponClick(coupon)}>
				                    	<h5 className="heading-5 color-primary-50">{coupon?.couponName || "-"}</h5>
					                    
					                    <div className="text-left color-primary-50">
					                    	{coupon?.couponDiscount} {coupon?.couponType} 
					                    	<div>- applicable for max {coupon?.couponMaxUsageCount} services</div>
					                    </div>
				                    	
				                    </button>
				                    <div>
				                      <button className="ax-button color-secondary-100 fw-bold fs-5" onClick= {() => setCouponDetails(coupon)}>...</button>
				                    </div>
			                  	</div>
	            			</div>

	        				{(index !== couponList.length - 1) && <hr />}
						</div>
					)}
					
				</div>
			</div>
			{isShowCouponDialog && <UpdateCoupon 
				isOpen = {isShowCouponDialog} 
				details = {couponDetails}
				onUpdateSuccess = {onUpdateSuccess}
				handleClose = {() => setShowCouponDialog(false)}
			/>}
		</div>
	)
}

export default CouponDetails;