import {
  SAVE_FILTER_DATA,
  REMOVE_FILTER_DATA,
  baseURL
} from './Constants';

export const saveFilters = (filter) => (dispatch) => {
  dispatch({
    type: SAVE_FILTER_DATA,
    payload: filter,
    error: null
  });
};

export const removeFilters = () => (dispatch) => {
  dispatch({
    type: REMOVE_FILTER_DATA,
    payload: {},
    error: null
  });
};


