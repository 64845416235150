import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import {getDateFromEpoc, extractDateFromMoment} from '../../utils/util';
import './calendarView.css';

const CalendarView = (props) => {
  const {onDateSelect, dateVal = new Date(), allEvents = [], startDate = 0, endDate = 0} = props;

  const [date, setDate] = useState(new Date());

  const onChange = date => {
    setDate(date);
    onDateSelect(date);
  }

  useEffect(() => {
    setDate(date);
  }, [dateVal]);

  const tileContent = ({ date, view }) => {
    // Logic to determine the dots for each date based on event availability

    const event = allEvents.find(event => {
      return getDateFromEpoc(event.scheduleDate) === extractDateFromMoment(date)
    });

    if (event) {
      return <div className="dot"></div>;
    }
    return null;
  };

  const formatDate = date => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const max = endDate !== 0 ? new Date(endDate) : new Date("2024-12-31");
  const start = startDate !== 0 ? new Date(startDate) : new Date();

  return (
    <div>
      <Calendar
        onChange={onChange}
        value={date}
        tileClassName="content"
        minDate={start}
        tileContent={tileContent}
        maxDate={max}
      />
    </div>
  );
}

export default CalendarView;
