import React, {useState, useEffect} from 'react';
import CheckPickerView from '../../components/checkPicker/CheckPickerView';
import FilterView from '../../components/filters/FilterView';
import HelmetText from '../../components/helmet-text/HelmetText';
import Loading from '../../components/loading/Loading';

import {generateNewsDropdownList} from '../../utils/util';
import NewsCard from './NewsCard';

import './community.css';

const Community = () => {
	const [isLoading, setLoading] = useState(false);

	const [allNews, setAllNews] = useState([]);
	const [filterNews, setFilterNews] = useState([]);
	const [allDropdownList, setAllDropdownList] = useState({});

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

	const NewsArray = [];

	const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

	useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	setAllNews([NewsArray]);
    	setFilterNews(NewsArray);
    	const obj = generateNewsDropdownList(NewsArray);
    	setAllDropdownList(obj);
    }, []);

    useEffect(() => {
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = NewsArray;

    	if(selectedCatalyst.length) {
    		filterArray = filterArray.filter((item, index) => selectedCatalyst.includes(item.blog_name));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item.blog_type));
    	}


    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item.blog_topic));
    	}

    	setFilterNews(filterArray);
    }, [selectedFilters]);

    const handleDateChange = (stDate, edDate) => {
    	
    }

	return (
		<>
			<HelmetText />
			<div className="main-content-padding">
					<div className="desktop_view w-100">
						<div className="d-flex justify-content-between align-items-center">
							<h4 className="heading-4 color-primary-50">Community's updates</h4>
							<FilterView 
								isHideSwitch={true} 
								onHandleDateChange = {handleDateChange}
								allDropdownList = {allDropdownList}
								onCheckboxSelect = {onCheckboxSelect} 
							/>
						</div>
					</div>

					<div className="mobile_view">
						<h1 className="heading-1 text-center color-primary-50">News</h1>
					</div>

					<div className="community_grid">
						{filterNews.map((item, index) => 
							<NewsCard item={item} />
	            		)}
					</div>
				
			</div>
			{isLoading && <Loading />}
		</>
	)
}

export default Community;