import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { Breadcrumb } from "rsuite";

import AuthDialog from '../../components/authenticate/AuthDialog';
import HelmetDetails from "../../components/HelmetDetails";
import OnBoardingDialog from '../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import SubscriptionDetails from '../admin/dashboard/settings/SubscriptionDetails';
import {InitialUserDetails} from '../../utils/DefaultInitialValues';
import {saveAdmin} from '../../actions/admin.actions';

import right_arrow from '../../assets/thin arrow - right.png';
import './becomecatalyst.css';

const BecomeCatalyst = () => {
    const navigate = useNavigate();
    const [crumb, setCrumb] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();

    const saveAdminAction = saveAdmin;
    const [isShowLoginDialog, setShowLoginDialog] = useState(false);
    const [isShow, setShow] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);

    const [userDetails, setUserDetails] = useState(InitialUserDetails);
    
    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "becomeacatalyst":
					obj.path = "/becomeacatalyst";
					obj.label = "Become a Catalyst";
					crumbArray.push(obj);
					break;
				default: break;
				
			}

			setCrumb(crumbArray);
		});
	}

	const onHandleApplyClick = (selectedPlan = {}) => {

		if(isShow === false) {
			const newUserDetails = userDetails;

			if(selectedPlan && selectedPlan.subscriptionId) {
				newUserDetails.catalystDetails.planDetails = selectedPlan;
			}

			newUserDetails.role = "catalyst";
			setUserDetails(newUserDetails);
			setShowLoginDialog(true);
		}
	}

	const onLoginSuccess = (isSignupView = false, userinfo = {}) => {
        setShowLoginDialog(false);
        if(isSignupView) {
            setUserDetails(userinfo);
            setShow(true);
        } else {
            setLoggedIn(true);
            dispatch(saveAdminAction(userinfo));
            navigate("/dashboard");
        }
    }

	return (
		<>
			<HelmetDetails title="Become a catalyst | Minded." />
			<div className="main-content-padding">
				<Breadcrumb> 
	                {crumb.map((item, index) => {
	                	return (index !== crumb.length - 1) ? (
		                	<>
			                	<Link to={item.path}>
			                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
			                	</Link>
			                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt="" />
		                	</>
		                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
	                	}
	                )}
	                 <h1 className="heading-1 color-primary-50">Become a Catalyst</h1>
		        </Breadcrumb>
		             
		        <div className="w-100 d-flex color-primary-50">
					<div className="become_bg_1">
						<h1 className="h1 capitalize">Share your Expertise</h1>
						<div>
							<h5 className="heading-5 pt-3">Impact lives seeking personal growth.</h5>
						</div>
						

						<div className="body-R width-75">
							Increase your impact and revenue. Showcase your expertise to a global audience hungry for growth and leverage our platform for unparalleled visibility and reach.
						</div>

						<div className="py-4 apply_now_container">
							<button className="primary_btn capitalize" onClick={onHandleApplyClick}>Apply Now</button>
						</div>
					</div>

					<div className="become_bg_2">
					</div>
				</div>
				
		        <div>
		        	<div className="container px-2 px-lg-2 px-md-2 pb-5">
						<div className="body-CAP capitalize color-primary-50 text-center">Your Journey</div>
						<h5 className="heading-5 color-primary-50 py-3 width-75 m-auto text-center">
							Expand your reach and connect with an audience of users who are focused on improving their well-being through personal growth.
						</h5>
					</div>

					<div className="w-100 secondary_90_bg flex_container_70 padding_70 rounded-4">
						<div className="text-center width-75 m-auto">
							<h6 className="heading-6 color-primary-50 width-75 text-center m-auto">
								Our platform is designed to connect users with providers like you who have a wealth of experience in offering personal growth services such as:
							</h6>
						</div>
						<div className="become_catalyst_flex px-lg-5 px-md-2 w-100 m-auto">
							{["Coaching", "Meditation", "Yoga", "Nutrition", "Fitness", "Mind fullness", "Counselling", "Finance"].map((item, index) => <h5 className="heading-5 card_item color-primary-50">{item}</h5>)}
						</div>
					</div>
				</div>

				<div className="container-fluid w-100">
					<div>
						<h4 className="heading-4 color-primary-50 text-center">Select your plan</h4>
					</div>
			        {!isShow && <SubscriptionDetails onHandlePlanDetailsChange = {(plan) => onHandleApplyClick(plan)} />}
			    </div>	
	       		
	       		<div className="container-fluid w-100">
	       			<div className="flex_container_50 w-100">
	       				
	       				<div className="w-100">
							<div className="body-CAP capitalize color-primary-50 text-center py-2">BECOME A CATALYST</div>
							<h4 className="w-100 heading-4 color-primary-50 d-flex flex-column align-items-center justify-content-center">
								<div>Join us today</div>
								<div> and start expanding your reach.</div>
							</h4>
						</div>
						<h5 className="heading-5 color-primary-50 text-center px-2 px-lg-5 px-md-2 w-75 m-auto">
							Apply to our CATALYSTS.PORTAL, where you can connect with motivated individuals who are striving for a better life.
						</h5>
						<div className="d-flex align-items-center justify-content-center w-100">
							<button className="secondary_btn color-accent-90 capitalize" onClick={onHandleApplyClick}>Apply Now</button>
						</div>
					</div>
				</div>
			</div>

			{isShowLoginDialog && <AuthDialog 
				isOpen = {isShowLoginDialog} 
				onHandleClose = {(val) => setShowLoginDialog(false)} 
				onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
				isShowSignUp = {true}
				userinfo = {userDetails}
			/>}

            {isShow && <OnBoardingDialog 
                isOpen = {isShow}
                onHandleClose={() => setShow(false)} 
                setLoggedIn = {val => setLoggedIn(val)}
                userinfo = {userDetails}
            />}
		</>
	)
}

export default BecomeCatalyst;

