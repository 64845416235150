import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import HelmetText from '../../../components/helmet-text/HelmetText';

import AdminDashboard from './admin/AdminDashboard';
import CatalystDashboard from './catalyst/CatalystDashboard';
import CustomerDashboard from './customer/CustomerDashboard';

import './dashboard.css';

const Dashboard = () => {
	const [role, setRole] = useState("admin");

	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));
    
    useEffect(() => {
    	if(loggedUser && loggedUser.role) {
    		const role = loggedUser.role;
    		setRole(role.toLowerCase()) //Amit
    	}
    }, [loggedUser]);

	return (
		<>
			{role === "catalyst" && <CatalystDashboard />}
			{role === "admin" && <AdminDashboard />}
			{role === "customer" && <CustomerDashboard />}	
		</>
	)
}

export default Dashboard;