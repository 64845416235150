import {Helmet} from 'react-helmet';

const HelmetDetails = props => {
	const {title = "", href = "#"} = props;

	return (
		<Helmet>
      		<meta charSet="utf-8" />
      		<title>{title}</title>
      		<link rel="canonical" href={href} />
    	</Helmet>
	)	
}

export default HelmetDetails;