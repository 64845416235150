import {addZeroBefore} from '../../utils/util';
import Input from '../../components/input/Input';

const PackageView = (props) => {
	const {
    label="Price Option",
    list = [], 
    version = "2.1.1", 
    title = "", 
    firstLabel = "", 
    secondLabel = "", 
    firstFieldName = "",
    secondFieldName = "", 
    removeItem, 
    handleChange,
    isShow = "true",
    isError = false
  } = props;

	return (
		<div className="row m-auto color-neutral-90_bg p-3 my-3 rounded-3">
        {list.map((item,index) => 
          <>
            <div className="col-lg-12 mt-2 ">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6 className="color-primary-50 heading-6">{label} {index+1} </h6>
                <div className="">
                  <button type="button" className="ax-button" onClick={() => removeItem(title, index)} disabled={index === 0} >
                    <i className='bx bx-x-circle text-danger'></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 my-2">
              <Input label={firstLabel} name={firstFieldName} type="text" value={item[firstFieldName]} onInputChange={(e) => handleChange(title, e, index)} />
            </div>
            <div className="col-lg-6 my-2">
              <Input label={secondLabel} name={secondFieldName} type="text" value={item[secondFieldName]} onInputChange={(e) => handleChange(title, e, index)} />
            </div>
          </>
        )}

        {isError && <div className="text-danger">! {title} details can not be empty</div>}
    </div>
	)
}

export default PackageView;