import {
  GET_SCHEDULES_START,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_FAILURE,
  baseURL
} from './Constants';

import {headerOption} from '../utils/DefaultInitialValues';

export const getSchedules = (userId = "", serviceId = "", serviceType = "", serviceCategoryId = "",  scheduleStartDate = "", scheduleEndDate = "", scheduleStatus = "", scheduleId = "") => async (dispatch) => { 
  let param = "";
  let isExist = false;

  if(userId) {
    isExist = true;
    param +=  `userId=${userId}`;
  }

  if(serviceCategoryId) {
    if(isExist) param += "&";
    param +=  `serviceCategoryId=${serviceCategoryId}`;
    isExist = true;
  }

  if(serviceId) {
    if(isExist) param += "&";
    param +=  `serviceId=${serviceId}`;
    isExist = true;
  }

  if(serviceType) {
    if(isExist) param += "&";
    param +=  `serviceType=${serviceType}`;
    isExist = true;
  }

  if(scheduleId) {
    if(isExist) param += "&";
    param +=  `scheduleId=${scheduleId}`;
    isExist = true;
  }

  if(scheduleStartDate) {
    if(isExist) param += "&";
    param +=  `scheduleStartDate=${scheduleStartDate}`;
    isExist = true;
  }

  if(scheduleEndDate) {
    if(isExist) param += "&";
    param +=  `&scheduleEndDate=${scheduleEndDate}`;
    isExist = true;
  }

  if(scheduleStatus) {
    if(isExist) param += "&";
    param +=  `&scheduleStatus=${scheduleStatus}`;
    isExist = true;
  }

  dispatch({
    type: GET_SCHEDULES_START,
    payload: [],
    error: null
  });
  
  const URL = `${baseURL}/minded-services/getScheduleDetails?${param}`;
  
  return await fetch(URL, headerOption)
  .then(response => {
    const resp = response;

    const {status = ""} = resp;
  
    if(status === 200 || status === 201) {
      return response.json().then(res => {
        dispatch({
          type: GET_SCHEDULES_SUCCESS,
          payload: res,
          error: null
        });
      });
    } else {
      //Error
      dispatch({
        type: GET_SCHEDULES_FAILURE,
        error: true,
      });
    }
  })
  .catch((error) => {
    console.log('error getSchedules', error);
    dispatch({
      type: GET_SCHEDULES_FAILURE,
      error: error
    });
  });
}







