import React, {useEffect, useState} from 'react';
import HelmetDetails from "../../components/HelmetDetails";
import HelmetText from '../../components/helmet-text/HelmetText';
import Loading from '../../components/loading/Loading';
import {baseURL} from '../../actions/Constants';
import DialogView from '../../components/dialog/DialogView';
import {InitialContactDetails} from '../../utils/DefaultInitialValues';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import './contact.css';

const Contact = () => {
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const [contactUs, setContactUs] = useState(InitialContactDetails);
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

    const onHandleSubmit = (e) => {
        e.preventDefault();

        const isValid = validate();
        if(isValid) {
            //call API
            submitQueries();
        }
    }


    const validate = () => {
       return true;
    }

    const submitQueries = async () => {
        setLoading(true);
        const URL = `${baseURL}/contactUs`;

        //const myHeaders = getAuthenticateHeader();

        return await fetch(URL, {
            method: 'POST',
            body: JSON.stringify(contactUs),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => {
            const resp = response;

            const {status = ""} = resp;
            setLoading(false);

            if(status === 200) {
              return response.json().then(res => {
                setFormSubmitted(true);
                setContactUs(InitialContactDetails);
              });
            } else {
              //Error
              setLoading(false);
              setError(true);
            }
        })
    }

    const onInputChange = e => {
        setContactUs({...contactUs, [e.target.name]: e.target.value})
    }

    const {
        name = "",
        email = "",
        phone = "",
        subject = "",
        msg = ""
    } = contactUs || {};


	return(
        <>
            <HelmetDetails title="Contact | Minded." />
            <HelmetText />
            <div className="main-content-padding">
                <div className="mobile_view">
                    <h1 className="heading-1 text-center color-primary-50">Contact us</h1>
                </div>
                {!isFormSubmitted && <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                    		<div className="contact" id="contact">
                                <form onSubmit= {e => onHandleSubmit(e)}>
                                    <div className="input-box">
                                        <input type="text" placeholder="Full Name *" className="p-2" required name="name" value={name} onChange= {e => onInputChange(e)} />
                                        <input type="text" placeholder="Mobile Number" className="p-2" name="phone" value = {phone}  onChange= {e => onInputChange(e)} />
                                    </div>
                                    <div className="input-box">
                                        
                                        <input type="email" placeholder="Email Address *" className="p-2" required name="email" value = {email}  onChange= {e => onInputChange(e)} />
                                        
                                        <select required className="p-2" name="subject" value = {subject} onChange= {e => onInputChange(e)}>
                                            <option disabled selected value="">Email Subject *</option>
                                            <option value="Payment and Refunds">Payment and Refunds</option>
                                            <option value="I am interested to become a Catalyst but have few queries">I am interested to become a Catalyst but have few queries</option>
                                            <option value="I am interested in a Business Partnership">I am interested in a Business Partnership</option>
                                            <option value="I need to reschedule a class">I need to reschedule a class</option>
                                            <option value="I want to cancel my subscription">I want to cancel my subscription</option>
                                        </select>
                                    </div>
                                    <textarea name="msg" id="" rows="4" cols="50" placeholder="Your Message *" value = {msg}  onChange = {e => onInputChange(e)}>

                                    </textarea>
                                    <button type="submit" value="Send Message" className="my-3 secondary_btn capitalize LINK">Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>}
                {isFormSubmitted && <DialogView show={isFormSubmitted} isContatctUs = {true} onHandleClose = {() => setFormSubmitted(false)} />}
            </div>
            {isLoading && <Loading />}
         
            {isError && <CustomizedSnackbars isShow={isError} text= { "Something went wrong. Please try after sometime."} />}
        </>
	)
}

export default Contact;

