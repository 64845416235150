import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {convertDateToString} from '../../utils/util';

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import './slider.css';
// import required modules
import { Scrollbar } from "swiper/modules";
import about_2 from '../../assets/about_2.png';

const CatalystProgressSlider =  (props) => {

  const {list = [], onCardClick } = props;

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        scrollbar={{
          hide: false
        }}
        modules={[Scrollbar]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 3,
          }
      }}
      >
        {list.map((item, index) => {
          
          const { name = ""} = item || {};

          const feature_array = item?.catalystDetails?.serviceCategory?.map((item) => item?.name);
          const location_array = item?.catalystDetails?.locations?.map((item) => item?.city);
  
          return (
            <SwiperSlide onClick = {() => onCardClick(item)}>
              <div className="slide_container">
                <div className="slide_img_container">
                  <img src={item?.profileDescription?.profilePic || about_2} className="catalyst_grid_item_img" />
                </div>

                <div className="pb-5 text-center w-100">
                  <div className="body-CAP color-primary-20">{feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>
                  <h5 className="heading-5 color-primary-50">{name}</h5>
                 
                  <div className="body-CAP color-primary-20 text-center w-100">{location_array.length > 0 && location_array.join(" | ") || "-"}</div>
                </div>
              </div>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}

export default CatalystProgressSlider;
