import { combineReducers } from 'redux';
import adminReducer from './Admin/admin.reducer';
import bookingsReducer from './Bookings/bookings.reducer';
import filterReducer from './Filters/filter.reducer';
import servicesReducer from './Services/services.reducer';
import catalystsReducer from './Catalysts/catalysts.reducer';
import customersReducer from './Customers/customers.reducer';
import schedulesReducer from './Schedules/schedules.reducer';

const appReducer = combineReducers({
  loggedAdmin: adminReducer,
  filter: filterReducer,
  bookings: bookingsReducer,
  services : servicesReducer,
  catalysts: catalystsReducer,
  customers: customersReducer,
  schedules: schedulesReducer
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;