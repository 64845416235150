import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../components/loading/Loading';
import CalendarView from '../../../components/calendarView/CalendarView';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import {getSchedules} from '../../../actions/schedules.actions';

import {getSelectedDateSchedules, getDateFromEpoc} from '../../../utils/util';
import {getTimeZoneKey} from '../../../utils/DefaultStates';
import brown_cancel from '../../../assets/brown_cancel.png';
import Edit from '../../../assets/Edit.png';
import  './schedule-calendar.css';

const ScheduleCalendar = (props) => {
	const dispatch = useDispatch();
	const {bookingDetails = [], currentSchedules = {}, setCurrentSchedules} = props;

	const [allSchedules, setSchedules] = useState([]);
	const [bookedSchedules, setBookedSchedules] = useState([]);
	const [nonBookedSchedules, setNonBookedSchedules] = useState([]);

	const [dateVal, setDate] = useState(new Date());
	const [serviceId, setServiceId] = useState("");
	const [isError, setError] = useState(false);

	const [dateWiseSchedules, setDateWiseSchedules] = useState([]);
	const getSchedulesAction = getSchedules;

	const {
       details: schedulesDetails,
       loading: schedulesLoading,
       error: schedulesError
    } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

	useEffect(() => {
		const srvId = bookingDetails && bookingDetails[0] && bookingDetails[0]?.serviceId;
		const filteredBookedSchedules = bookingDetails.filter(item => item.bookingStatus === "active");
		setBookedSchedules(filteredBookedSchedules);

		const filteredNonBookedSchedules = bookingDetails.filter(item => item.bookingStatus === "pending_confirmation");

		setNonBookedSchedules(filteredNonBookedSchedules);

		if(srvId) {
			setServiceId(srvId);
			dispatch(getSchedulesAction("",srvId, "", "", new Date().getTime(), 1721919792000, "active", ""));
		}
	}, []);

	useEffect(() => {
		if(Array.isArray(schedulesDetails)) {
			setSchedules(schedulesDetails);	
		}
	}, [schedulesDetails]);

	useEffect(() => {
		const dateWiseSchedules = getSelectedDateSchedules(allSchedules, dateVal);
		setDateWiseSchedules(dateWiseSchedules);
	}, [allSchedules, dateVal]);

	const updateSchedule = (e, sch = {}) => {
		const {checked = false} = e.target;

		const newArray = [...currentSchedules];
		const filterTicketIndex = newArray.findIndex((item, i) => item?.scheduleId === sch.scheduleId);
		
		if(checked) {
			if(nonBookedSchedules.length >= 1) {
				setError(false);
				const selectedSchedule = nonBookedSchedules[0];
				selectedSchedule.scheduleId = sch.scheduleId;
				selectedSchedule.schedule.scheduleDate = sch.scheduleDate;

				newArray.push(selectedSchedule);
				nonBookedSchedules.splice(0, 1);
				
				const newNonBookedList = nonBookedSchedules;
				setNonBookedSchedules(newNonBookedList);
			} else {
				setError(true);
			}
		} else {
			newArray.splice(filterTicketIndex, 1);
		}

	    setCurrentSchedules(newArray);
	}

	const isChecked = (schId = "") => {
		const newArray = [...currentSchedules];
		const findIndex = currentSchedules.findIndex((item, i) => item?.scheduleId === schId);

		return findIndex !== -1;
	}

	const isDisabled = (schId = "") => {
		const newArray = [...bookedSchedules];
		const findIndex = bookedSchedules.findIndex((item, i) => item?.scheduleId === schId);

		return findIndex !== -1;
	}


	

	return(
		<div>
			<div className="container my-5">
				<div className="row">
					<div className="col-lg-5 m-auto">
						
						<CalendarView onDateSelect = {(val) => setDate(val)} dateVal = {dateVal} allEvents = {allSchedules} endDate = {bookingDetails && bookingDetails[0]?.expiredDate} />

						<h5 className="subtitle-R color-primary-50 mt-4">Select Slot</h5>
						
						{dateWiseSchedules?.length === 0 && <div className="color-primary-50 body-R mt-3">No Slot available</div>}
						<div className="show_time_grid my-4 w-100">
						
						{dateWiseSchedules.map((item, index) => {
							return (
								<div>
									<input id={item?.scheduleId} type="checkbox" className="a11y time_slot" checked={true} checked = {isChecked(item?.scheduleId)} onChange = {(e) => updateSchedule(e, item)} disabled =  {isDisabled(item?.scheduleId)} />
									<label for={item?.scheduleId} className="time_conatiner">{item.serviceDetails?.startTime} {getTimeZoneKey(item?.serviceDetails?.serviceTimezone)}</label>
								</div>
							)
						})
						}
						</div>
			        </div>

			        <div className="col-lg-5">
						<div className="booking_details_card time_remaining_container">
							<div className="w-100 subtitle-B color-primary-50">
								<div className="d-flex justify-content-between align-items-center">
									<div>Booked schedules</div>
									<div className="subtitle-R color-primary-50">{bookedSchedules.length}/{bookingDetails.length} Sessions</div>
								</div>
							</div>
								{bookedSchedules.length === 0 && <div className="color-primary-50 subtitle-R">No bookings</div>}
								{bookedSchedules.length > 0 && bookedSchedules.map((item, id) => 
									<div className="w-100 color-primary-50 subtitle-B d-flex justify-content-between">
										<div className="subtitle-R">{getDateFromEpoc(item?.schedule?.scheduleDate)}</div>
										<div className="subtitle-R"> {item?.scheduleDetails?.serviceDetails?.startTime} - {item?.scheduleDetails?.serviceDetails?.endTime} {getTimeZoneKey(item?.scheduleDetails?.serviceDetails?.serviceTimezone)}</div>
									</div>
								)}	
						
							<hr />

							<div className="w-100 subtitle-R color-primary-50">
								<div className="color-primary-50 subtitle-B">Recent schedules</div>
								{currentSchedules?.length === 0 && <div className="color-primary-50 subtitle-R my-3">No bookings</div>}
							
								{currentSchedules?.length > 0 && currentSchedules.map((item, id) => <div className="w-100 color-primary-50 subtitle-B d-flex justify-content-between my-3">
									<div>{getDateFromEpoc(item?.schedule?.scheduleDate)}</div>

									<div> {item?.schedule?.serviceDetails?.startTime} - {item?.schedule?.serviceDetails?.endTime} {getTimeZoneKey(item?.schedule?.serviceDetails?.serviceTimezone)}</div>
								</div>
								)}
							</div>

						</div>
			        </div>
			    </div>
			</div>
			{schedulesLoading && <Loading />}
			{isError && <CustomizedSnackbars isShow={isError} text="You have reached to maximum bookings." showAlert={(isHide) => setError(false)} />}      
		</div>
	)
}

export default ScheduleCalendar;
