import tick from '../../../assets/tick.png';

const ThankYou = (props) => {
	const {serviceType = ""} = props;

	return (

		<div className="container guideline_info dialog_padding">
	    	<div className="row">
	    		
	            <div className="col-lg-12 col-md-12 my-3">
	              
	              <div className="d-flex flex-column align-items-center  justify-content-center my-3">
	              	<div className="d-flex justify-content-center my-5"><img src={tick} /></div>
	              	<h5 className="heading-5 my-3 color-primary-50 text-center">
						Congratulations! Your {serviceType} has been successfully published.
	              	</h5>
	                

	                {/*
	                <div className="subtitle-R my-3">Would you like to schedule the service now? Please press the below Schedule now button.</div>
					<div>
						<button onClick= {onHandleScheduleNow} className="my-3 tertiary_btn">Schedule now</button>
					</div>
					*/}
	              </div>
	            </div>
			</div>
		</div>
	)
}

export default ThankYou;