import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import HelmetDetails from "../../components/HelmetDetails";
import HelmetText from '../../components/helmet-text/HelmetText';
import Loading from '../../components/loading/Loading';
import about_3 from "../../assets/about-3.png"; 
import about_2 from "../../assets/about_2.png"; 
import about_expect from "../../assets/about_expect.png"; 
import become_catalyst_img from '../../assets/become_catalyst_img.png';
import './about.css';

const About = () => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useState(false);


	const handleAboutBtnClick = () => {
    	navigate('/about');
    }

    const handleCommunityBtnClick = () => {
    	navigate('/news');
    }

    const handleCatalsystBtnClick = () => {
    	navigate('/catalysts');
    }

    const handleBecomeCatalystBtnClick = () => {
    	navigate('/becomeacatalyst');
    }

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

	return (
		<>
			<HelmetDetails title="About | Minded." />
			<HelmetText />
			<div className="main-no-content-padding flex_container_24">
				<div className="mobile_view">
					<h1 className="heading-1 text-center color-primary-50">About Minded</h1>
				</div>

				<div className="about-grid my-3">
					<div className="each_about_card about-card text-center white-text text-center">
						<h2 className="heading-2 py-2 white_text">Are you ready</h2>
						<h5 className="heading-5 about_ready_container text-center white_text">to take the step towards personal growth?</h5>
					</div>
				
					<div className="each_about_card">
						<img src={about_3} />
					</div>

					<div className="each_about_card">
						<img src={about_2} />
					</div>
					
					<div className="each_about_card about-card-odd color-neutral-00">
						<h5 className="heading-5 color-primary-50 p-4 text-center">
							<div className="py-2">Are you Minder?</div>
							<div>A Minder is an individual who is looking to enhance their personal development and empowerment. Whether it’s Nutrition, Therapy, Breath work or Yoga, learn and develop skills to help you reach your goals.</div>
						</h5>
						
					</div>
				</div>

				<div className="about-journey">
					<div className="body-CAP capitalize color-primary-50">Our mission</div>
					<h5 className="heading-5 color-primary-50 py-3 font-400">


					<div>Personal growth isn’t just about fixing your problems. It’s also about recognising and nurturing your strengths, talents, and passions.</div>
					<div className="py-3">Here at Minded we provide a platform for users to connect with personal growth professionals we call Catalysts and provide a community for everyone on their journey towards becoming their best self.</div>
					</h5>
					{
					/*<div className="d-flex justify-content-center py-4">
						<button className="capitalize secondary_btn LINK" onClick={handleCommunityBtnClick}>
							Subscribe to our newsletter
						
						</button>
					</div>
					*/}
				</div>

				
				<div className="yellow_container">
					<div className="yellow_text_container m-auto">
						<div className="capitalize body-CAP color-neutral-100 text-center">FIND A CATALYST</div>
						<h5 className="heading-5 color-neutral-100 text-center">Are you seeking guidance in career advancement, nutrition, mindset development, or overall well-being?</h5>
					</div>

					<div className="yellow_bottom_container">
						<div>
							<img src={about_expect} className="yellow_img" alt="" />
						</div>

						<div className="right_view">
							<div className="body-R">Look no further.</div>
							<div className="body-R">Our Catalysts, experts within their personal growth fields, are here to offer their insights, knowledge, and support.</div>
							<div className="body-R">Each Catalyst brings a unique set of skills and experiences, ensuring that you can find the one who best fits your needs and resonates with your goals.</div>

							<div className="d-flex start-here-CTA my-2">
								<button className="primary_btn capitalize color-primary-50 LINK" onClick={handleCatalsystBtnClick}>Find an Expert</button>
							</div>
						</div>	
					</div>
				</div>

				<div className="salmon_container color-secondary-90_bg my-5">
					<div className="px-3 flex_container gap_8">
						<h3 className="w-100 body-CAP color-primary-50 capitalize text-center">Become a Catalyst</h3>
						<h5 className="w-100 heading-5 color-primary-50 text-center">Are you a facilitator, teacher, coach, service provider?</h5>
					</div>

					<div className="grid-container">
					  	<div className="item">
					    	<img src={become_catalyst_img} alt="" />
					  	</div>

						<div className="item">
					    	<div className="flex_container_24">
								<div className="body-R color-primary-brand-a">Are you looking for an opportunity to share your expertise and make an impact in the lives of individuals seeking personal growth?</div>
							
								<div>
									<div className="body-R color-primary-brand-a">Look no further! We are excited to invite you to join our CATALYSTS.PORTAL, where you can connect with motivated individuals who are striving for a better life.</div>
									<div className="body-R color-primary-brand-a py-1">Our platform is designed to connect users with providers like you, who have a wealth of experience in offering personal growth services such as coaching, counselling, meditation, yoga, fitness, nutrition, and mindfulness.</div>
								</div>

								<div className="body-R color-primary-brand-a">As a provider on our platform, you'll have the unique chance to expand your reach and connect with a diverse audience of users who are looking to improve their wellbeing through personal growth. Our platform provides a secure and user-friendly environment, so you can focus on what you do best: providing quality services and helping people reach their goals.</div>
							
								<div className="body-R color-primary-brand-a">Join us TODAY and start expanding your reach with our CATALYSTS.PORTAL!</div>
								
								<div className="d-flex start-here-CTA my-2 w-100">
									<button className="secondary_btn capitalize px-3 LINK" onClick={handleBecomeCatalystBtnClick}>
										View Subscription options
									</button>
								</div>
							</div>	
						</div>
					</div>
				</div>	
				
			</div>
			{isLoading && <Loading />}
		</>
	)
}

export default About;