import {useState, useEffect, useRef} from 'react';
import tick from '../../../assets/tick.png';
import { Link} from 'react-router-dom';

const Confirmation = (props) => {
	const dialogRef = useRef();
	const {onHandleAllService, userDetails = {}} = props;

	const {
		role = "customer"
	} = userDetails || {}; 

	useEffect(() => {
	    try {
	      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	    } finally {
	    }
  	}, []);

	return (

		<div className="container guideline_info dialog_padding" ref={dialogRef}>
	    	<div className="row">
	    		
	            <div className="col-lg-8 col-md-8 my-3 m-auto">
	              	<div className="d-flex justify-content-center my-5"><img src={tick} /></div>
	              	<div className="d-flex flex-column align-items-center  justify-content-center my-3">
	              		<h5 className="heading-5 my-3 color-primary-50 text-center">Yay! your request has been submitted successfully.</h5>
	              		<>
		              		{role=== "customer" && <div className="body-R color-primary-50 my-3 width-50 text-center">
								Want to book a service? 
							</div>}

							{role=== "catalyst" && <div className="body-R color-primary-50 my-3 width-50 text-center">
								We will send the confirmation once it has been approved. Typically, approvals take around 12 to 24 hours to be reviewed.
							</div>}

							<div className="my-3">
								<button
									onClick={onHandleAllService} 
									className="my-3 mx-3 tertiary_btn color-primary-50 capitalize"
								>
								View all services
								</button>
							</div>
						</>
		              	

		                {/*role === "catalyst" && 
							<>
								<div className="body-R color-primary-50 my-3 text-center">
									Already want to fill up your service information to enlist? 
								</div>
								<div className="my-3">
									<button onClick= {onHandleScheduleNow} className="my-3 mx-3 tertiary_btn color-primary-50">Add Service</button>
								</div>
							</>
						*/}
	              	</div>
	            </div>
			</div>
		</div>
	)
}

export default Confirmation;