import NoResult from '../../../../assets/Noresult.png';

const Noresult = (props) => {
	const {serviceName = "Classes", onCTAClick} = props;
	return (
		<div className="flex_container_40 w-100 align-items-center">
			<img src={NoResult} />
			<div className="subtitle-R color-primary-50">No request yet</div>
		</div>
	)
}

export default Noresult;