import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, applyMiddleware, compose } from 'redux';

import { Provider } from 'react-redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import './index.css';


function saveToSessionStorage(state) {
	try {
		const serializedState = JSON.stringify(state); //state
		localStorage.setItem('minded', serializedState);
	}
	catch {
		console.log('error');
	}
}

function loadFromLocalStoarge() {
	try {
		//localStorage.removeItem('state');
		const serializedState = localStorage.getItem('minded');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);	
	} catch(e) {
		return undefined;
	}
}

const persistedState = loadFromLocalStoarge();
const storeEnhancer = compose(
    applyMiddleware(thunk),
    /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
);

export const store = createStore(
	rootReducer,
	persistedState,
	storeEnhancer,
);

// If you want to start measuring performance in your app, pass a function
store.subscribe(()=> saveToSessionStorage(store.getState()));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
       <Provider store={store}>
    		<App />
    	</Provider>
    </React.StrictMode>,
    document.getElementById('root')
);