import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import React, {useState} from 'react';
import MUIDataTable from "mui-datatables";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import './table.css';

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const Table = (props) => {
  const {title="", headers = [], rowData = [], clsName="", options = {}, ref = null} = props;
  const [responsive, setResponsive] = useState('vertical');
 
  //const newOptions = {...options, responsive}

  const tableOptions = {
    responsive
  };

  const finalOptions = {...options, ...tableOptions }

  return (

    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          className = {`gfg`}
          ref = {ref}
          title={title}
          data={rowData}
          columns={headers}
          options={finalOptions}
        />
      </ThemeProvider>
    </CacheProvider>
    
  );
}

export default Table;
