import {useEffect, useState} from 'react';
import AdminSettings from './AdminSettings';
import CatalystSettings from './CatalystSettings';
import {useDispatch, useSelector} from 'react-redux';

const Settings = () => {

	const [role, setRole] = useState("admin");

	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));
    
    useEffect(() => {
    	if(loggedUser && loggedUser?.role) {
    		const role = loggedUser?.role;
    		setRole(role.toLowerCase()) //Amit
    	}
    }, [loggedUser]);

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

	return (
		<>
			{role === "catalyst" && <CatalystSettings  />}
			{role === "admin" && <AdminSettings />}
		</>
	)
}

export default Settings;