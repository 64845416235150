import Input from '../input/Input';
import UploadImage from '../uploadimg/UploadImage';
import img_placeholder from '../../assets/img_placeholder.png';

const AddGuest = (props) => {

	const {guest = {}, removeItem, onHandleItemChange, uploadProfile, index = 0} = props;

  const {
    guestName = "",
    guestBio = "",
    guestPic = ""
  } = guest;

	return (
		<>
      <div className="d-flex justify-content-between align-items-center w-100">
        <h6 className="color-primary-50 heading-6">Guest {index+1} </h6>
        <div className="">
          <button type="button" className="ax-button" onClick={() => removeItem("Guest", index)} disabled = {index === 0}>
            <i className='bx bx-x-circle text-danger'></i>
          </button>
        </div>
      </div>
  
    	<div className="w-100 my-2">
        <Input
          name="guestName"
          label="Guest name"
          placeHolder="xxx" 
          value={guestName}
          onInputChange={(e) => onHandleItemChange("Guest", e, index)} 
        />
	    </div>

      <div className="w-100 my-2">
        <Input
          name="guestBio"
          label="Guest description"
          placeHolder="xxx" 
          value={guestBio}
          onInputChange={(e) => onHandleItemChange("Guest", e, index)} 
        />
      </div>

      <UploadImage 
        pic =  {(typeof(guestPic)) !== 'string' ? URL.createObjectURL(guestPic) : img_placeholder}
        uploadPicture = {(e) => uploadProfile(e, index)}
      />
		</>
	)
}

export default AddGuest;