import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation, Link, useParams } from 'react-router-dom';
import { Breadcrumb } from "rsuite";

import {baseURL} from '../../../../actions/Constants';

import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import AddServiceDialog from '../../../admin/createService/serviceDialog';
import HelmetText from '../../../../components/helmet-text/HelmetText';
import Loading from '../../../../components/loading/Loading';
import TabView from '../../../../components/tabView/TabView';
import {DummyService} from '../../../../assets/data';

import {getTimeDetails, getAuthenticateHeader, getDateFromEpoc} from '../../../../utils/util';

import right_arrow from '../../../../assets/thin arrow - right.png';
import img_placeholder from '../../../../assets/img_placeholder.png';

import {
  InitialServiceDetails,
  headerOption
} from '../../../../utils/DefaultInitialValues';

import './service_details.css';

const ServiceDashboard = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [crumb, setCrumb] = useState([]);
	const [user, setUser] = useState({});
	const location = useLocation();

	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [isShowDialog, setShowDialog] = useState(false);
	const [viewIndex, setViewIndex] = useState(0);
	
	const [serviceDetails, setServiceDetails] = useState(InitialServiceDetails);
	const { id } = useParams();

	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	const tabList = [
		{
			"label": "Overview",
			"value" : "Overview"
		},
		{
			"label": "Tickets",
			"value" : "Tickets"
		},
		{
			"label": "Schedule",
			"value" : "Schedule"
		},
		{
			"label": "Orders",
			"value" : "Orders"
		}
	];

	const onEditClick = (inx) => {
		setViewIndex(inx);
		setShowDialog(true);
	}

	const OverView = () => {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center my-3">
					<h4 className="heading-4 color-primary-50">Details</h4>
					<button 
						className="ax-button Link color-secondary-100 capitalize" 
						onClick = {() => onEditClick(2)}
						disabled
					>
						Edit
					</button>
				</div>

				<div className="details-grid">
					<div className="flex_container_60 bg-white border_50 padding_70">
						<h5 className="heading-5 color-primary-50">General Information</h5>
						<div>
							<div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Name</div>
								<div className="body-B color-primary-50">{serviceName}</div>
							</div>

							<div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Details</div>
								<div className="body-B color-primary-50">
									{serviceDescription}
								</div>
							</div>

							<div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Category</div>
								<div className="flex_container_30">
									<div className="category_container">{serviceCategoryDetails?.serviceCategoryName || "-"}</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex_container_60 bg-white border_50 padding_70">
						<h5 className="heading-5 color-primary-50">Service Photo</h5>
						
						{serviceImg && serviceImg.length !== 0 && <div className="details-picture-grid">
							{serviceImg.map((item, index) => {
								return (
									<div className="py-2">
										<img src={item || img_placeholder} style={{width: '120px', height: "120px"}} />
									</div>
								)
							})}
						</div>}

						{serviceImg && serviceImg.length === 0 && <div className="details-picture-grid">
							{[0,1,2,3,4].map((item, index) => {
								return (
									<div className="py-2">
										<img src={img_placeholder}  />
									</div>
								)
							})}
						</div>}
					</div>
				</div>

				{serviceType !== "PRIVATE" && <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
					<h4 className="heading-4 color-primary-50">Guests</h4>
					<button 
						className="ax-button Link color-secondary-100 capitalize" 
						onClick = {() => onEditClick(4)}
						disabled
					>
						Edit
					</button>
				</div>}

				{serviceType !== "PRIVATE" && <div className="details-grid my-3">
					{serviceScheduleDetails && serviceScheduleDetails?.guestDetails && serviceScheduleDetails?.guestDetails?.map((item, index) => {
						return (
							<div className="flex_container_60 bg-white border_50 padding_70">
								<h5 className="heading-5 color-primary-50">Guest {index+1}</h5>
								
								<div>
									<div className="py-3">
										<div className="body-CAP capitalize py-2 color-primary-50">Name</div>
										<div className="body-B color-primary-50">{item?.guestName || "-"}</div>
									</div>

									<div className="py-3">
										<div className="body-CAP capitalize py-2 color-primary-50">Short description</div>
										<div className="body-B color-primary-50">
											{item?.guestBio || "-"}
										</div>
									</div>
								</div>								
							</div>
						)	
					})}
				</div>}	
			</>
		)
	};

	const TicketView = () => {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center my-3">
					<h4 className="heading-4 color-primary-50">All Tickets</h4>
					<button className="ax-button Link color-secondary-100 capitalize fs-6" onClick = {() => onEditClick(3)} disabled>Edit</button>
				</div>

				<div className="ticket-grid">
					{servicePriceOptions && servicePriceOptions.map((item, index) => 
						<>
							{item?.pricingType === "package" && 
								item?.packages?.map((pck, i) => 
								
									<div className="flex_container_60 bg-white border_50 padding_50 flex_container_50">
										<div>
											<div className="d-flex justify-content-between align-items-center">
												<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.pricingType}</h2>
												<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.tickets?.packageType}</h2>
											</div>
											<h2 className="subtitle-R color-primary-50 fs-6">{pck?.packageName}</h2>

											<h2 className="d-flex heading-2 color-primary-50 align-items-center justify-content-between">
												<div>{pck?.noOfSessions}</div>
												<div className="body-CAP px-2">Sessions</div>
											</h2>
											<div className="subtitle-B color-primary-50">{pck?.packagePrice} {pck?.priceCurrency}</div>
											<div className="subtitle-R fs-6 color-primary-50">Validity - {pck?.packageValidityDuration} months</div>
										</div>
										
									</div>
								)
							}

							{item?.pricingType === "plan" && 
								item?.plans?.map((pck, i) => 
									<>
										<div className="flex_container_60 bg-white border_50 padding_50 flex_container_50">
											<div>
												<div className="d-flex justify-content-between align-items-center">
													<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.pricingType}</h2>
													<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.tickets?.packageType}</h2>
												</div>
												<h2 className="subtitle-R color-primary-50 fs-6">{pck?.planName} | {pck?.planPaymentPeriod}</h2>

												<h2 className="d-flex heading-2 color-primary-50 align-items-center justify-content-between">
													<div>{pck?.planSessionPerMonth}</div>
													<div className="body-CAP px-2">Sessions</div>
												</h2>

												<div className="subtitle-B color-primary-50">{pck?.planPrice} {pck?.priceCurrency || "EUR"}</div>
												<div className="subtitle-R fs-6 color-primary-50">Validity - {pck?.planValidityDuration} months</div>
											</div>
										</div>
										{(i !== item?.tickets?.ticketList?.length - 1) && <hr />}
									</>
								)
							}

							{item?.pricingType === "ticket" &&
								<div className="flex_container_60 bg-white border_50 padding_50">
									<div className="d-flex justify-content-between align-items-center">
										<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.pricingType}</h2>
										<h2 className="subtitle-R color-primary-50 opacity fs-6 pb-2 capitalize">{item?.tickets?.packageType}</h2>
									</div>
									{item?.tickets?.ticketList?.map((pck, i) => 
										<>
											<div className="flex_container_50">
												<div>
													
													
													<h2 className="subtitle-R color-primary-50 fs-6">{pck?.packageName}</h2>

													<div className="subtitle-B color-primary-50">{pck?.packagePrice} {pck?.priceCurrency || "EUR"}</div>
												</div>
											</div>
											{(i !== item?.tickets?.ticketList?.length - 1) && <hr />}
										</>
									)}
								</div>
							}
						</>
					)}
				</div>
			</>
		)
	}

	const ScheduleView = () => {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center my-3">
					<h4 className="heading-4 color-primary-50">Schedule Details</h4>
					<button className="ax-button Link color-secondary-100 capitalize fs-6" onClick = {() => onEditClick(6)} disabled>Edit</button>
				</div>

				<div className="details-grid">
					<div className="flex_container_60 bg-white border_50 padding_70">
						<h5 className="heading-5 color-primary-50">Date & Time</h5>
						<div>
							<div className="w-100 d-flex align-items-center py-3">
								<div className="pe-5">
									<div className="body-CAP capitalize py-2 color-primary-50">Start Date</div>

									<div className="body-B color-primary-50">{getDateFromEpoc(serviceStartDate) || "-"}</div>
								</div>

								<div className="">
									<div className="body-CAP capitalize py-2 color-primary-50">End Date</div>
									<div className="body-B color-primary-50">{getDateFromEpoc(serviceEndDate) || "-"} </div>
								</div>
							</div>

							{serviceType !== "PRIVATE" && <div className="w-100 d-flex align-items-center">
								<div className="pe-5">
									<div className="body-CAP capitalize py-2 color-primary-50">Start Time</div>
									<div className="body-B color-primary-50">{startTime || "-"}</div>
								</div>

								<div className="">
									<div className="body-CAP capitalize py-2 color-primary-50">End Time</div>
									<div className="body-B color-primary-50">{endTime || "-"}</div>
								</div>
							</div>}

							{serviceType === "PRIVATE" && <div className="w-100">
								{serviceScheduleDetails?.serviceScheduleTypeConfig.map((eachDate, index) => {
									return (
										<>
											<div className="color-primary-50 body-B">Every {eachDate?.day}</div>
											{eachDate?.dayWiseTimeList?.map((daywiseTime, index) => 
												<div>
													<div className="">
														
														<div className="body-CAP capitalize py-2 color-primary-50">{daywiseTime?.startTime} - {daywiseTime?.endTime || "-"}</div>
													
													</div>
												</div>
											)}
										
										</>
									)}
								)}
							</div>
							}

							<div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50 capitalize">Time zone</div>
								<div className="body-B color-primary-50">{serviceTimezone || "-"}</div>
							</div>
						</div>
					</div>

					<div className="flex_container_60 bg-white border_50 padding_70">
						<h5 className="heading-5 color-primary-50">Location</h5>
						<div>
							<div className="py-3">
								<div className="body-B color-primary-50">{serviceScheduleDetails?.serviceMode?.toUpperCase() || ""}</div>
							</div>

							{serviceScheduleDetails.serviceMode === "online" && <div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Meeting Link</div>
								<div className="body-B color-primary-50">{serviceScheduleDetails?.serviceMeetingLink || ""}</div>
							</div>}

							{serviceScheduleDetails.serviceMode === "physical" && <div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Venue name</div>
								<div className="body-B color-primary-50">{serviceLocation?.venueName}</div>
							</div>}

							{serviceScheduleDetails.serviceMode == "physical" && <div className="py-3">
								<div className="body-CAP capitalize py-2 color-primary-50">Address</div>
								{serviceScheduleDetails?.serviceMode !== "online" && <div className="body-B color-primary-50">{serviceLocation?.address?.label}</div>}
								{serviceScheduleDetails?.serviceMode === "online" && <div className="body-B color-primary-50">-</div>}
							</div>}
						</div>
					</div>

				</div>
			</>
		)
	};

	const OrderView = () => {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center my-3">
					<h4 className="heading-4 color-primary-50">Order Details</h4>
					<button className="ax-button Link color-secondary-100 capitalize fs-6" disabled>Edit</button>
				</div>
				<div className="body-R color-primary-50">Upcoming Feature</div>
			</>
		)
	}

	const TabPanel = () => {
		return (
			<>
				{activeTab === 0 && <OverView />}
				{activeTab === 1 && <TicketView />}
				{activeTab === 2 && <ScheduleView />}
				{activeTab === 3 && <OrderView />}
			</>
		);
	}

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    useEffect(() => {
    	if(loggedUser && loggedUser.role) {
    		setUser(loggedUser);
    	}
    }, [loggedUser]);

    useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	getServiceById();
    }, []);

    const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "events-classes":
					obj.path = "/events-classes";
					obj.label = "Events and Classes";
					crumbArray.push(obj);
					break;
				case "event-details":
					obj.path = "/events-classes";
					obj.label = "Event details";
					crumbArray.push(obj);
					break;
				case "class-details":
					obj.path = "/events-classes";
					obj.label = "Class details";
					crumbArray.push(obj);
					break;
				case "news":
					obj.path = "/news";
					obj.label = "News";
					crumbArray.push(obj);
					break;
				case "catalysts":
					obj.path = "/catalysts";
					obj.label = "Catalysts";
					crumbArray.push(obj);
					break;
				
				case "about":
					obj.path = "/about";
					obj.label = "About minded";
					crumbArray.push(obj);
					break;
				case "contact":
					obj.path = "/contact";
					obj.label = "Contact";
					crumbArray.push(obj);
					break;
			}

			setCrumb(crumbArray);
		});
	}

	const getServiceById  = async () => {
    	const URL=`${baseURL}/minded-services/getServiceById/${id}`;

	    const myHeaders = getAuthenticateHeader();

 		setLoading(true);

       	return await fetch(URL, headerOption)
       	.then(response => {
		    const resp = response;
		   
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => {
		      
		      	const newService = res;
		      	
		      	/*
		      	if(newService && newService.serviceLocation) {
		      		newService.serviceLocation = JSON.parse(newService?.serviceLocation);
		      	}

		      	if(newService && newService?.serviceScheduleDetails?.guestDetails) {
		      		newService.serviceScheduleDetails.guestDetails = JSON.parse(newService?.serviceScheduleDetails?.guestDetails);
		      	}

		      	if(newService.servicePriceOptions) {
		      		newService.servicePriceOptions = JSON.parse(newService.servicePriceOptions);
		      	}
		      	*/

		       	setServiceDetails(newService);
		      });
		    } else {
		    	setError(true);
		    }
		})
		.catch((error) => {
			console.log('error getServiceById', error);
	      	setLoading(false);
	      	setError(true);
		});
	}

	const {
	  serviceType = "Event",
	  serviceName = "",
	  serviceDescription = "",
	  status = 'active',
	  serviceStartDate = "",
	  serviceEndDate = "",
	  endTime = "",
	  startTime = "",
	  author = "",
	  userId = "",
	  serviceTimezone = "",
	  serviceImg = [],
	  createdOn = "",
	  serviceLocation = {},
	  serviceCategoryDetails = {},
	  servicePriceOptions = [],
	  serviceScheduleDetails = {
	    serviceMode : "physical",
	    repeat: "weekly",
	    recurring: [],
	    serviceScheduleType: "single",
	    serviceStartDate : "",
	    serviceEndDate : "",
	    totalParticipant: "",
	    timezone: "",
	    serviceMeetingLink : "",
	    guestDetails : [
	      {
	        guestName : "",
	        guestPic : "",
	        guestBio : ""
	      }
	    ],
	    serviceLocation : {
	      address : "",
	      city : "",
	      country : "",
	      venueName: "",
	      coordinate : "",
	    },
	    scheduleInfo : []
	  },
	  trail = []
	} = serviceDetails || {};

	return (
		<>
			<div className="main-no-content-padding">
				<Breadcrumb> 
	                {crumb.map((item, index) => {
	                	return (index !== crumb.length - 1) ? (
		                	<>
			                	<Link to={item.path}>
			                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
			                	</Link>
			                	<img src={right_arrow} style={{width: '12px', height: '12px'}} />
		                	</>
		                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
	                	}
	                )}  
		        </Breadcrumb>
		        <h1 className="heading-1 color-primary-50">{serviceName || "-"}</h1>
	        </div>

			<div className="main-no-content-padding">
				<TabView children = {TabPanel} tabs={tabList} onTabClick = {(val) => setActiveTab(val)}  />
			</div>
			
			{isShowDialog && <AddServiceDialog 
				isOpen = {isShowDialog} 
				onHandleClose={() => setShowDialog(false)} 
				navigateStepper = {viewIndex}
				serviceinfo = {serviceDetails}
			/>}

			{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</>
	)
}

export default ServiceDashboard;