import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Loading from '../../../../../components/loading/Loading';
import TabView from '../../../../../components/tabView/TabView';
import ListView from '../../ListView';
import Noresult from '../../settings/Noresult';
import {generateEventsDropdownList, getTypeFromActiveTab} from '../../../../../utils/util';
import {Service_Tab_List} from '../../../../../utils/DefaultInitialValues';
import {getServices} from '../../../../../actions/services.actions';

const ServiceView = (props) => {

	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(0);
	const [allServices, setAllServices] = useState([]);
	const getServicesAction = getServices;
	const dispatch = useDispatch();

	const {
       details,
       loading,
       error
    } = useSelector(({ services : { details, loading, error} }) => ({ details, loading, error }));

    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllServices(details);
    	}
    }, [details]);

     useEffect(() => {
		const type = getTypeFromActiveTab(activeTab);
		dispatch(getServicesAction("", "", type, "", "", "", "active"));
	}, [activeTab]);

	const onViewAllServices = () => {
        navigate("/dashboard/services");
    }

	const ClassTabpanel = (serviceName = "") => {
    	return (
    		<>
	    		<div className="list_view w-100">
					{allServices.length === 0 && <div> <Noresult serviceName = "Classes" onCTAClick = {() => console.log("Classes")} /> </div>}
					{allServices.length > 0 && <ListView isShow = {true} list = {allServices} onHandleEventScheduleClick = {(val) => console.log(val)} onHandleServiceTitleClick = {() => console.log("hello")} />}
				</div>
				{allServices.length > 3 && <div className="d-flex justify-content-center">
					<button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllServices} >View All Services</button>
				</div>}
			</>
    	)
    }

    const PrivateTabpanel = (serviceName = "") => {
    	return (
    		<>
	    		<div className="list_view w-100">
					{allServices.length === 0 && <div> <Noresult serviceName = "1:1 Session" onCTAClick = {() => console.log("Classes")} /> </div>}
					{allServices.length > 0 && <ListView isShow = {true} list = {allServices} onHandleEventScheduleClick ={(val) => console.log(val)} onHandleServiceTitleClick = {() => console.log("hello")} />}
					
				</div>
				{allServices.length > 3 && <div className="d-flex justify-content-center">
					<button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllServices}>View All Private Sessions</button>
				</div>}
			</>
    	)
    }

    const EventTabpanel = (serviceName = "") => {
    	return (
    		<>
	    		<div className="list_view w-100">
					{allServices.length === 0 && <div> <Noresult serviceName = "Events" onCTAClick = {() => console.log("Classes")} /> </div>}
					{allServices.length > 0 && <ListView isShow = {true} list = {allServices} onHandleEventScheduleClick ={(val) => console.log(val)} onHandleServiceTitleClick = {() => console.log("hello")} />}
				</div>
				{allServices.length > 3 && <div className="d-flex justify-content-center">
					<button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllServices}>View All Events</button>
				</div>}
			</>
    	)
    }

    const TabPanel = () => {
		return (
			<>
				{activeTab === 0 && <ClassTabpanel />}
				{activeTab === 1 && <PrivateTabpanel />}
				{activeTab === 2 && <EventTabpanel />}
			</>
		);
	}

	return (
		<>
			<div className="w-100">
				<TabView children = {TabPanel} tabs={Service_Tab_List} onTabClick = {(val) => setActiveTab(val)}  />
			</div>
			{loading && <Loading />}
		</>
	)
}

export default ServiceView;