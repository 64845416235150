import {useState, forwardRef, useImperativeHandle} from 'react';
import EventPackageDialog from './EventPackageDialog';
import ActionMenu from '../../../../components/actionMenu/ActionMenu';
import {InitialTicketDetails, InitialServiceErrors} from '../../../../utils/DefaultInitialValues';

const AddEventPriceView = forwardRef((props, ref) => {
	const {
    serviceDetails = {},
    onUpdatePriceInfo
  } = props;

  const [priceDetails, setPriceDetails] = useState(serviceDetails?.servicePriceOptions);
  const [isShowPriceDialog, setShowPriceDialog] = useState(false);
  const [eachTicket, setTicketDetails] = useState({});
  const [serviceErrors, setServiceErrors] = useState(InitialServiceErrors);

  const {
    course_price_error = false
  } = serviceErrors || {};

  const onHandleMenuClick = (type = "", ticket = {}) => {

    switch(type) {
      case "edit":
        OnEditTicket(ticket);
        break;
      case "duplicate":
        onDuplicateTicket(ticket);
        break;
      case "delete":
        onDeleteTicket(ticket);
        break;
      default: break;
    }
  }

  const onDuplicateTicket = (ticket = {}) => {
    const originalObject = { ...ticket }; 
  
    const clonedObject = {};
    clonedObject.pricingType = "ticket";
    clonedObject.tickets = {};
    clonedObject.tickets.id = new Date().getTime();
    clonedObject.tickets.ticketName =  `${originalObject.tickets.ticketName} Copy`;
    clonedObject.tickets.ticketList = originalObject.tickets.ticketList;
    clonedObject.tickets.ticketDescription = originalObject.tickets.ticketList;
    clonedObject.tickets.ticketQuantityType = originalObject.tickets.ticketQuantityType;
    clonedObject.tickets.ticketMaxParticipant = originalObject.tickets.ticketMaxParticipant;
    clonedObject.tickets.couponInfo = originalObject.tickets.couponInfo;
    clonedObject.tickets.policyInfo = originalObject.tickets.policyInfo;
    clonedObject.tickets.packageType = originalObject.tickets.packageType;

    onUpdatePackagePriceInfo(clonedObject);

  }

  const onDeleteTicket = (ticket = {}) => {
    const newArray = priceDetails.filter((eachTicket, index) => eachTicket?.tickets?.id !== ticket?.tickets?.id);
    setPriceDetails(newArray);
  }

  const onUpdatePackagePriceInfo = (ticket = {}) => {
    const newArray = [...priceDetails];

    const filterInx = newArray.findIndex((item, inx) => item?.tickets?.id === ticket?.tickets?.id);
    if(filterInx !== -1) {
      newArray[filterInx] = ticket;
    } else {
      newArray.push(ticket);
    }
    
    setPriceDetails(newArray);
  }

  const onAddPackage = (tck = {}) => {
    const tckt = {};
    tck.id = new Date().getTime();
    tckt.pricingType = "ticket";
    tckt.tickets = tck;

    onUpdatePackagePriceInfo(tckt);
  }

  const onEditPackage = (tck = {}) => {
    onUpdatePackagePriceInfo(tck);
  }

  const OnEditTicket = (ticket = {}) => {
    setTicketDetails(ticket);
    setShowPriceDialog(true);
  }

  const onAddTicket = () => {
    
    setTicketDetails({...InitialTicketDetails, pricingType: "ticket"});
    setShowPriceDialog(true);
  }

  useImperativeHandle(ref, () => ({

    validateTicketDetails() {
      let isPriceInvalid = false;
      let isFormValid = true;

      if(priceDetails?.length === 0) {
        isPriceInvalid = true;
        isFormValid = false;
      }

      if(isPriceInvalid) {
        setServiceErrors({...serviceErrors,
          course_price_error: isPriceInvalid
        })
      }

      if(isFormValid) {
        onUpdatePriceInfo("servicePriceOptions", priceDetails);
      }
      
      return isFormValid;
    }
  })
  )

	return (
    <div className="container dialog_padding">
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="w-100 my-3">
            <div className="w-100 d-flex justify-content-between">
              <h5 className="heading-5 color-primary-50">4.1 Ticket types</h5>
              <button className="tertiary_btn color-primary-50 capitalize" onClick={() => onAddTicket(true)}>Create Tickets</button>
            </div>

            {priceDetails.length === 0 && <div className="w-100 card-view bg-white flex_container_50 bg-white p-4 rounded-3 my-4">
              <div className="color-primary-50">There are no tickets created yet</div>
            </div>}

            {priceDetails?.map((eachPackage, index) => {
              return (
                <div className="my-4 rounded-4 p-3 bg-white d-flex justify-content-between" key={eachPackage?.tickets?.id}>
                  <div>
                    <h5 className="heading-5 py-1 color-primary-50">{eachPackage?.tickets?.ticketName}</h5>
                    <div className="subtitle-B py-1 color-primary-50">{eachPackage?.tickets?.packageType}</div>
                    
                    <div className="d-flex justify-content-between">
                      {eachPackage.packageType !== "Variant" && <div>
                        <div className="subtitle-B py-1 color-primary-50">{eachPackage?.tickets?.ticketList[0]?.packagePrice} EUR</div>
                      </div>}

                      {eachPackage.packageType === "Variant" && <div>
                        {eachPackage?.ticketList.map((eachDiff, i) => {
                          return (
                            <>
                              <div className="subtitle-B py-1 color-primary-50">{eachDiff?.packageName} - {eachDiff?.packagePrice} EUR</div>
                            </>
                          )
                        })
                      }
                      </div>
                      }
                    </div>
                  </div>

                  <div className="my-4 rounded-4 p-3 bg-white d-flex flex-column justify-content-center">
                    <div>
                      <button className="ax-button color-secondary-100" >
                        <ActionMenu onHandleMenuClick = {(type) => onHandleMenuClick(type, eachPackage)} />
                      </button>
                    </div>
                  </div>
                  
                </div>
              )
            })}

            {course_price_error && <div className="text-danger pt-1">! Please create atleast 1 ticket</div>}
          </div>
    		</div>
    	</div>

      {isShowPriceDialog && <EventPackageDialog
        maxParticipant = {serviceDetails?.serviceScheduleDetails?.maxParticipant}
        eachTicket = {eachTicket} 
        isOpen = {isShowPriceDialog} 
        handleClose = {() => setShowPriceDialog(false)} 
        onAddPackage = {(obj) => onAddPackage(obj)}
      />
      }
    </div>
	)
});

export default AddEventPriceView;