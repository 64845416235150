import {useState, useEffect} from 'react';
import {getLocationLink, getTimeFromEpoc, isScheduleExpired} from '../../utils/util';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import location_icon from '../../assets/Location pin.png';

const WeekCard = (props) => {
	const {
		item = {}, 
		onHandleItemClick, 
		onHandleCTAClick, 
		activeTab = "-1",
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false
	} = props;

	const {
		scheduleMode = "",
		availableSeatCount = 10,
		scheduleEndTime = "",
		scheduleStartTime = "",
		serviceType = ""
	} = item || {};
	
	const {
        scheduleId = "",
        serviceName = "",
        serviceId = "",
        serviceDescription = "",
        day = "",
        serviceCategoryDetails = {},
        startTime = "",
        endTime = "",
        service_date = "",
        serviceMeetingLink = "",
        serviceLocation = {},
        duration = "",
        booking_details = [],
        userDetails = {},
        serviceTimezone = ""
      } = item?.serviceDetails || {};
	
	const [isExpanded, setExpanded] = useState(false);
	const [isExpired, setExpired] = useState(false);

	useEffect(() => {
    	const isExp = isScheduleExpired(scheduleEndTime)
      	setExpired(isExp);
	}, []);

	return (
		<div className={`p-3 w-100 my-1 class-container py-lg-4 ${((item?.isDisabled)) ? 'disabled': ''}`}>
			
			<div className="padding desktop_view">
				<div className="flex_container gap_8">
					<div className="d-flex justify-content-between align-items-center caption-3 w-100">
						{serviceType !== "PRIVATE" && <div className="caption-3 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
						{serviceType === "PRIVATE" && <div className="caption-3 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}

						<div className="color-primary-100">
							<div>
								<button className="ax-button caption-3" onClick={() => setExpanded(!isExpanded)} disabled = {isCTADisabled || item?.isDisabled === "true"}> {isExpanded ? "-" : "+"} info</button>
							</div>
						</div>
					</div>

					<button className="ax-button caption-3 color-primary-50" onClick={() => onHandleItemClick(item)} disabled = {isCTADisabled || item?.isDisabled === "true"}>{userDetails?.userName?.toUpperCase() || "-"}</button>
					
					{/*<div className="caption-3 color-primary-50">{serviceCategoryDetails?.serviceCategoryName || "-"}</div>*/}
					
					<button className="ax-button card_service_name" onClick={() => onHandleItemClick(item)} disabled = {isCTADisabled || item?.isDisabled === "true"}>{serviceName}</button>
					
					{scheduleMode === "physical" &&
						<a href={getLocationLink(serviceLocation)} target="_blank">
							<div className="caption-2 color-primary-50 d-flex">
								<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '0px'}} alt = "" />
								<h6 className="caption-3 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
							</div>
						</a>
					}
					{scheduleMode === "online" &&
						<div className="caption-2 color-primary-50 d-flex">
							<h6 className="caption-3 color-primary-50">Online</h6>
						</div>
					}
				</div>
			</div>

			<div className="padding mobile_view">
				<div className="d-flex justify-content-between subtitle-3 mb-2">
			
					{serviceType !== "PRIVATE" && <div className="caption-3 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
					{serviceType === "PRIVATE" && <div className="caption-3 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}

					<div className="subtitle-3 color-primary-50">
						<div>
							<button disabled={item?.isDisabled} className="ax-button" onClick={() => setExpanded(!isExpanded)}> {isExpanded ? "-" : "+"} info</button>
						</div>
					</div>
				</div>
				<button className="ax-button caption-3 color-primary-50" onClick={() => onHandleItemClick(item)} disabled = {isCTADisabled || item?.isDisabled === "true"}>{userDetails?.userName?.toUpperCase() || "-"}</button>
					
				<div>
					<h4 className="heading-4 color-primary-50">{serviceName}</h4>
				</div>

				<div className="py-2 d-flex justify-content-between align-items-center">
					
					<div>
						{scheduleMode === "physical" && <a href={getLocationLink(serviceLocation)} target="_blank">
							<div className="subtitle-5 color-primary-50 d-flex justify-content-between align-items-center">
								<img src={location_icon} style={{width: '24px', height: '24px'}} alt = "" />
								<div className="px-2 subtitle-5 color-primary-50">{serviceLocation?.city || scheduleMode}</div>
							</div>
						</a>}
						{scheduleMode === "online" &&
							<div className="py-2 color-primary-50 d-flex">
								<h6 className="subtitle-5 color-primary-50">Online</h6>
							</div>
						}
					</div>
				</div>
			</div>

			{isExpanded && <div className="color-primary-50 subtitle-3">{item?.serviceDetails?.serviceDescription}</div>}
			
			{isCTAShow && <div className="d-flex justify-content-center pt-3">
				<button className="capitalize book_btn" onClick={() => onHandleCTAClick(item)} disabled = {isExpired || isCTADisabled || item?.isDisabled === "true"}>{CTALabel}</button>
			</div>}
		</div>
	)	
}

export default WeekCard;