import React, {useRef} from 'react';
import ReactDOM from 'react-dom';
import { CheckPicker } from 'rsuite';
import './checkview.css';

const CheckPickerView = (props) => {
  const {name="", label = "", list = [], onCheckboxSelect} = props;
  const checkboxRef = useRef(null);

  const data = list.map(item => ({ label: item?.name, value: item?.id }));

  const onHandle = () => {
    if(checkboxRef && checkboxRef.current && checkboxRef.current.target) {
      checkboxRef.current.target.click();
    }
  }

  const onItemSelect = (val, item, event) => {
    onCheckboxSelect(name, val);
  }

  return (
    <div className="checkbox_container">
      <CheckPicker 
        style={{ width: 180, margin: 10 }} 
        ref={checkboxRef}
        data={data} 
        appearance="subtle" 
        countable={true} 
        cleanable={false} 
        searchable={false}
        placeholder=""
        onSelect = {onItemSelect}
      />
      <span className="value LINK" onClick={onHandle}>{label}</span>
    </div>
  )
};

export default CheckPickerView;

