import React, {useState, useEffect} from 'react';
import MyTimer from '../timer/Mytimer';

const SubscribePaymentDetailsView = (props) => {

	const {userDetails = {}} = props;
	const [timer, setTimer] = useState(new Date());
  const [isTimeExpire, setTimeExpire] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 119);

  const onHandleTimeExpire = () => {
    setTimeExpire(true);
  }

  const {
    catalystDetails = {}
  } = userDetails || {};

  const {
    planDetails = {}
  } = catalystDetails || {};

  return (
    <>
  		<div className="row px-4 rounded-4 coupon w-100">
        <div className="col-lg-12">
          <div className="py-5">
            <h4 className="color-primary-50 heading-4">Overview</h4>
            <div className="subtitle-R color-primary-50">{planDetails?.subscriptionName}</div>
            <div className="body-R color-primary-50">{planDetails?.subscriptionDesc}</div>
            {planDetails?.subscriptionTrialDuration !== 0 && <div className="body-R color-primary-50 my-2">Free Trial For - {planDetails?.subscriptionTrialDuration} Days</div>}
            
            <div className="d-flex justify-content-between py-3">
              <div className="body-R color-primary-50">{planDetails?.subscriptionName}  x  1</div>
              <div className="body-B color-primary-50">{planDetails?.subscriptionPrice} EUR / {planDetails?.subscriptionPaymentDuration}</div>
            </div>

            <hr />

            <div className="d-flex justify-content-between py-3">
              <div className="body-R color-primary-50">Total</div>
              <div className="body-B color-primary-50">{planDetails?.subscriptionPrice} EUR</div>
            </div>
            <div className="fs-6 color-primary-50">(Includes VAT at the legally mandated rate)</div>
          </div>
        </div>
      </div>
      
      {/*!isTimeExpire && 
        <div className="row px-4 rounded-3 coupon color-peach_bg w-100 p-3">
          <div className="col-lg-12">
            <div className="body-R color-primary-50 text-center">
              Time remaining <MyTimer expiryTimestamp={time} onExpire={onHandleTimeExpire} />
            </div>
          </div>
        </div>
      */}
    </>
	)
}

export default SubscribePaymentDetailsView;