import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import {Catalyst_Tab_List} from '../../../../../utils/DefaultInitialValues';
import CatalystListView from '../../settings/CatalystListView';
import Loading from '../../../../../components/loading/Loading';
import Noresult from '../../settings/Noresult';

import TabView from '../../../../../components/tabView/TabView';
import {getCatalystAll} from '../../../../../actions/catalysts.actions';

const CatalystView = (props) => {
    const navigate = useNavigate();
	const dispatch = useDispatch();

	const getCatalystAllAction = getCatalystAll;

	const [activeTab, setActiveTab] = useState(0);
	const [allCatalysts, setAllCatalysts] = useState([]);
	const [filterCatalysts, setFilterCatalysts] = useState([]);

	useEffect(() => {
    	dispatch(getCatalystAllAction());
    }, []);

    const {
       details,
       loading,
       error
    } = useSelector(({ catalysts: { details, loading, error} }) => ({ details, loading, error }));

    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllCatalysts(details);
    		applyFilter();
    	}
    }, [details]);

    useEffect(() => {
    	applyFilter();
    }, [activeTab]);


    const onViewAllCatalysts = () => {
        navigate("/dashboard/catalysts");
    }

    const applyFilter = () => {
    	let text = "active";

    	switch(activeTab) {
    		case 0:
    			text = "active";
    			break;
    		case 1:
    			text = "pending";
    			break;
    		case 2:
    			text = "rejected";
    			break;
            default: break;
    	}
    	const filterData = allCatalysts.filter((item, index) => item.status === text);
    	setFilterCatalysts(filterData);
    }

    const TabPanel = () => {
		return (
            <>
    			<div className="list_view w-100">
    				{filterCatalysts.length === 0 && <div> <Noresult serviceName = "Classes" onCTAClick = {() => console.log("Classes")} /> </div>}
    				{filterCatalysts.length > 0 && <CatalystListView data = {filterCatalysts} />}
    			</div>
                {filterCatalysts.length > 0 && <div className="d-flex justify-content-center">
                    <button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllCatalysts}>View All Catalysts</button>
                </div>}
            </>
		);
	}

	return (
        <>
    		<div className="w-100">
    			<TabView children = {TabPanel} tabs={Catalyst_Tab_List} onTabClick = {(val) => setActiveTab(val)}  />
    		</div>
            {loading && <Loading />}
            {(error) && <CustomizedSnackbars isShow={error} text= "Something went wrong. Please try after sometime." />}
        
        </>
	)
}

export default CatalystView;