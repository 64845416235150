import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FloatSelectView from '../../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../../components/input/Input';
import DateRangePickerView from '../../../../../components/DateRangePickerView';
import Loading from '../../../../../components/loading/Loading';
import {baseURL} from '../../../../../actions/Constants';

import {InitialCouponDetails, InitialCouponErrors} from '../../../../../utils/DefaultInitialValues';
import brown_cancel from '../../../../../assets/brown_cancel.png';
import {endAfterStart} from '../../../../../utils/util';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdateCoupon = (props) => {
	const {
	    details = InitialCouponDetails,
	    isOpen = false, 
	    handleClose,
	    onUpdateSuccess
  	} = props;

  	const [couponDetails, setCouponDetails] = useState(details);
  	const [couponError, setCouponError] = useState(InitialCouponErrors);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const isEdit = details.coupon_id !== "";

  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	const onUpdateCouponDetails = (e) => {
  		setCouponDetails({...couponDetails, [e.target.name] : e.target.value});
  	}

  	const onHandleDateUpdate = (name, dateVal) => {
  		setCouponDetails({...couponDetails, [name] : dateVal});
  	}

  	const validate = () => {
  		let isFormValid = true;

  		let couponNameInvalid = false;
  		let couponCodeInvalid = false;
  		let couponDiscountInvalid = false;
  		let couponStartDateInvalid = false;
  		let couponDateRangeInvalid = false;
  		let couponEndDateInvalid = false;
  		let couponLimitCountInvalid = false;

  		if(couponName === "") {
  			couponNameInvalid = true;
  			isFormValid = false;
  		}

  		if(couponCode === "") {
  			couponCodeInvalid = true;
  			isFormValid = false;
  		}

  		if(couponDiscount === "") {
  			couponDiscountInvalid = true;
  			isFormValid = false;
  		}

  		if(couponStartDate === "") {
  			couponStartDateInvalid = true;
  			isFormValid = false;
  		}

  		if(couponEndDate === "") {
  			couponEndDateInvalid = true;
  			isFormValid = false;
  		}

  		if(couponStartDate !=="" && couponEndDate !== "") {
  			const isRangeValid = endAfterStart(couponStartDate, couponEndDate);
  			if(!isRangeValid) {
	          couponDateRangeInvalid = true;
	          isFormValid = false;
        	}
  		}

  		if(couponMaxUsageCount === "") {
  			couponLimitCountInvalid = true;
  			isFormValid = false;
  		}

  		setCouponError({...couponError,
  			coupon_name_error : couponNameInvalid,
	  		coupon_code_error : couponCodeInvalid,
	  		coupon_discount_error : couponDiscountInvalid,
	  		coupon_start_date_error : couponStartDateInvalid,
	  		coupon_end_date_error : couponEndDateInvalid,
	  		coupon_date_range_error: couponDateRangeInvalid,
	  		coupon_usage_count_error : couponLimitCountInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			UpdateCouponDetails();
  		}
  	}

  	const UpdateCouponDetails = async () => {
        const URL= isEdit ? `${baseURL}/updateCoupon` : `${baseURL}/addCoupon`;
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(couponDetails), // body data type must match "Content-Type" header
		    headers: {
		    	'Content-Type': 'application/json'
		    }
        })

        .then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => { 
		       	setError(false);
	            onUpdateSuccess();
	            handleClose();
		      });
		      
		    } else {
		      //Error
		       setError(true); 
		    }
		  })
		.catch((error) => {
			//console.log('error getSchedules', error);
			console.log('error UpdateCouponDetails/addCouponDetails', error);
            setLoading(false);
            setError(true); 
		});
    }

  	const {
  		id = "",
		couponType = "",
	  	couponName = "",
	  	couponCode = "",
	  	couponDiscount = "",
	  	discountCurrency = "EUR",
	  	couponStartDate = "",
	  	couponEndDate = "",
	  	couponMaxUsageCount = "",
	  	createdBy = ""
  	} = couponDetails || {};

  	const {
  		coupon_name_error = false,
  		coupon_code_error = false,
  		coupon_discount_error = false,
  		coupon_start_date_error = false,
  		coupon_end_date_error = false,
  		coupon_date_range_error = false,
  		coupon_usage_count_error = false
	} = couponError || {};

	const title = isEdit ? "Update Coupon": "Create New Coupon";

	return (
		<>
		    <BootstrapDialog
		        onClose={handleClose}
		        aria-labelledby="customized-dialog-title"
		        open={open}
		    >
		    <DialogActions className="w-100 p-4 bg-white">
		        <div className="w-100 d-flex justify-content-between align-items-center">
		          	<div>
		   
		          	</div>

		          	<h6 className="heading-6 color-primary-50">
		            	{title}
		          	</h6>

		          	<div>
			            <button     
			              onClick={handleClose}
			              aria-label="close"
			              className="ax-button close_icon"
			            >
			              <img src={brown_cancel} className="close_icon" />
			            </button>
		          	</div>
			    </div>
	    	</DialogActions>

		        <DialogContent className="bg-white">
		      		<div className="container-fluid">
		      			<div className="row">
		      				<div className="col-lg-12">
			      				<div className="d-flex align-items-center mb-3">
				      				{["EUR", "percentage"].map((category, index) => {
				      					return (
				      						<div className="pe-3">
				      							<input 
				      								id={category} 
				      								value={category} 
				      								type="radio" 
				      								name="couponType"
				      								checked = {couponType === category}
				      								onChange = {e => onUpdateCouponDetails(e)}
				      							/>
				      							<label for={category} className="px-2 body-R color-primary-50">In {category}</label>
				      						</div>
				      					)
				      				})}
			      				</div>
			      			</div>
		      			
				        	<div className="col-lg-12 my-3">
					            <div className="w-100">
					              <Input
					                name="couponCode"
					                label="Coupon code"
					                placeHolder="xxx" 
					                value={couponCode}
					                onInputChange = {e => onUpdateCouponDetails(e)}
					              />
					            </div>
					            {coupon_code_error && <div className="text-danger">! Coupon code is a required field</div>}
						        <div className="caption-R color-primary-50">e.g. Summersale20, This is the code customers will need to use this coupon.</div>
					    		</div>
					    
				        	<div className="col-lg-12 my-3">
					          	<div className="w-100">
					              <Input
					                name="couponName"
					                label="Coupon name"
					                placeHolder="xxx" 
					                value={couponName}
					                onInputChange = {e => onUpdateCouponDetails(e)}
					                
					              />
						        </div>
						        {coupon_name_error && <div className="text-danger">! Coupon name is a required field</div>}
						    </div>
					   
				        	<div className="col-lg-12 my-3">
					            <div className="w-100">
					              <Input
					                name="couponDiscount"
					                label={`Discount (${couponType === "EUR" ? "EUR": "%"})`}
					                placeHolder="xxx" 
					                value={couponDiscount}
					                type="number"
					                onInputChange = {e => onUpdateCouponDetails(e)}
					              />
					              
					            </div>
						        
						        {coupon_discount_error && <div className="text-danger">! Coupon discount is a required field</div>}
						        <div className="color-primary-50 caption-R">The amount in EUR that will be taken off the price.</div>
						    </div>
					   		
				        	<div className="col-lg-6 my-3">
					          	<DateRangePickerView 
					          		name="couponStartDate"
					          		label="Start date"
					          		value={couponStartDate}
					          		onHandleDateUpdate = { (name, date) => onHandleDateUpdate(name, date)} 
					          	/>
					          	{coupon_start_date_error && <div className="text-danger">! Start date is a required field</div>}
						    </div>

						    <div className="col-lg-6 my-3">
					          	<DateRangePickerView
					          		name="couponEndDate"
					          		label="End date"
					          		value={couponEndDate}
					          		onHandleDateUpdate = { (name, date) => onHandleDateUpdate(name, date)} 
					          	/>
					          	{coupon_end_date_error && <div className="text-danger">! End date is a required field</div>}
					          	{coupon_date_range_error && <div className="text-danger">! Date range is invalid</div>}
						    </div>
					    </div>

					    <div className="col-lg-12 my-3">
					    	<h6 className="heading-6 color-primary-50">Limit usage</h6>
				        	<div className="w-100">
								<Input
									name="couponMaxUsageCount"
									label="Number of usage"
									placeHolder="xxx" 
									value={couponMaxUsageCount}
									type="number"
									onInputChange={e => onUpdateCouponDetails(e)}
								/>  
						    </div>
						    {coupon_usage_count_error && <div className="text-danger">! Coupon count is a required field</div>}
					    </div>
					</div>

		        </DialogContent>

		        <DialogActions className="bg-white">
		        	<div className="py-3">
			        	<button autoFocus onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2">
			            	Cancel
			          	</button>

			          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize">
			           		{isEdit ? "Update" : "Add"}
			          	</button>
		            </div>
		        </DialogActions>
	      	</BootstrapDialog>
	      	{isLoading && <Loading />}
	      	{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
    	</>
	)
}

export default UpdateCoupon;