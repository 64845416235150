import {getDateFromEpoc} from '../../../utils/util';
import {getTimeZoneKey} from '../../../utils/DefaultStates';

const ScheduleConfirmationView = ({currentSchedules = []}) => {
	
  const {
		serviceName = "",
		serviceCategory = "",
		serviceLocation = {},
		mode = "",
    serviceCategoryDetails = {},
    startTime = "",
    endTime = "",
    serviceTimezone = ""

	} = currentSchedules  && currentSchedules[0] && currentSchedules[0]?.schedule?.serviceDetails || {};

  const {
    scheduleMode = ""
  } = currentSchedules  && currentSchedules[0] && currentSchedules[0]?.schedule;


  const EachRow = (props) => {

    const {eachBook = {}} = props;

    const {schedule, serviceDetails} = eachBook || {};

    const {scheduleDate = "", scheduleStartTime = "", scheduleEndTime} = schedule || {};

    return (
      <>
        <div className="d-flex justify-content-between align-items-center py-2 w-100">
          <div>
            {/*<div className="body-B color-primary-50">{serviceName} x 1</div>*/}
            <div className="body-B color-primary-50">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>
          </div>
          <div>
            <div className="body-B color-primary-50">{getDateFromEpoc(scheduleDate)}</div>
          </div>
        </div>
      </>
    )
  }

	return (
		<div className="container dialog_padding">
  		<div className="row w-100">
  			<div className="col-lg-8 m-auto w-100">
  				<div className="confirmation_container align-items-center w-50 m-auto">
  					<div className="card_item p-5 m-auto w-100 ">
  						<div className="card_item_content m-auto">
  						    <h5 className="heading-5 color-primary-50">
                    {serviceName} | {serviceCategoryDetails?.serviceCategoryName} {scheduleMode === "physical" && <span>| {serviceLocation?.city}</span>}
                  </h5>

                  {mode !== "physical" && <h5 className="heading-5 color-primary-50">{mode}</h5>}
  							<hr />
  						</div>
              
  						{currentSchedules.map((eachBook, index) => 
	          		<>
			          	<EachRow eachBook={eachBook} />
			          	{index !== (currentSchedules.length -1) && <hr />}
		          	</>
              )}       
  					</div>

  					<div>
  						<h5 className="heading-5 color-primary-50 text-center">Yay! You've successfully made a Schedule!</h5>
  						<div className="body-R color-primary-50 text-center">The ticket has been sent to your email.</div>
  					</div>
  				</div>
  			</div>
  		</div>
    </div>
	)
}

export default ScheduleConfirmationView;