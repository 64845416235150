import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CheckPickerView from '../../components/checkPicker/CheckPickerView';
import SwitchView from '../../components/switch/SwitchView';
import ButtonGroup from '../../components/button-group/ButtonGroup';
import { Checkbox } from 'rsuite';
import {initialFilters} from '../../utils/DefaultInitialValues';
import {saveFilters} from '../../actions/filters.actions';

import {getMonthText, getMonthDayText, getWeekDays, isEmptyJson} from '../../utils/util';

import './filters.css';

const FilterView = (props) => {
	const dispatch = useDispatch();

	const {
		isHideSwitch = false,
		selectedFilter = "services",
		isShow = true,
		onHandleDateChange,
		allDropdownList = {},
		onCheckboxSelect
	} = props;

	const {locationList = [], catalystList = [], serviceList = []} = allDropdownList || {};

	const saveFiltersAction = saveFilters;
	const [filters, setFilters] = useState(initialFilters);
	const [isFilterBtnActivated, setFilterButtonActivated] = useState(false);
	const [selectedFilterValue, setSelectedFilterValue] = useState(selectedFilter);
	const [month_val, setMonthVal] = useState("");

	const {
		isDayView = "false",
	    isMobileView = false,
	    startDate = new Date(),
	    days = [],
	    selectedCatalyst = [],
	    selectedCategory  = [],
	    selectedLocation = []
	} = filters;

	const {
       details: filterDetails
    } = useSelector(({ filter: { details} }) => ({ details }));

    const onMobileCheckboxSelect = (fieldName, name, val) => {

    	if(fieldName === "selectedCatalyst") {
    	
    		let newList = [...selectedCatalyst];

    		if(val) {
    			if(!selectedCatalyst.includes(name)) {
    				newList.push(name);
    			}
    		} else {
    			newList = newList.filter(item => item !== name);
    		}

    		setFilters({...filters, "selectedCatalyst": newList});
    		onCheckboxSelect("selectedCatalyst", newList);
    	}

    	else if(fieldName === "selectedCategory") {
    		let newList = [...selectedCategory];
    		if(val) {
    			if(!selectedCategory.includes(name)) {
    				newList.push(name);
    			}
    		} else {
    			newList = newList.filter(item => item !== name);
    		}

    		setFilters({...filters, "selectedCategory": newList});
    		onCheckboxSelect("selectedCategory", newList);
    	}
    	else if(fieldName === "selectedLocation") {
    		let newList = [...selectedLocation];
    		if(val) {
    			if(!selectedLocation.includes(name)) {
    				newList.push(name);
    			}
    		} else {
    			newList = newList.filter(item => item !== name);
    		}

    		setFilters({...filters, "selectedLocation": newList});
    		onCheckboxSelect("selectedLocation", newList);
    	}
    }

    const  onFilterCategoryChange= (val = "catalysts") => {
    	setSelectedFilterValue(val)
    }

	const resizeView = () => {
		if(window.screen.width >= 320 && window.screen.width <= 640) {
           	setFilters({...filters, ["isMobileView"] : true});
        } else {
           	setFilters({...filters, ["isMobileView"] : false});
        }
	}

	useEffect(() => {
		const dayList = getWeekDays(startDate, isMobileView, isDayView);

		setFilters({...filters, ["days"] : dayList});
	}, [isMobileView, startDate, isDayView]);

	useEffect(() => {
    	window.addEventListener("resize", function() {
           resizeView();
        });

        resizeView();
        const dayList = getWeekDays(startDate, isMobileView, isDayView);
    	//setFilters({...filters, ["days"] : dayList}); //Amit -patch
    	onHandleDateChange(days[0], days[days.length - 1]);

    	if(days?.length === 0 && (isEmptyJson(filterDetails) || filterDetails?.days?.length === 0)) {
    		setFilters({...filters, days: dayList});
    	}
    }, []);

	const handleBackBtnClick = () => {
    	let intervalDay = 7;

    	if(isDayView === "true") {
    		intervalDay = 1;
    	}

    	const newDate = new Date(startDate).setDate(startDate?.getDate() - intervalDay);
    	setFilters({...filters, ["startDate"] : new Date(newDate)});
    }

    const handleNextBtnClick = () => {
    	let intervalDay = 7;

    	if(isDayView === "true") {
    		intervalDay = 1;
    	}

     	const newDate = new Date(startDate).setDate(startDate?.getDate() + intervalDay);
     	setFilters({...filters, ["startDate"] : new Date(newDate)});
    }

    const onFilterChange = (name, value) => {
    	setFilters({...filters, [name] : value});
    }

    useEffect(() => {
    	if(days && days.length !== 0) {
    		onHandleDateChange(days[0], days[days.length - 1]);
    		getText();
    	}
    }, [days]);

    useEffect(() => {
    	if(!isEmptyJson(filters) && filters?.days?.length> 0) {
    		dispatch(saveFiltersAction(filters));
    		getText();
    	}
    }, [filters]);

    useEffect(() => {
		if(!isEmptyJson(filterDetails) && JSON.stringify(filters) !== JSON.stringify(filterDetails)) {
			const obj = filterDetails;
			obj.startDate = typeof(filterDetails.startDate) === 'string' ? new Date(filterDetails.startDate) : filterDetails.startDate;
			setFilters({...filters, ...obj});
		}
	}, [filterDetails]);

	const getText = () => {
		const val = isDayView === "false" ? getMonthText(days, isMobileView, isDayView) : getMonthDayText(startDate);
		if(month_val !== val) {
			setMonthVal(val);
		}
	}

    return (
		<div className="filterView">
			{!isHideSwitch && <div className="my-3">
				<SwitchView name="isDayView" onViewChange = {(name, val) => onFilterChange(name, val)} fieldValue = {isDayView} />
			</div>}

			{!isHideSwitch && <div className="my-3 py-3 px-3 rounded d-flex justify-content-between align-items-center">
				<button onClick={handleBackBtnClick} className="ax-button color-primary-50 ">
					<i className='bx bx-chevron-left pt-2 fs-5'></i>
				</button>
				
				<span className="px-3 color-primary-50 caption-B">{month_val}</span>
				
				<button onClick={handleNextBtnClick} className="ax-button pt-2 color-primary-50">
					<i className='bx bx-chevron-right fs-5'></i>
				</button>
			</div>}
			
			<div className="desktop_view">
				<div className="d-flex justify-content-between my-3  ">
			 		{isShow && <CheckPickerView name="selectedCatalyst" label = "Catalysts" list={catalystList} onCheckboxSelect   = {(name, val) => onCheckboxSelect(name, val)} />}
					<CheckPickerView name="selectedCategory" label = "Category"  list = {serviceList} onCheckboxSelect  = {(name, val) => onCheckboxSelect(name, val)} />
					<CheckPickerView name="selectedLocation" label = "Locations" list = {locationList} onCheckboxSelect = {(name, val) => onCheckboxSelect(name, val)} />
				</div>
			</div>

			<div className="my-3 mobile_view">
				{!isFilterBtnActivated && <div className="btn-group filter_group">
					<button className="btn ax-button" onClick={() => setFilterButtonActivated(!isFilterBtnActivated)} >
						All Filters
					</button>
				</div>}

				{isFilterBtnActivated && <ButtonGroup onFilterChange = {(val) => onFilterCategoryChange(val)} selectedFilter = {selectedFilterValue} isShow = {isShow} />}

				{isFilterBtnActivated && <div className="filter_wrapper">
					<div className="filter_container">
						{isShow && selectedFilterValue === "catalysts" && selectedCatalyst.length === 0 && <div className="color-primary-50 text-center">No2 result found</div>}
						{selectedFilterValue === "services" && serviceList.length === 0 && <div className="color-primary-50 text-center">No1 result found</div>}
						{selectedFilterValue === "locations" && locationList.length === 0 && <div className="color-primary-50 text-center">No3 result found</div>}

						{isShow && selectedFilterValue === "catalysts" && catalystList.map((item, index) => {
							const isCatlystChecked = selectedCatalyst.includes(item?.id);
							return (
								<div>
									<Checkbox className="color-primary-50" checked = {isCatlystChecked} name="selectedCatalyst" value={item?.id} onChange = {(name, val) => onMobileCheckboxSelect("selectedCatalyst", name, val)} >{item?.name}</Checkbox>
								</div>
							)
						})
						}

						{selectedFilterValue === "services" && serviceList.map((item, index) => {
							const isCategoryChecked = selectedCategory.includes(item?.id);
							return (
								<div>
									<Checkbox className="color-primary-50" checked = {isCategoryChecked} name="selectedCategory" value={item?.id} onChange = {(name, val) => onMobileCheckboxSelect("selectedCategory", name, val)} >{item?.name}</Checkbox>
								</div>
							)
						})
						}

						{selectedFilterValue === "locations" && locationList.map((item, index) => {
							const isLocationChecked = selectedLocation.includes(item?.id);
							return (
								<div>
									<Checkbox className="color-primary-50" checked = {isLocationChecked} name="selectedLocation" value={item?.id} onChange = {(name, val) => onMobileCheckboxSelect("selectedLocation", name, val)} >{item?.name}</Checkbox>
								</div>
							)
						})
						}
    				</div>
					<button className="w-100 body-R dismiss_btn" onClick={() => setFilterButtonActivated(false)}>
						Dismiss
					</button>
				</div>
				}
			</div>
		</div>
	)
}

export default FilterView;