import {useState} from 'react';
import {groupById} from '../../../../utils/util';
import {getTimeZoneKey} from '../../../../utils/DefaultStates';

const PackageBookingListView = (props) => {

	const {indexval = 0, onHandleScheduleNow,  list = [], isShow = false} = props;
	const [isExpanded, setExpanded] = useState(false);

	const groupList = groupById(list);

	return (
		<>
			{groupList.length !== 0 && groupList.map((eachBooking, indexVal) => {

			const {
				scheduleMaxParticipants = "",
				scheduleStatus = "",
				serviceDetails = {}
			} = eachBooking[0]?.schedule || {};

			const {serviceName = "", userDetails = {}, startTime = "", endTime = ""} = serviceDetails || {};
			
			const totalOpenCount = eachBooking.filter(item => item.bookingStatus === "pending_confirmation");

			return (
				<>
					<div className="desktop_view1">
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<div className="caption-R color-primary-50 py-1">{userDetails?.userName}</div>
								
								<div className="subtitle-B color-primary-50 text-left" >
									{serviceName}
								</div>

								{<div className="caption-R color-primary-50 py-1">Remaining {totalOpenCount.length}/{eachBooking.length} sessions</div>}
							</div>

							<button disabled = {totalOpenCount === 0} className="capitalize tertiary_btn color-primary-50" onClick={() => onHandleScheduleNow(eachBooking[0])}>
								Schedule
							</button>
						</div>
					</div>

					{/*
					<div className="rounded-5 mobile_view m-lg-3">
						<div className="padding_Amit">
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<div className="caption-R color-primary-50 py-1">{userDetails?.userName}</div>
								
									<div className="subtitle-3 color-primary-50">
										<div className="subtitle-B color-primary-50 text-left">
											{serviceName}
										</div>
									</div>
									{<div className="caption-R color-primary-50 py-1">{getDateFromEpoc(scheduleDate)}  {startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
								</div>
								
								<button className="capitalize tertiary_btn color-primary-50" onClick={() => onHandleScheduleNow(eachBooking)}>
									Reschedule
								</button>
							</div>
						</div>
					</div>
					*/}

					{(indexVal !== (groupList.length - 1)) && 
						<div className="">
							<hr />
						</div>
					}
				</>
			)
		})}
		</>
	)
}

export default PackageBookingListView;