import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from "rsuite";
import Table from '../../../../../components/table/Table';
import right_arrow from '../../../../../assets/thin arrow - right.png';

const AllServices = () => {
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const columns = [
	    {
	      name: "uid",
	      label: 'ID',
	      options: {
	       filter: true,
	       sort: true,
	      }
	    },
	    {
	      name: "name",
	      label: 'Name',
	      options: {
	       filter: true,
	       sort: true,
	      }
	    },
	    {
	      name: "email",
	      label: 'Email',
	      options: {
	       filter: true,
	       sort: true,
	      }
	    },
	    {
	      name: "status",
	      label: 'Status',
	      options: {
	       filter: true,
	       sort: true
	      }
	    },
	    {
	      name: "status",
	      label: 'Expertise',
	      options: {
	       filter: true,
	       sort: true
	      }
	    },
	    {
	      name: "status",
	      label: 'Offerings',
	      options: {
	       filter: true,
	       sort: true
	      }
	    },
	    {
	      name: "status",
	      label: 'Earning in Apr.',
	      options: {
	       filter: true,
	       sort: true
	      }
	    },
	    {
	      name: "status",
	      label: 'Tickets Sold',
	      options: {
	       filter: true,
	       sort: true
	      }
	    }	    
	];


	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "services":
					obj.path = "/dashboard/services";
					obj.label = "All Services";
					crumbArray.push(obj);
					break;
				default: break;
			}

			setCrumb(crumbArray);
		});
	}

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt = "" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Services</h1>
			
			<div className="my-5">
			<Table headers={columns} rowData={[]} />
			</div>
		</div>
	)
}

export default AllServices;