import {addZeroBefore} from '../../utils/util';
import Input from '../../components/input/Input';

const AddLocationView = (props) => {
	const {
    label="",
    list = [],
    removeItem, 
    title = "", 
    firstLabel = "city",
    firstFieldName = "",
    handleLocationChange,
    location = "",
    isError = false
  } = props;

	return (
		<div className="m-auto align-items-center">
      {list.map((item,index) => 
        <>
          <div className="my-2">
            <Input 
              label={firstLabel} 
              name="city"
              type="text"
              value={item.city} 
              onInputChange={(e) => handleLocationChange(e, index)} 
            />
          </div>
        </>
      )}

      {isError && <div className="text-danger">! {title} details can not be empty</div>}
    </div>
	)
}

export default AddLocationView;