import React from "react";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";
 
const DialogView = (props) => {

  const {onHandleClose, show = false, about = "" , isContatctUs = false} = props;
  
  const handleClose = () => {
    onHandleClose();
  }

  return (
   
    <Modal {...props} size="lg"  centered>
      <div className="modal-header">
        <h5 className="modal-title text-center w-100 color-primary-50 capitalize" id="exampleModalLabel">{isContatctUs ? "Confirmation" : "About"}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </div>

      <Modal.Body>
        <>
        {!isContatctUs && <div className="text-justify p-4 color-primary-50">{about}</div>}
        {isContatctUs && <div className="text-center">
          <h5 className="heading-5 color-primary-50 my-3">Thanks for contacting us</h5>
          <div className="color-primary-50">We will get back to your query soon</div>

        </div>}
        </>
      </Modal.Body>
      
      <Modal.Footer className="row py-3">
        <div className="d-flex justify-content-center">  
          <button 
            onClick={handleClose} 
            className="secondary_btn w-25 mx-3"
          >
          
          <lable style={{verticalAlign:"middle"}}>
            Close
          </lable>
        </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default DialogView;