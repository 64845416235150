import {useState, useEffect} from 'react';
import {TimePicker} from 'antd';
import moment from 'moment';

import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(CustomParseFormat);

const TimePickerView = (props) => {
	const {value = "", name = "",  onTimeChange} = props;

	const [time, setTime] = useState("");
	
	useEffect(() => {
		if(value !== "") {
			setTime(value);
		}
	}, [value]);

	const format = 'HH:mm';

	const handleTime = (time, timeString) => {
		onTimeChange(name, timeString);
		setTime(time);
	}

	const formattedval = time !== "" ? dayjs(time, format) : null;

	return (
		<TimePicker 
			name={name} 
			placeholder={name === "startTime" ? "Start Time" : "End Time"} 
			allowClear={false} 
			className="w-100" 
			format={format}
			value={formattedval}
			variant="borderless"
			onChange = {handleTime} 
		/>
	)
}

export default TimePickerView;