import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import { styled } from '@mui/material/styles';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {getAuthenticateHeader, getCookie} from '../../../../../utils/util';

import FloatSelectView from '../../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../../components/input/Input';
import Textarea from '../../../../../components/textarea/Textarea';
import Loading from '../../../../../components/loading/Loading';
import {baseURL} from '../../../../../actions/Constants';
import brown_cancel from '../../../../../assets/brown_cancel.png';

import {InitialPackageDetails, Month_Array, InitialPackageErrors} from '../../../../../utils/DefaultInitialValues';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdatePackage = (props) => {
  	const {
	    details = InitialPackageDetails,
	    isOpen = false, 
	    handleClose,
		onUpdateSuccess
  	} = props;

  	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  	const [packageDetails, setPackageDetails] = useState(details);
  	const [packageError, setPackageError] = useState(InitialPackageErrors);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

    const isEdit = (details.id && details.id !== "");
  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	const onUpdatePackageDetails = (e) => {
  		setPackageDetails({...packageDetails, [e.target.name] : e.target.value});
  	}

  	const validate = () => {
  		let isFormValid = true;

  		let packageNameInvalid = false;
  		let packageSessionInvalid = false;
  		let packagePriceInvalid = false;
  		let packageValidityDurationInvalid = false;

  		if(packageName === "") {
  			packageNameInvalid = true;
  			isFormValid = false;
  		}

  		if(noOfSessions === "") {
			packageSessionInvalid = true;
			isFormValid = false;
  		}

  		if(packagePrice === "") {
  			packagePriceInvalid = true;
  			isFormValid = false;
  		}

  		if(packageValidityDuration === "") {
  			packageValidityDurationInvalid = true;
  			isFormValid = false;
  		}

  		setPackageError({...packageError,
  			package_name_error: packageNameInvalid,
			no_of_sessions_error: packageSessionInvalid,
			package_price_error: packagePriceInvalid,
			package_validity_duration_error: packageValidityDurationInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			UpdatePackageDetails();
  		}
  	}

  	const UpdatePackageDetails = async () => {
        const URL= isEdit ? `${baseURL}/minded-services/updatePackageById/${packageDetails.id}` : `${baseURL}/minded-services/addPackage`;
        setLoading(true);

        packageDetails.createdBy = loggedUser?.userId;

		const myHeaders = getAuthenticateHeader();

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(packageDetails), // body data type must match "Content-Type" header
		    headers: {
		    	'Content-Type': 'application/json'
		    }
        })
        .then((res) => {
            //Close Dialog
            const {status = ""} = res;

            setLoading(false);

            if(status === 200 || status === 201) {
	            setError(false);
	            onUpdateSuccess();
	            handleClose();
            } else {
            	setError(true);
            }
        })
        .catch((error) => {
            console.log('error UpdatePackageDetails/addPackageDetails', error);
            setLoading(false);
            setError(true); 
            //handleClose();
        });
    }

  	const {
		packageName = "",
		packageDescription = "",
		noOfSessions = "",
		packagePrice = "",
		priceCurrency = "EUR",
		packageValidityDuration = "",
		createdBy = ""
  	} = packageDetails || {};

  	const {
  		package_name_error = false,
		no_of_sessions_error = false,
		package_price_error = false,
		package_validity_duration_error = false
  	} = packageError || {};

  	const title = isEdit ? "Update Package": "Create New Package";

	return (
		<>
		    <BootstrapDialog
		        onClose={handleClose}
		        aria-labelledby="customized-dialog-title"
		        open={open}
		    >

		    <DialogActions className="w-100 p-4 bg-white">
		        <div className="w-100 d-flex justify-content-between align-items-center">
		          <div>
		   
		          </div>

		          <h6 className="heading-6 color-primary-50">
		            {title}
		          </h6>

		          <div>
		            <button     
		              onClick={handleClose}
		              aria-label="close"
		              className="close_icon ax-button"
		            >
		              <img src={brown_cancel} className="close_icon" />
		            </button>
		          </div>
			    </div>
		    </DialogActions>

	        <DialogContent className="bg-white">
	      		<div className="container-fluid">
	      			<div className="row">

	      				<div className="col-lg-12">
	      					<div className="w-100 my-3">
					            <div className="w-100">
					              <Input
					                name="packageName"
					                label="Package name"
					                placeHolder="xxx" 
					                value={packageName}
					                onInputChange = {e => onUpdatePackageDetails(e)}
					              />
					            </div>
					            {package_name_error && <div className="text-danger">! Package name is a required field</div>}
					        </div>
					    </div>
					    <div className="col-lg-12 my-3">
					   		<Textarea
					   			label="Description (optional)"
					   			name="packageDescription" 
					   			value={packageDescription}
					   			classVal="w-100" 
					   			placeHolder="max 300 charecters"
					   			onInputChange={(e) => onUpdatePackageDetails(e)} 
					   		/>
              			</div>

		      			<div className="col-lg-12 my-3">
				            <div className="w-100">
				              <Input
				                name="noOfSessions"
				                label="No. of sessions"
				                placeHolder="xxx" 
				                value={noOfSessions}
				                type="number"
				                onInputChange = {e => onUpdatePackageDetails(e)}
				              />
				              
				            </div>
				            {no_of_sessions_error && <div className="text-danger">! Session count is a required field</div>}
					    </div>

					    <div className="col-lg-12">
	      					<div className="w-100 my-3">
				              	<Input
					                name="packagePrice"
					                label="Price (EUR)"
					                placeHolder="xxx" 
					                value={packagePrice}
					                type="number"
					                onInputChange = {e => onUpdatePackageDetails(e)}
				              	/>
					        </div>
					        {package_price_error && <div className="text-danger">! Package price is a required field</div>}
					    </div>

					    <div className="col-lg-12">
	      					<div className="w-100 my-3">
						    	<FloatSelectView
					                name = "packageValidityDuration"
					                label = "Valid for"
					                list = {Month_Array}
					                extraText = "Months"
					                value = {packageValidityDuration}
					                onInputChange = {e => onUpdatePackageDetails(e)} 
	              				/>
	              			</div>
	              			{package_validity_duration_error && <div className="text-danger">! Package validity is a required field</div>}
		              	</div>
		            </div>    
				</div>

	        </DialogContent>

	        <DialogActions className="bg-white">
	        	<div className="py-3">
		        	<button autoFocus onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2">
		            	Cancel
		          	</button>

		          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize">
		           		{isEdit ? "Update" : "Add"}
		          	</button>
	            </div>
	        </DialogActions>
      	</BootstrapDialog>
      	{isLoading && <Loading />}

	    {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
    </>
	)
}

export default UpdatePackage;
