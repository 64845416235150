import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Carousel from 'react-bootstrap/Carousel'; 

import AuthDialog from '../../components/authenticate/AuthDialog';

import CatalystCard from '../../components/catalyst-card/CatalystCard';
import CatalystProgressSlider from '../../components/Swiper/CatalystProgressSlider';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import FeatureDetails from './FeatureDetails';
import HelmetDetails from "../../components/HelmetDetails";
import Loading from '../../components/loading/Loading';

import OnBoardingDialog from '../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import ProgressSlider from '../../components/Swiper/ProgressSlider';

import about from '../../assets/about-1.png';
import {saveAdmin} from '../../actions/admin.actions';

import {getServices} from '../../actions/services.actions';
import {getPopularCatalystAll} from '../../actions/catalysts.actions';
import {InitialUserDetails} from "../../utils/DefaultInitialValues";

import hero_img_1 from '../../assets/Header.png';
import hero_img_2 from '../../assets/header_01.png';
import hero_img_3 from '../../assets/header_02.png';

import vector from '../../assets/Vector 64.png';
import './start-here.css';

const Home = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getServicesAction = getServices;
	const getPopularCatalystAllAction = getPopularCatalystAll;

	const [events, setEvents] = useState([]);
	const [catalysts, setCatalysts] = useState([]);

	const [isShowLoginDialog, setShowLoginDialog] = useState(false);
	const [userDetails, setUserDetails] = useState(InitialUserDetails);
	const [isShow, setShow] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    
	const saveAdminAction = saveAdmin;

    const {
       details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));


	useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});

    	const today = new Date();
    	const endDate = new Date(); //15 days from now
    	endDate.setDate(endDate.getDate() + 14);

    	dispatch(getServicesAction("", "", "EVENT", "", today.getTime(), endDate.getTime(), "active"));
    	dispatch(getPopularCatalystAllAction());
    }, []);

    const {
       details,
       loading,
       error
    } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));

    const {
       details: catalystsDetails,
       loading: catalystsLoading,
       error: catalystsError
    } = useSelector(({ catalysts: { details, loading, error} }) => ({ details, loading, error }));

    useEffect(() => {
    	if(Array.isArray(details)) {
    		setEvents(details);
    	}
    }, [details]);

     useEffect(() => {
    	if(Array.isArray(catalystsDetails)) {
    		const activeCatalsyt = catalystsDetails.filter(cat => cat.status === "active");
    		setCatalysts(activeCatalsyt);
    	}
    }, [catalystsDetails]);

    const onLoginSuccess = (isSignupView = false, userinfo = {}) => {
        setShowLoginDialog(false);

        if(isSignupView) {
            setUserDetails(userinfo);
            setShow(true);
        } else {
            setLoggedIn(true);
            dispatch(saveAdminAction(userinfo));
            navigate("/dashboard");
        }
    }

    const onEventClick = (item) => {
    	navigate(`/events-classes/service-details/${item.serviceId}`);
    }

    const handleExpertBtnClick = () => {
    	navigate('/catalysts');
    }

    const handleEventBtnClick = () => {
    	navigate('/events-classes');
    }

    const handleBecomeCatalystBtnClick = () => {
    	navigate('/events-classes');
    }

    const becomePartnerBtnClick = () => {
    	//navigate('/news');
    }

    const handleAboutBtnClick = () => {
    	navigate('/about');
    }

    const showCatalystDetails = (item) => {
    	navigate(`/catalyst/${item.userId}`);
    }

    const joinTheClub = () => {
    	setShowLoginDialog(true);
    }

    useEffect(() => {
    	setUserDetails(loggedUser);
    }, [loggedUser]);

    const isLoading = loading || catalystsLoading;

    const isUserSignedIn = userDetails && userDetails?.userId && userDetails?.userId !== "";

	return(
		<>
			<HelmetDetails title="Home | Minded." />
			<div className="hero">
				<Carousel> 
			        <Carousel.Item interval={6000}> 
			        	<img src={vector} className="vector" alt=""/>
			          	<img className="hero" src={hero_img_1} alt = "Slide One" /> 
				        <div className="hero-content">
				            <div>
					            <h1 className="heading-1 color-accent-90 line-height_val">
									Unlock <div className="heading-1 color-accent-90">your potential</div>
								</h1>
								<div className="heading-5 color-accent-90 shadow_text pt-2">We are Minded.</div>
							</div>
							
							<div className="py-3 sub_text start-here-sub-text capitalize color-accent-90">A vibrant community of like-minded people on a transformative journey of growth and self-empowerement.</div>
							<div className="CTA_button_container">
								<button className="me-4 primary_btn capitalize LINK" onClick={joinTheClub} disabled={isUserSignedIn}>Join the Club</button>
								<button className="secondary_btn capitalize LINK" onClick={handleEventBtnClick}>Upcoming Events</button>
							</div>
						</div>
			        </Carousel.Item>
			         
			        <Carousel.Item interval={6000}> 
			        	<img src={vector} className="vector" alt="" />
			          	<img className="hero" src={hero_img_2} alt="Slide two" /> 
				        <div className="hero-content">
				            <div>
					            <h1 className="heading-1 color-accent-90 line-height_val">
									Unlock <div className="heading-1 color-accent-90">your potential</div>
								</h1>
								<div className="heading-5 color-accent-90 shadow_text pt-2">We are Minded.</div>
							</div>
							<div className="py-3 sub_text start-here-sub-text capitalize color-accent-90">A vibrant community of like-minded people on a transformative journey of growth and self-empowerement.</div>
							<div className="d-flex">
								<button className="me-4 primary_btn capitalize LINK" onClick={joinTheClub} disabled={isUserSignedIn}>Join the Club</button>
								<button className="me-4 secondary_btn capitalize LINK" onClick={handleEventBtnClick}>Upcoming Events</button>
							</div>
						</div>
			        </Carousel.Item>
			        <Carousel.Item interval={6000}> 
			        	<img src={vector} className="vector" alt="" />
			          	<img className="hero" src={hero_img_3} alt="Slide three"/> 
				        <div className="hero-content">
				            <div>
					            <h1 className="heading-1 color-accent-90 line-height_val">
									Unlock <div className="heading-1 color-accent-90">your potential</div>
								</h1>
								<div className="heading-5 color-accent-90 shadow_text pt-2">We are Minded.</div>
							</div>
							<div className="py-3 sub_text start-here-sub-text capitalize color-accent-90">A vibrant community of like-minded people on a transformative journey of growth and self-empowerement.</div>
							<div className="d-flex">
								<button className="me-4 primary_btn capitalize LINK" onClick={joinTheClub} disabled={isUserSignedIn}>Join the Club</button>
								<button className="me-4 secondary_btn capitalize LINK" onClick={handleEventBtnClick}>Upcoming Events</button>
							</div>
						</div>
			        </Carousel.Item>
  				</Carousel>
			</div>
			<div className="main-content-padding">
				<div className="flex_container color-primary-50 mt-5">
					<div className="text-center flex_container_24 px-lg-5 px-md-3 px-0">
						<div className="w-100 body-CAP color-primary-50 text-center capitalize">YOUR JOURNEY STARTS HERE</div>
						<h5 className="heading-5  text-center w-100 px-lg-5 px-md-3 px-0">Explore our wide range of catalysts who are here to offer their insights, knowledge and support.</h5>
						<h5 className="heading-5 px-lg-5 px-md-3 px-0">Whether you’re searching for a Fitness Coach, a Therapist or a Nutritionist, we’re here to help you connect with your future support Catalyst.</h5>
						<div className="d-flex justify-content-center w-100">
							<a href="#cat_section_id" className="secondary_btn capitalize LINK">Book With a Catalyst</a>
						</div>
					</div>	
				</div>

				<div className="flex_container">
					<h4 className="heading-4 color-primary-50 mt-5">Here you can expect...</h4>
					<FeatureDetails />
				</div>

				<div className="flex_container gap_8 w-75 m-auto">
					<div className="w-100 text-center flex_container_24 mt-5">
						<div className="w-100 body-CAP color-primary-50 text-center capitalize">You can also</div>
						<h5 className="w-100 heading-5 color-primary-50">Explore our community events and gain valuable support from our expert Catalysts</h5>
						
						<div className="w-100 d-flex justify-content-center">
							<button className="me-4 secondary_btn my-4 LINK capitalize" onClick={handleBecomeCatalystBtnClick}>Events and classes</button>
							<button className="secondary_btn my-4 LINK capitalize" onClick={handleExpertBtnClick}>Catalysts</button>
						</div>
					</div>
				</div>
		
				<div className="flex_container mt-5">
					<div className="d-flex justify-content-between align-items-center w-100">
						<h4 className="heading-4 color-primary-50">Upcoming Events</h4>
						<div>
							{events.length !== 0 && <Link to="/events-classes" className="LINK capitalize fw-bold color-secondary-100">View ALL</Link>}
						</div>
					</div>
				
					{events.length !== 0 ? <ProgressSlider list = {events} onCardClick = {(val) => onEventClick(val)} /> : <h6 className="heading-6 color-primary-50">No upcoming events</h6>}
				</div>

				{catalysts.length !== 0 && <div className="desktop_view w-100">
					<div className="flex_container mt-5" id="cat_section_id">
						<div className="d-flex justify-content-between align-items-center w-100">
							<h4 className="heading-4 color-primary-50">Learn from the best</h4>
							<div>
								<Link to="/catalysts" className="LINK capitalize fw-bold color-secondary-100">View ALL</Link>
							</div>
						</div>

						<div className="flex_container gap_8 w-75 m-auto">
							<div className="w-100 text-center flex_container_24">
								<div className="w-100 body-CAP color-primary-50 text-center capitalize">What is a Catalyst?</div>
								<h5 className="w-100 heading-5 color-primary-50">Catalysts are experts within their fields related to personal growth that are here to offer their insights, knowledge, and support.</h5>
							</div>
						</div>

						<div className="catalyst_grid w-100">
							{catalysts.length === 0 && <h6 className="heading-6 color-primary-50">No Catalysts</h6>}
							{catalysts.slice(0,4).map((item, index) => 
								<CatalystCard item = {item} onCatalystClick = {item => showCatalystDetails(item)} />
			        		)}
						</div>
					</div>
	        	</div>}

				{catalysts?.length !== 0 && <div className="mobile_view w-100">
					<div className="flex_container" id="cat_section_id mt-5">
						<div className="d-flex justify-content-between align-items-center w-100">
							<h4 className="heading-4 color-primary-50">Catalysts</h4>
							<div>
								<Link to="/catalysts" className="LINK capitalize fw-bold color-secondary-100">View ALL</Link>
							</div>
						</div>

						<div className="flex_container gap_8 w-75 m-auto">
							<div className="w-100 text-center flex_container_24">
								<div className="w-100 body-CAP color-primary-50 text-center capitalize">What is a Catalyst?</div>
								<h5 className="w-100 heading-5 color-primary-50">Catalysts are experts within their fields related to personal growth that are here to offer their insights, knowledge, and support.</h5>
							</div>
						</div>
						<CatalystProgressSlider list={catalysts} onCardClick={(val) => showCatalystDetails(val)} />
					</div>
				</div>}

				<div className="yellow_container my-5">
					<div className="m-auto flex_container gap_8 text-center w-100">
						<div className="w-100 capitalize body-CAP color-neutral-100 text-center">our mission</div>
						<h5 className="w-100 heading-5 text-center font-400">Personal growth isn't just about fixing your problems</h5>
						<h5 className="w-100 heading-5 text-center font-400">It's also about recognising and nurturing your your strengths, talents and passions.</h5>
					</div>

					<div className="yellow_bottom_container">
						
						<img src={about} className="yellow_img" alt="" />

						<div className="right_view">
							<div className="body-R">We want you to discover and embrace your potential. Count on us to learn and develop skills that will have a big impact on your life.</div>
							<div className="body-R">To create a space where the human being is the focus of attention, where knowledge and training are promoted in order to foster personal development.</div>
							<div className="body-R">To enable people to achieve the most of their potential, and in so doing, become a driving force behind the continuous improvement of communities.</div>
							<div className="start-here-CTA">
								<button className="primary_btn capitalize LINK" onClick={handleAboutBtnClick}>About Minded CLUB</button>
							</div>
						</div>	
					</div>
				</div>	
			</div>


			 {isShowLoginDialog && <AuthDialog 
                isOpen = {isShowLoginDialog} 
                onHandleClose={(val) => setShowLoginDialog(false)} 
                onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
            />}

            {isShow && <OnBoardingDialog 
                isOpen = {isShow}
                onHandleClose={() => setShow(false)} 
                setLoggedIn = {val => setLoggedIn(val)}
                userinfo = {userDetails}
            />}

			{isLoading && <Loading />}
			{(error || catalystsError) && <CustomizedSnackbars isShow={error || catalystsError} text="Something went wrong. Please try after sometime." />}
		</>
	)
}

export default Home;

