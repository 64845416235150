import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FloatSelectView from '../../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../../components/input/Input';
import Loading from '../../../../../components/loading/Loading';
import {baseURL} from '../../../../../actions/Constants';
import PackageView from "../../../../../components/packageView/PackageView";

import brown_cancel from '../../../../../assets/brown_cancel.png';

import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import {InitialPolicyDetails, Policy_Array, InitialPolicyErrors} from '../../../../../utils/DefaultInitialValues';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdatePolicy = (props) => {
	const {
	    details = InitialPolicyDetails,
	    isOpen = false, 
	    handleClose,
	    onUpdateSuccess
  	} = props;

  	const addReschedulePolicy = () => {
  		const newArray = [...rescheduleList];
  		newArray.push({
      		reschedulePermitHr: "",
      		rescheduleMaxCount: ""
    	});
    	setPolicyDetails({...policyDetails, rescheduleList : newArray});
  	}

  	const addCancellationPolicy = () => {
  		const newArray = [...cancellationList];
  		newArray.push({
      		cancelPermitHr: "",
      		cancelRefundMoney: ""
    	});
    	setPolicyDetails({...policyDetails, cancellationList : newArray});
  	}

  	const [policyDetails, setPolicyDetails] = useState(details);
  	const [policyError, setPolicyError] = useState(InitialPolicyErrors);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const isEdit = (details.id && details.id !== "");

  	const [open, setOpen] = useState(false);

  	const onUpdatePolicyDetails = (e) => {
  		setPolicyDetails({...policyDetails, [e.target.name] : e.target.value});
  	}

  	const onHandleDateUpdate = (name, dateVal) => {
  		setPolicyDetails({...policyDetails, [name] : dateVal});
  	}

  	const onHandleItemChange = (type = "", e, updateInx = 0) => {
	    if(type === "Rescheduling") {
	      rescheduleList[updateInx][e.target.name] = e.target.value;
	      const newArray = [...rescheduleList];
	      setPolicyDetails({...policyDetails, rescheduleList : newArray});
	      
	    } else if(type === "Cancellation") {
	      cancellationList[updateInx][e.target.name] = e.target.value;
	      const newArray = [...cancellationList];
	      setPolicyDetails({...policyDetails, cancellationList : newArray});
	    }
  	}

  	const removeItem = (type = "", delInx = 1) => {
	    let updatedList = [];

	    switch(type) {
	      
	      	case "Rescheduling":
	        	updatedList = rescheduleList.filter((item, index) => index !== delInx);
	       		setPolicyDetails({...policyDetails, rescheduleList : updatedList});
	        	break;
	        case "Cancellation":
	        	updatedList = cancellationList.filter((item, index) => index !== delInx);
	       		setPolicyDetails({...policyDetails, cancellationList : updatedList});
	        	break;
	    }
  	}

  	const validate = () => {
  		let isFormValid = true;

  		let isPolicyNameInvalid = false;
  		let isReschduleInvalid = false;
  		let isCancellationInvalid = false;

  		if(policyName === "") {
  			isPolicyNameInvalid = true;
  			isFormValid = false;
  		}

  		if(rescheduleType === "allowed") {
  			if(rescheduleList.length === 0) {
  				isReschduleInvalid = true;
  				isFormValid = false;
  			}
  		}

  		if(cancellationType === "allowed") {
  			if(cancellationList.length === 0) {
  				isCancellationInvalid = true;
  				isFormValid = false;
  			}
  		}
  	
  		setPolicyError({...policyError,
	  		policy_name_error : isPolicyNameInvalid,
			reschedule_list_error : isReschduleInvalid,
			cancellation_list_error : isCancellationInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			UpdateCouponDetails();
  		}
  	}

  	const UpdateCouponDetails = async () => {
        const URL= isEdit ? `${baseURL}/updateCoupon` : `${baseURL}/addCoupon`;
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(policyDetails), // body data type must match "Content-Type" header
	      	headers: {
		        'Content-Type': 'application/json'
	      	}
        })
        .then((res) => {
            //Close Dialog
            const {status = ""} = res;

            setLoading(false);

            if(status === 200 || status === 201) {
	            setError(false);
	            onUpdateSuccess();
	            handleClose();
            } else {
            	setError(true);
            	handleClose(); 
            }
        })
    }

  	const {
  		id = "",
		policyName = "",
	  	rescheduleType = "allowed",
	  	rescheduleList = [],
	  	cancellationType = "allowed",
	  	cancellationList = []
  	} = policyDetails || {};

  	const {
  		policy_name_error = false,
		reschedule_list_error = false,
		cancellation_list_error = false
	} = policyError || {};

	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	useEffect(() => {
  		if(rescheduleList.length === 0) {
  			addReschedulePolicy();
  		}
  	}, []);

  	useEffect(() => {
  		if(rescheduleList.length === 0) {
  			addReschedulePolicy();
  		}
  	}, [rescheduleList]);

  	useEffect(() => {
  		if(cancellationList.length === 0) {
  			addCancellationPolicy();
  		}
  	}, [cancellationList]);

	const title = isEdit ? "Update Policy" : "Create New Policy";

	return (
		<>
		    <BootstrapDialog
		        onClose={handleClose}
		        aria-labelledby="customized-dialog-title"
		        open={open}
		    >
		    <DialogActions className="w-100 p-4 bg-white">
		        <div className="w-100 d-flex justify-content-between align-items-center">
		          	<div>
		   
		          	</div>

		          	<h6 className="heading-6 color-primary-50">
		            	{title}
		          	</h6>

		          	<div>
			            <button     
			              onClick={handleClose}
			              aria-label="close"
			              className="ax-button close_icon"
			            >
			              <img src={brown_cancel} className="close_icon" />
			            </button>
		          	</div>
			    </div>
	    	</DialogActions>

		        <DialogContent className="bg-white">
		      		<div className="container-fluid">
		      			<div className="row">
		      			
				        	<div className="col-lg-12 my-3">
					            <div className="w-100">
					              <Input
					                name="policyName"
					                label="Policy name"
					                placeHolder="xxx" 
					                value={policyName}
					                onInputChange = {e => onUpdatePolicyDetails(e)}
					              />
					            </div>
					            {policy_name_error && <div className="text-danger pt-1">! Policy name is a required field</div>}
						    </div>
					    	<h6 className="color-primary-50 heading-6 my-3">Rescheduling policy</h6>

				        	<div className="w-100 d-flex col-lg-12">
				              {Policy_Array.map((item, index) => {
				                return (
				                  <div className="pe-3 d-flex align-items-center">
				                    <input id={item.value} type="radio" name="rescheduleType" value={item.value} onChange={(e) => onUpdatePolicyDetails(e)} checked = {item.value === rescheduleType} />
				                    <label for={item.value} className="px-3 body-R color-primary-50">{item.label}</label>
				                  </div>
				                )}
				              )}
				            </div>
				           
				            {rescheduleType === "not-allowed" && <div className="w-100">
				                <label className="my-3 color-primary-50 body-R">No Reschedule is possible</label>
				                </div>
				            }
				           
				            {rescheduleType === "allowed" &&
				            	<div> 
									<div className="w-100 col-lg-12">
					                  <PackageView 
					                    title="Rescheduling"
					                    firstLabel="Reschedule permitted in hr."
					                    secondLabel="No. of times allowed reschedule"
					                    firstFieldName = "reschedulePermitHr"
					                    secondFieldName = "rescheduleMaxCount" 
					                    list = {rescheduleList}
					                    label = "Rescheduling"
					                    removeItem = {(type, delInx) => removeItem(type, delInx)}
					                    handleChange = {(title, e, index) => onHandleItemChange(title, e, index)}
					                  />
					            	</div>

					            	{reschedule_list_error && <div className="text-danger pt-1">! Please select atleast 1 Reschedule policy</div>}

					            	<div className="w-100 col-lg-12 my-3">
					            		<button 
					            			className="tertiary_btn color-primary-50 w-100 capitalize" 
					            			disabled={rescheduleList.length >= 10} 
					            			onClick={addReschedulePolicy}
					            		>
					            			Add more Reschedule Policies
					            		</button>
		                			</div>
                				</div>
                			}
                		
			            	<h6 className="color-primary-50 heading-6 my-3">Cancellation policy</h6>

			            	<div className="w-100 d-flex col-lg-12">
				               {Policy_Array.map((item, index) => {
				                return (
				                  <div className="pe-3 d-flex align-items-center">
				                    <input id={`cancellation_${item.value}`} type="radio" name="cancellationType" value={item.value} onChange={(e) => onUpdatePolicyDetails(e)} checked = {item.value === cancellationType} />
				                    <label for={`cancellation_${item.value}`} className="px-3 body-R color-primary-50">{item.label}</label>
				                  </div>
				                )}
				              )}
				            </div>

				            {cancellationType === "allowed" && <div>
								<div className="w-100 col-lg-12">
				                  <PackageView 
				                    title="Cancellation"
				                    firstLabel="Cancellation permitted in hr."
				                    secondLabel="Refund Money (EUR)"
				                    firstFieldName = "cancelPermitHr"
				                    secondFieldName = "cancelRefundMoney" 
				                    list = {cancellationList}
				                    label = "Cancellation"
				                    removeItem = {(type, delInx) => removeItem(type, delInx)}
				                    handleChange = {(title, e, index) => onHandleItemChange(title, e, index)}
				                  />
				            	</div>
				            	{cancellation_list_error && <div className="text-danger pt-1">! Please select atleast 1 cancellation policy</div>}

				            	<div className="w-100 col-lg-12 my-3">
				            		<button 
				            			className="tertiary_btn color-primary-50 w-100 capitalize" 
				            			disabled={cancellationList.length >= 10} 
				            			onClick={addCancellationPolicy}
				            		>
				            			Add more Cancellation Policies
				            		</button>
	                			</div>
	                		</div>
	                		}

				            {cancellationType === "not-allowed" && <div className="w-100">
				                <label className="my-3 color-primary-50 body-R">No Cancellation is possible</label>
				                 
				                </div>
				            }
				           
					    </div>
					</div>

		        </DialogContent>

		        <DialogActions className="bg-white">
		        	<div className="py-3">
			        	<button autoFocus onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2">
			            	Cancel
			          	</button>

			          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize">
			           		{isEdit ? "Update" : "Add"}
			          	</button>
		            </div>
		        </DialogActions>
	      	</BootstrapDialog>
	      	{isLoading && <Loading />}
	      	{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}		
    	</>
	)
}

export default UpdatePolicy;