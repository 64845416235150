import {useState, useEffect, useRef} from 'react';

import SubscriptionDetails from '../dashboard/settings/SubscriptionDetails';

const PlanDetails = (props) => {
  const dialogRef = useRef();
	const {userDetails = {},  userErrors = {}, onHandlePlanDetailsChange} = props;

  const {
    catalystDetails = {}
  } = userDetails;

  const {
    planDetails = {}
  } = catalystDetails || {};

  useEffect(() => {
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {
    }
  }, []);

	return (
		<div ref={dialogRef}>
      <div className="annual_plan">
        <div className="body-R color-primary-50 my-3">Choose a yearly pricing plan and get additional discounts!</div>
      </div>

      <SubscriptionDetails 
        pageName = "Catalyst" 
        CTALabel = "Select" 
        onHandlePlanDetailsChange = {onHandlePlanDetailsChange} 
        planDetails = {planDetails} 
        userErrors = {userErrors}
      />
     
    </div>
	)
}

export default PlanDetails;