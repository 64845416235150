import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import Loading from '../../../components/loading/Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ScheduleCalendar from './ScheduleCalendar';
import PlanScheduleCalendar from './PlanScheduleCalendar';
import ScheduleConfirmationView from './ScheduleConfirmationView';
import {baseURL} from '../../../actions/Constants';

import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import {getBookings} from '../../../actions/bookings.actions';
import brown_cancel from '../../../assets/brown_cancel.png';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ScheduleDialog = (props) => {
  const dispatch = useDispatch();
  const _ = require('lodash');
  
  const {isOpen = false, onHandleClose, purchaseId = "", service_Type = "ticket"} = props;

  const [stepperView, setStepperView] = useState(1);
  const [open, setOpen] = useState(false);

  const [bookingDetails, setBookingDetails] = useState([]);
  const [currentSchedules, setCurrentSchedules] = useState([]);
  const [openSchedules, setOpenSchedules] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [overallCurrentBookings, setOverallCurrentBookings] = useState([]);

  const getBookingsAction = getBookings;
  
  const {
    details: bookingsDetails,
    loading: bookingsLoading,
    error: bookingsError
  } = useSelector(({ bookings: { details, loading, error} }) => ({ details, loading, error }));
      

  useEffect(() => {
    setOpen(isOpen);
    if(isOpen && purchaseId) {
      dispatch(getBookingsAction("", "", "", "", purchaseId, "", "", "", service_Type));
    }
  }, [isOpen]);

  useEffect(() => {
      if(bookingsDetails && Array.isArray(bookingsDetails)) {
        setBookingDetails(bookingsDetails);
        const nonSchedulesBookings = bookingDetails.filter(item => item.bookingStatus === "open");
        setOpenSchedules(nonSchedulesBookings);
      }
    }, [bookingsDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleClose();
  };

  const goNext = async () => {
    if(stepperView === 1) {
    
      
     // const completeJSON = [...currentSchedules];
      const clonedArray = _.cloneDeep(currentSchedules);
      setOverallCurrentBookings(clonedArray);

      const allPromises = currentSchedules.map(async (item, idx) => {
        const returnVal = await bookSchedule(item, idx);
        return returnVal;
      });

      const userDetails = await Promise.all(allPromises);
      setStepperView(stepperView + 1);
    } else {
      handleClose();
    }
  }

  const handleScheduleNow = () => {
    handleClose();
  }

  const bookSchedule = async (item, idx) => {

    item.scheduleDate = (new Date()).getTime();
    item.bookingDate = (new Date()).getTime();
    item.bookingStatus = "active";

    delete item?.schedule;

    setLoading(true);

    const URL=`${baseURL}/minded-services/insertOrUpdateBooking`;

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(item),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;
      setLoading(false);
      if(status === 200 || status === 201) {
        return response.json().then(res => {
          setError(false);
        });
        
      } else {
        //Error
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error bookSchedule', error);
      setError(true);
    });
  }

  const title = (stepperView === 1) ? "Schedule your sessions" : "Confirmation";
  const submitBtnText = (stepperView === 1) ? "Schedule" : "Close";

 const {
  serviceName = "",
  serviceLocation = ""
 } = bookingDetails || {};

  return (
    <div className="full-dialog">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >

        <DialogActions className="d-flex justify-content-between align-items-center p-4">
          <div>
              
          </div>

          <h4 className="heading-4 color-primary-50">
            {title}
          </h4>

          <div>
            <button     
              onClick={handleClose}
              aria-label="close"
              className="close_icon ax-button"
            >
              <img src={brown_cancel} className="close_icon" />
            </button>
          </div>
          
        </DialogActions>

        <DialogContent>
          {service_Type === "package" && stepperView === 1 && <ScheduleCalendar
            bookingDetails = {bookingDetails} 
            setCurrentSchedules = {setCurrentSchedules}
            currentSchedules = {currentSchedules}
            purchaseId = {purchaseId}
          />}

          {service_Type === "plan" && stepperView === 1 && <PlanScheduleCalendar
            bookingDetails = {bookingDetails} 
            setCurrentSchedules = {setCurrentSchedules}
            currentSchedules = {overallCurrentBookings}
            purchaseId = {purchaseId}
          />}

          {stepperView === 2 && <ScheduleConfirmationView currentSchedules = {overallCurrentBookings} />}
        </DialogContent>

        <DialogActions className="dialog_footer d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex justify-content-between align-items-center">
                <div className="px-3 subtitle-R color-primary-50"></div>
                
              </div>
              <div className="d-flex">
                <button className="mx-3 secondary_btn capitalize" onClick={goNext} disabled={currentSchedules.length === 0}>{submitBtnText}</button>
              </div>
            </div>
          </div>
        </DialogActions>
        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />} 
      </Dialog>
    </div>
  );
}


export default ScheduleDialog;