import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import {useDispatch} from 'react-redux';
import ArrowBack from '@mui/icons-material/ArrowBack';

import AddObjective from './AddObjective';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import Confirmation from './Confirmation';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import UserDetails from './UserDetails';
import Loading from '../../../components/loading/Loading';
import GuidelineInfo from './GuidelineInfo';

import {InitialUserDetails, InitialUserErrors} from '../../../utils/DefaultInitialValues';

import {baseURL} from '../../../actions/Constants';
import {saveAdmin} from '../../../actions/admin.actions';
import brown_cancel from '../../../assets/brown_cancel.png';
import {getBoardingHeaderText, getBoardingCTAText, isShowBackBtn, covertToLower, isBigText} from '../../../utils/util';


const CustomerOnBoarding = (props) => {
	const {onHandleClose, navigateStepper = 0, userinfo = InitialUserDetails} = props;
	const navigate = useNavigate();
  	const dispatch = useDispatch();
  	const saveAdminAction = saveAdmin;

  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const [stepperView, setStepperView] = useState(1);
  	const [userDetails, setUserDetails] = useState(userinfo);
  	const [userErrors, setUserErrors] = useState(InitialUserErrors);

  	const [isGuidelineChecked, setGuidelineChecked] = useState(false);
  	const [isProfilePicUpdate, setProfilePicUpdate] = useState(false);
  	const [newProfilePic, setProfileImage] = useState("");

  	let isEdit = false;

  	useEffect(() => {
	    if(navigateStepper !== 0) {
	      setStepperView(navigateStepper);
	    }
  	}, [navigateStepper]);

  	const handleBack = () => {
    	setStepperView(stepperView - 1);
  	}

	const onHandleChange = (e) => {
	    setUserDetails({...userDetails, [e.target.name] : e.target.value});
	}

	const onHandleProfileChange = (e) => {
    	setUserDetails({...userDetails, 
    		profileDescription : {...userDetails.profileDescription, [e.target.name] : e.target.value}
    	});
  	}

  	const onDropdownChange = (name, list = []) => {
	    let latestList = [];
	    
	    if(name === "languages" || name === "languageSpeak") {
	      latestList = list.map((item, index) => {
	        const obj = {};
	        obj.value = item.value;
	        obj.id = item.id;
	        return obj;
	      });
	    } else {
	      latestList = list.map((item, index) => {
	        const obj = {};
	        obj.name = item.name;
	        obj.id = item.id;
	        return obj;
	      });
	    }

	    setUserDetails({
	    	...userDetails, 
	    	profileDescription: {...userDetails.profileDescription, [name] : latestList}
	    });
  	}

  	const onHandleAllService = () => {
    	onHandleClose();
    	navigate("/events-classes");
  	}

  	const onHandleSkipSubmit = () => {
	    const isValid = validate();
	    if(isValid) {
	      updateUser(true);
	    }
  	}

  	const onHandleProfileImageChange = (e) => {
	    let file = e.target.files[0];
	    let reader = new FileReader();

	    reader.onload = function(event) {
	      //setFile(e.target.result);
	    };
	    reader.readAsDataURL(file);

	    const extention = file.name.split('.').pop();

	    if(extention !== "png" && extention !== "jpg" ) {
	      window.alert("File does not support. You must use .png or .jpg ");
	      return false;
	    }
	    if(file.size > 2097152) {
	      window.alert("Image size should not be more than 2 MB");
	      return false;
	    }

	    setProfilePicUpdate(true);
	    const newPic = file;
	    setProfileImage(newPic);
	}

  	const onHandleAddressChange = (e) => {
    	setUserDetails({...userDetails, address : {...userDetails.address, [e.target.name] : e.target.value}});
  	}

  	const onObjectiveDropdownChange = (name, list = []) => {
    	setUserDetails({...userDetails, profileDescription: {...userDetails.profileDescription, [name] : list}});
  	}

  	const goNext = () => {
	    const isValid = validate();

	    if(stepperView === 4) {
	      onHandleClose();
	      navigate("/dashboard");
	    }

	    if(isValid) {
	        stepperView === 3 ? updateUser() : setStepperView(stepperView + 1);
	    }
  	}

  	const validate = () => {
	    let isFormValid = true;

	    let isGuidelineInvalid = false;
	    let isNameInvalid = false;
	    let isPhoneInvalid = false;
	    let isStreetInvalid = false;
	    let isApartmentInvalid = false;
	    let isStateInvalid = false;
	    let isCityInvalid = false;
	    let isPincodeInvalid = false;
	    let isCountryInvalid = false;
	    let isLanguageSpeakInvalid = false;
	    let isInterestCategoriesInvalid = false;
	    let isStugleCategoriesInvalid = false;
	    let isTaxNumberInvalid = false;
	    let isBigPublicName = false;
	    
	    if(stepperView === 1) {
	        if(!isGuidelineChecked) {
	          isGuidelineInvalid = true;
	          isFormValid = false;
	        }
	    } else if(stepperView === 2) {
	        if(name === "") {
	          isNameInvalid = true;
	          isFormValid = false;
	        }
	        if(phone === "") {
	          isPhoneInvalid = true;
	          isFormValid = false;
	        }

	        if(street === "") {
	          isStreetInvalid = true;
	          isFormValid = false;
	        }

	        if(city === "") {
	          isCityInvalid = true;
	          isFormValid = false;
	        }

	        if(state === "") {
	          isStateInvalid = true;
	          isFormValid = false;
	        }

	        if(apartment === "") {
	        	isApartmentInvalid = true;
	        	isFormValid = false;
	        }

	        if(pincode === "") {
	          isPincodeInvalid = true;
	          isFormValid = false;
	        }

	        if(country === "") {
	          isCountryInvalid = true;
	          isFormValid = false;
	        }

	        if(publicName !== "" && isBigText(publicName)) {
	        	isBigPublicName = true;
	        	isFormValid = false;
	        }

	        if(languageSpeak?.length === 0) {
	        	isLanguageSpeakInvalid = true;
	        	isFormValid = false;
	        }
	    } else if(stepperView === 3) {
	    	if(interestCategories?.length === 0) {
	    		isInterestCategoriesInvalid = true;
	    		isFormValid = false;
	    	}

	    	if(stuggleCategories?.length === 0) {
	    		isStugleCategoriesInvalid = true;
	    		isFormValid = false;
	    	}

	    	if(taxNumber === "") {
	    		isTaxNumberInvalid = true;
	    		isFormValid = false;
	    	}
	    }

	    setUserErrors({
	      ...userErrors,
	      guidelineError : isGuidelineInvalid,
	      nameError: isNameInvalid,
	      phoneError: isPhoneInvalid,
	      countryError: isCountryInvalid,
	      streetError: isStreetInvalid,
	      apartmentError: isApartmentInvalid,
	      stateError: isStateInvalid,
	      cityError: isCityInvalid,
	      pincodeError: isPincodeInvalid,
	      publicNameError: isBigPublicName,
	      languageSpeakError: isLanguageSpeakInvalid,
	      interestCategoriesError : isInterestCategoriesInvalid,
	      stuggleCategoriesError : isStugleCategoriesInvalid,
	      taxNumberError: isTaxNumberInvalid
	    });

	    return isFormValid;
  	}

  	const uploadProfileImage = async (data = userDetails, updatedProfile, isSubmit = false) => {
	    const URL = `${baseURL}/minded-user/uploadUserProfilePicture`;
	    setLoading(true);

	    var imageFormData = new FormData();

	    imageFormData.append('userId', data?.userId);
	    imageFormData.append("file", newProfilePic);

	    return await fetch(URL, {
	      method: 'POST', // *GET, POST, PUT, DELETE, etc.
	      body: imageFormData // body data type must match "Content-Type" header
	    })
	    .then(response => {
	      const resp = response;
	      const {status = ""} = resp;

	      setLoading(false);

	      if(status === 200) {
	        return response.json().then(res => { 
	          dispatch(saveAdminAction(res));
	          if(isSubmit) {
	            setStepperView(4);
	          }
	        });
	      } else {
	        setError(true);
	      }
	    })
	    .catch((error) => {
	      console.log('error uploadProfileImage', error);
	      setLoading(false);
	    });
  	}

  	const updateUser = async (isSubmit = false) => {
	    userDetails.status = "active";
	    isEdit = (userDetails?.userId && userDetails?.userId !== "");
	    const URL = isEdit ?  `${baseURL}/minded-user/updateUserDetails` : `${baseURL}/minded-user/createUser`;
	    setLoading(true);
	    
	    let updatedProfile = "";

	    return await fetch(URL, {
	      method: 'POST',
	      body: JSON.stringify(userDetails),
	      headers: {
	        'Content-Type': 'application/json'
	      }
	    })
	    .then(response => {
	      const resp = response;
	      const {status = ""} = resp;

	      setLoading(false);

	      if(status === 200 || status === 201) {
	        return response.json().then(res => { 
	          setUserDetails((prevState) => ({
	            ...prevState,
	            res,
	            userId: res?.userId
	          }));

	          dispatch(saveAdminAction(res));
	          if(isProfilePicUpdate) {
	            uploadProfileImage(res, updatedProfile, isSubmit);
	          } else {
	            if(isSubmit) {
	                setStepperView(4);
	            } else {
	               setStepperView(stepperView + 1);
	            }
	          }
	        });
	      } else {
	        setError(true);
	      }
	    })
	    .catch((error) => {
	      console.log('error addService/updateService', error);
	      setLoading(false);
	    });
  	}

  	const isShowSkipButton = () => {
		if(stepperView !== 4 && userDetails && userDetails?.userId && userDetails?.userId !== "") {
			return true;
		}
	    
	    return false;
  	}

  	const onHandleAcountChange = (e) => {
    	setUserDetails({...userDetails, accountDetails : {...userDetails.accountDetails, [e.target.name] : e.target.value}});
  	}

  	const {
  		role = "customer",
	    name = "",
	    phone = "",
	    address = {},
	    profileDescription = {},
	    accountDetails = {}
  	} = userDetails || {};

  	const {
  		publicName = "",
  		languageSpeak = [],
  		interestCategories = [],
  		stuggleCategories = []
  	} = profileDescription || {};

  	const {
  		taxNumber = ""
  	} = accountDetails || {};

  	const {
	    street = "",
	    city = "",
	    state = "",
	    apartment = "",
	    pincode = "",
	    country = ""
  	} = address || {};

  	const isJump = false;
  	const isSkip = isJump;

  	const formattedRole = covertToLower(role);
  	const submitText = getBoardingCTAText(stepperView, formattedRole, isSkip);
  	const showBackBtn = isShowBackBtn(stepperView, formattedRole, "ON_BOARDING");
  	const title = getBoardingHeaderText(stepperView, formattedRole);

	return(
		<>
			<DialogActions className="w-100 p-4">
		        <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3">
		            <div>
		              {(showBackBtn && navigateStepper < stepperView) && <button
		                onClick={handleBack}
		                aria-label="Back"
		                className="ax-button color-primary-50"
		              >
		                <ArrowBack className = "color-primary-50" />
		              </button>
		            }
		            </div>

		            <h4 className="heading-4 color-primary-50">
		              {title}
		            </h4>

		            <div>
		              <button     
		                onClick={onHandleClose}
		                aria-label="close"
		                className="close_icon ax-button"
		              >
		                <img src={brown_cancel} className="close_icon" alt="Close" />
		              </button>
		            </div>
		        </div>
		    </DialogActions>
	        
	        <DialogContent>
	          {stepperView === 1 && <GuidelineInfo 
	            userDetails = {userDetails} 
	            isGuidelineChecked = {isGuidelineChecked}
	            setGuidelineChecked = {(val) => setGuidelineChecked(val)}
	            userErrors = {userErrors}
	      
	          />}
	          
	          {stepperView === 2 && <UserDetails 
	            userDetails = {userDetails} 
	            userErrors = {userErrors} 
	            onHandleChange = {e => onHandleChange(e)}
	            onHandleAddressChange = {e => onHandleAddressChange(e)}
	            onHandleProfileChange = {e => onHandleProfileChange(e)}
	            onHandleProfileImageChange = {onHandleProfileImageChange}
	            onDropdownChange = {(name, list) => onDropdownChange(name, list)}
	            newProfilePic = {newProfilePic}
	          />}

	          {stepperView === 3 && <AddObjective 
	            userDetails = {userDetails} 
	            userErrors = {userErrors} 
	            onHandleChange = {e => onHandleChange(e)}
	            onDropdownChange = {(name, list) => onObjectiveDropdownChange(name, list)}
	            onHandleAcountChange = {e => onHandleAcountChange(e)}
	          />}

	          {stepperView === 4 && <Confirmation
	            userDetails = {userDetails} 
	            userErrors = {userErrors} 
	            onHandleChange = {e => onHandleChange(e)}
	            onHandleAllService = {onHandleAllService}
	          />}
	        </DialogContent>

	        <DialogActions className="d-flex justify-content-between align-items-center bg-white">
	          <div className="container-fluid">
	            <div className=" d-flex justify-content-between align-items-center dialog_padding">
	              <div className="d-flex"></div>
	              
	              {stepperView !== 6 && <div className="d-flex">
	                {isShowSkipButton()  && <button className="me-3 tertiary_btn capitalize color-primary-50" onClick={onHandleSkipSubmit}>Skip & submit</button>}
	                <button className="secondary_btn capitalize" onClick={goNext}>{submitText}</button>
	              </div>
	          		}
	            </div>
	          </div>
	        </DialogActions>

	        {isLoading && <Loading />}
      		{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
        </>
	)
}

export default CustomerOnBoarding;