import React, { useRef } from 'react';

const PayWidgetView = () => {

	const iframeRef = useRef(null);

	return (
		<>
			<iframe title = "Payment view" id="iframe" ref={iframeRef} src="../samplesibs.html" width="100%" height="90%" frameBorder="0" allow="geolocation"></iframe>
		</>
	)
}

export default PayWidgetView;