import React, {useState, useEffect} from 'react';
import UpdateCategory from './settings-view/UpdateCategory';
import {InitialCategoryDetails, Category_List} from '../../../../utils/DefaultInitialValues';
import partner_icon from '../../../../assets/Partners.png';

const CategoryDetails = () => {

	const [isShowCategoryDialog, setShowCategoryDialog] = useState(false);
	const [categoryDetails, setCategoryDetails] = useState(null);
	const [categoryList, setCategoryList] = useState(Category_List);

	const addCategory = () => {
		setCategoryDetails(InitialCategoryDetails);
		setShowCategoryDialog(true);
	}

	const onCategoryClick = (item = {}) => {
		const filterItemArray = categoryList.filter((category, index) => category.id === item.id);

		if(filterItemArray.length > 0) {
			setCategoryDetails(filterItemArray[0]);
			setShowCategoryDialog(true);
		}
	}

	const fetchCategories = () => {

	}

	const onUpdateSuccess = () => {
		fetchCategories();
	}

	useEffect(() => {
		fetchCategories();
	}, []);

	return (
		<div className="row">
			<div className="col-lg-8 m-auto">
				<div className="bg-white rounded-3 p-4 my-5">
					
					{categoryList.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center my-5">
						<div className="color-primary-50 body-R">There is no category added yet. Please add a category!</div>
					</div>}
					
					<h4 className="heading-4 color-primary-50 px-lg-3">Categories</h4>
					<div className="w-100 d-flex justify-content-center my-3">
						<button className="tertiary_btn capitalize width-50 m-auto color-primary-50" onClick={addCategory} disabled>
							Add New Category
						</button>
					</div>
					<div className="settings_category_container">
						{categoryList.length > 0 && categoryList.map((cat, index) =>
							<div>
								<div className="my-1 p-2">
				                  	<div className="d-flex justify-content-between align-items-center">
					                    <button className="ax-button d-flex align-items-center" onClick = {() => onCategoryClick(cat)}>
					                    	<div>
					                    		<img src={partner_icon} className="subscription_img" />
					                    	</div>
					                    	<div className="d-flex flex-column align-items-start">
					                    		<div className="subtitle-B py-1 color-primary-50">{cat?.name}</div>
					                    		
					                    	</div>
					                    </button>
					                    <div>
				                      		<button className="ax-button color-secondary-100 fw-bold fs-5 px-2" onClick={() => setCategoryDetails(cat)}>...</button>
				                    	</div>
				                  	</div>
		            			</div>

		        				{(index !== categoryList.length - 1) && <hr />}
							</div>
						)}
					</div>
					
				</div>
			</div>
			{isShowCategoryDialog && <UpdateCategory 
				isOpen = {isShowCategoryDialog} 
				details = {categoryDetails}
				onUpdateSuccess = {onUpdateSuccess}
				handleClose = {() => setShowCategoryDialog(false)} 
			/>
			}
		</div>
	)
}

export default CategoryDetails;
