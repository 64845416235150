import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import {useDispatch} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import CatalystOnBoarding from './CatalystOnBoarding';
import CustomerOnBoarding from './CustomerOnBoarding';

import Loading from '../../../components/loading/Loading';
import GuidelineInfo from './GuidelineInfo';
import PlanDetails from './PlanDetails';
import PaymentView from './PaymentView';

import UserDetails from './UserDetails';
import AddObjective from './AddObjective';

import Confirmation from './Confirmation';

import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';
import CatalystProfileDetails from './CatalystProfileDetails';
import PayWidgetView from '../../payWidgetView/PayWidgetView';
import ArrowBack from '@mui/icons-material/ArrowBack';

import {baseURL} from '../../../actions/Constants';

import {InitialUserDetails, InitialUserErrors} from '../../../utils/DefaultInitialValues';
import {getBoardingHeaderText, getBoardingCTAText, isShowBackBtn, covertToLower, isEmptyJson, getEndEpochAfterGivenDate} from '../../../utils/util';

import {saveAdmin} from '../../../actions/admin.actions';
import brown_cancel from '../../../assets/brown_cancel.png';
import './onBoading.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnBoardingDialog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveAdminAction = saveAdmin;

  const {isOpen = false, onHandleClose, navigateStepper = 0, userinfo = InitialUserDetails} = props;
  
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /*
  const handleClickOpen = () => {
    setOpen(true);
  };
  */

  const handleClose = () => {
    setOpen(false);
    onHandleClose();
  };

  const {
    role = "customer"
  } = userinfo || {};

  const formattedRole = covertToLower(role);

  return (
    <div className="full-dialog">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {formattedRole === "catalyst" && <CatalystOnBoarding 
          onHandleClose = {onHandleClose}
          navigateStepper = {navigateStepper}
          userinfo = {userinfo}
        />
        }

        {(formattedRole === "customer" ||  formattedRole === "admin") && <CustomerOnBoarding 
          onHandleClose = {onHandleClose}
          navigateStepper = {navigateStepper}
          userinfo = {userinfo}
        />
        }
        
      </Dialog>
    </div>
  );
}

export default OnBoardingDialog;