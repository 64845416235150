import React, {useEffect, useState} from 'react';
import Switch from '@mui/material/Switch';

const BasicSwitch = (props) => {
  const {name = "", value = "", onInputChange} = props;

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = (e) => {
    onInputChange(e, e.target.checked);
  };

  return (
    <Switch
      name = {name}
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}

export default BasicSwitch;
