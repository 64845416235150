import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import ActionMenu from '../../../../../components/actionMenu/ActionMenu';
import Loading from '../../../../../components/loading/Loading';

import {getDateFromEpoc} from '../../../../../utils/util';
import Noresult from '../../settings/Noresult';
import {baseURL} from '../../../../../actions/Constants';
import {headerOption} from '../../../../../utils/DefaultInitialValues';

const PaymentView = (props) => {
	const navigate = useNavigate();
	
	const [allPayments, setAllPayments] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);

	const onViewAllPayments = () => {
        navigate("/dashboard/payments");
    }

    const getPurchaseDetails = async () => {
	   	setLoading(true);

	    const URL = `${baseURL}/minded-services/getPurchaseDetails?purchaseStatus=pending_confirmation`;

	    return await fetch(URL, headerOption)
	    .then(response => {
	      const resp = response;

	      const {status = ""} = resp;
	      setLoading(false);
	      if(status === 200 || status === 201) {
	        return response.json().then(res => {
	         // setError(false);
	          	setAllPayments(res);
	        });
	        
	      } else {
	        //Error
	       setError(false);
	      }
	    })
	    .catch((error) => {
	      setLoading(true);
	      console.log('error getPurchaseDetails', error);
	      setError(true);
	       
	    });
  	}

    useEffect(() => {
    	//dispatch(getPaymentsAction());
    	getPurchaseDetails();
    }, []);

    const PaymentList = ({list = []}) => {
    	return (
	    	<div>
				{list.map((item, idx) => {
					const {purchaseId = "", purchaseDate = "", purchaseAmount = 0, transactionCurrency = "EUR", userName = ""} = item || {};
					
					return (
						<>
						<div className="d-flex align-items-center justify-content-between py-1">
							<div>
								<div className="caption-R color-primary-50 py-1 d-flex align-items-center justify-content-between">

									<div>{getDateFromEpoc(purchaseDate)}</div>
									<div className="px-2 caption-R color-primary-50">Invoice - {purchaseId}</div>

								</div>
								
								<div className="subtitle-B color-primary-50 py-1">{userName}</div>
								<div className="body-R color-primary-50 py-1">{purchaseAmount && purchaseAmount?.toFixed(2)} {transactionCurrency}</div>
							</div>

							<div><ActionMenu isDisabled = {true} /></div>
						</div>
						{idx !== 2 && <hr /> }
						</>
					)
				})}
			</div>)
    }

	return (
		<>
			<div className="list_view w-100">
				{allPayments.length === 0 && <Noresult serviceName = "Payments" onCTAClick = {() => console.log("Classes")} />}
				{allPayments.length > 0 && <PaymentList list = {allPayments} />}
			</div>

			{allPayments.length > 0 && <div className="w-100 d-flex justify-content-center">
	            <button className="width-50 tertiary_btn color-primary-50" onClick = {onViewAllPayments}>View All Payments</button>
	        </div>}

	        {isLoading && <Loading />}
	        {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}     
    
        </>
	)
}

export default PaymentView;