import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import FloatSelectView from '../../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../../components/input/Input';
import Textarea from '../../../../../components/textarea/Textarea';
import Loading from '../../../../../components/loading/Loading';

import {baseURL} from '../../../../../actions/Constants';
import brown_cancel from '../../../../../assets/brown_cancel.png';

import {InitialCategoryDetails, InitialCategoryErrors} from '../../../../../utils/DefaultInitialValues';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdateCategory = (props) => {
  	const {
	    details = InitialCategoryDetails,
	    isOpen = false, 
	    handleClose,
	    onUpdateSuccess,
  	} = props;

  	const isEdit = details?.category_id !== "";

  	const [categoryDetails, setCategoryDetails] = useState(details);
  	const [categoryError, setCategoryError] = useState(InitialCategoryErrors);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	const onUpdateCategoryDetails = (e) => {
  		setCategoryDetails({...categoryDetails, [e.target.name] : e.target.value});
  	}

  	const validate = () => {
  		let isFormValid = true;

  		let categoryNameInvalid = false;
  		
  		if(name === "") {
  			categoryNameInvalid = true;
  			isFormValid = false;
  		}

  		setCategoryError({...categoryError,
  			category_name_error: categoryNameInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			UpdateCategoryDetails();
  		}
  	}

  	const UpdateCategoryDetails = async () => {
        const URL= isEdit ? `${baseURL}/updateCategory` : `${baseURL}/addCategory`;
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(categoryDetails), // body data type must match "Content-Type" header
		    headers: {
		    	'Content-Type': 'application/json'
		    }
        })
        .then((res) => {
            //Close Dialog
            setLoading(false);
            const {status = ""} = res;

            if(status === 200 || status === 201) {
	            setError(false);
	            onUpdateSuccess();
	            handleClose();
            } else {
            	setError(true);
            }
        })
        .catch((error) => {
            console.log('error updateUser/insertUser', error);
            setLoading(false);
            setError(true); 
        });
    }

  	const {
  		category_id = "",
  		name = "",
  		category_picture = ""
  	} = categoryDetails || {};

  	const {
  		category_name_error = false
  	} = categoryError || {};

  	const title = isEdit ? "Update Category": "Create New Category";

	return (
		<>
	    <BootstrapDialog
	        onClose={handleClose}
	        aria-labelledby="customized-dialog-title"
	        open={open}
	    >

	    <DialogActions className="w-100 p-4 bg-white">
	        <div className="w-100 d-flex justify-content-between align-items-center">
	          <div>
	   
	          </div>

	          <h6 className="heading-6 color-primary-50">
	            {title}
	          </h6>

	          <div>
	            <button     
	              onClick={handleClose}
	              aria-label="close"
	              className="ax-button close_icon"
	            >
	              <img src={brown_cancel} className="close_icon" />
	            </button>
	          </div>
		    </div>
	    </DialogActions>

	        <DialogContent className="bg-white">
	      		<div className="container-fluid">
	      			<div className="row">
	      				<div className="col-lg-12">
	      					<div className="w-100 my-3">
					            <div className="w-100">
					              <Input
					                name="category_name"
					                label="Category name"
					                placeHolder="xxx" 
					                value={name}
					                onInputChange = {e => onUpdateCategoryDetails(e)}
					                isDisabled ={true}
					              />
					            </div>
					            {category_name_error && <div className="text-danger">! Category name is a required field</div>}
					        </div>
					    </div>
					    <div className="col-lg-12">
						    <div className="my-3">
	        					<h5 className="heading-5 color-primary-50">Category photo</h5>

	        					<div className="w-100 d-flex align-items-center my-2">
						  			<div className="profile_img_container my-3">
						  			</div>
						 
							        <div className="mx-5">
							          <button type="button" className="tertiary_btn rounded-pill color-primary-100" disabled>CHOOSE PHOTO</button>
							        </div>
						    	</div>

						        
	      					</div>
	      				</div>
		            </div>    
				</div>

	        </DialogContent>

	        <DialogActions className="bg-white">
	        	<div className="py-3 d-flex align-items-center justify-content-end w-100 px-3">
		        	<button onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2" disabled>
		            	Cancel
		          	</button>

		          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize" disabled>
		           		{isEdit ? "Update" : "Add"}
		          	</button>
	            </div>
	        </DialogActions>

      	</BootstrapDialog>
      	{isLoading && <Loading />}
	    {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
    </>
	)
}

export default UpdateCategory;