import {getDurationDifferentFromCurrent} from '../../../../utils/util';

const CatalystListView = ({data = []}) => {

	return (
		<>
			{data && data.slice(0,3).map((item, index) => {
				const catArray = item?.catalystDetails?.serviceCategory.map(cat => cat.name);
				return (
					<>
						<div className="d-flex justify-content-between align-items-center my-2">
							<div>
								<div className="caption-2 color-primary-20">Joined {getDurationDifferentFromCurrent(item?.createdOn)}</div>
								<div className="caption-2 color-primary-50 pt-1">
									<div className="subtitle-B color-primary-50 text-left">
										{item.name || "-"}
									</div>
								</div>

								<div className="body-R color-primary-50 pt-1">{catArray.join(", ")}</div>
							</div>

							<div>
								<button className="ax-button color-secondary-100 fs-6 fw-bold" onClick = {() => console.log(item.name)}>
								...
								</button>
							</div>
						</div>
						{(index !== data.length -1) && <hr />}
					</>
				)}
			)}
		</>
	)
}

export default CatalystListView;