import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import HelmetDetails from "../../components/HelmetDetails";
import PurchaseDialog from '../events-classes/servicePurchaseDialog/PurchaseDialog';

import AuthDialog from '../../components/authenticate/AuthDialog';
import ScheduleDialog from '../events-classes/scheduleDialog/ScheduleDialog';
import CheckPickerView from '../../components/checkPicker/CheckPickerView';

import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import ClassListView from '../../components/classListView/ClassListView';

import DialogView from '../../components/dialog/DialogView';
import FilterView from '../../components/filters/FilterView';
import FeatureCard from '../../components/feature-card/FeatureCard';
import HelmetText from '../../components/helmet-text/HelmetText';

import Loading from '../../components/loading/Loading';
import TabView from '../../components/tabView/TabView';

import {
	CalculateDuration,
	ConvertDaysArray,
	ConvertPrivateTimesArray, 
	getAuthenticateHeader, 
	generateEventsDropdownList, 
	getMinimumTicketPrice,
	getCatalystDetailsServiceTypeFromActiveTab,
	isEmptyJson
} from '../../utils/util';

import Noresult from '../../components/no-result/Noresult';

import {Catalyst_Details_Service_Tab_List, headerOption} from '../../utils/DefaultInitialValues';
import {getServices} from '../../actions/services.actions';
import {getSchedules} from '../../actions/schedules.actions';

import avatar from '../../assets/avatar.png';
import location from '../../assets/Location pin.png';

import {baseURL} from '../../actions/Constants';
import './catalystDetails.css';

const CatalystDetails = () => {
	const dispatch = useDispatch();
	const getServicesAction = getServices;
	const getSchedulesAction = getSchedules;

	const { id } = useParams();
	const [catalyst, setCatalyst] = useState({});

	const [isShowAboutDialog, setShowAboutDialog] = useState(false);
    
    const [activeServiceTab, setActiveServiceTab] = useState(0);
    const [allServices, setAllServices] = useState([]);
    const [bestPackages, setBestPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});

    const [service_active_Type, setActiveServiceType] = useState("");
    
    const [serviceLocationList, setServiceLocationList] = useState([]);
    const [selectedLocationsFilters, setSelectedLocationsFilters] = useState([]);
    const [filterServices, setAllFilterServices] = useState([]);

    const [isError, setError] = useState(false);
    const [bookingNavigationStepper, setBookingNavigationStepper] = useState(-1);
    
    const [scheduleDetails, setScheduleDetails] = useState({});
   // const [bookingDetails, setBookingDetails] = useState({});

    const [purchaseId, setPurchaseId] = useState("");
    
    const [isShowDialog, setShowDialog] = useState(false);
	const [isShowLoginDialog, setShowLoginDialog] = useState(false);
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);
	
    const [allSchedules, setAllSchedules] = useState([]);
    const [filterSchedules, setFilterSchedules] = useState([]);

    const [startEpoc, setStartEpoc] = useState("");
	const [endEpoc, setEndEpoc] = useState("");

    const [isLoading, setLoading] = useState(false);
    const [allDropdownList, setAllDropdownList] = useState({});

    const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

	const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    const {
       details,
       loading,
       error
    } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));

	const {
       details: schedulesDetails,
       loading: schedulesLoading,
       error: schedulesError
    } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

    const getBestPackages = (list = []) => {
    	const newPackageList = [];
    	
    	const packages = list.map((item, index) => {
    		
    		item?.servicePriceOptions.map((option, idx) => {
    			if(option.pricingType === "package") {
    				
    				{option.packages.map(eachPck => {
						const obj = {};
    					obj.serviceId = item?.serviceId;
    					obj.serviceName = item?.serviceName;
    					obj.serviceLocation = item?.serviceLocation;
    					obj.package = eachPck;
    					newPackageList.push(obj)
    				});
    				}
    				
    			}
    		});
    	});

    	setBestPackages(newPackageList);
    }

    const onLoginSuccess = (isSignupView, userinfo) => {
		setShowDialog(true);
	}

    const getCatalystById = async () => {
		setLoading(true);
		const URL = `${baseURL}/minded-user/getUserById/${id}`;

		const myHeaders = getAuthenticateHeader();
 		
      	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => {
		        setCatalyst(res);
		      });
		    } else {
		      	console.log('error getCatalystById', error);
	      		//setLoading(false);
	      		setError(true);
		    }
		  })
		  .catch((error) => {
		  	console.log('error getCatalystById', error);
	      	setLoading(false);
	      	setError(true);
		});
	}

	const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    const onLocationCheckboxSelect = (name, val) => {
    	setSelectedLocationsFilters(val);
    }

    const handleDateChange = (stDate = "", edDate = "") => {
    	const type = getCatalystDetailsServiceTypeFromActiveTab(activeServiceTab);

    	if(stDate && edDate) {
	    	const startDateEpoc = new Date(stDate).getTime();
	    	const endDateEpoc   = new Date(edDate).getTime();

	    	setStartEpoc(startDateEpoc);
	    	setEndEpoc(startDateEpoc);
	    	
	    	dispatch(getSchedulesAction(id, "", type, "", startDateEpoc, endDateEpoc, "", ""));
    	}
    }

    const createLocationArray = () => {
    	let locList = [];
    	
    	const filterLocData =  allServices.map(item => {
    		if( item?.serviceLocation?.city &&  item?.serviceLocation?.city !== "") {
    			const obj = {};
		        obj.id = item?.serviceLocation?.city;
		        obj.name = item?.serviceLocation?.city;

		        const index = locList.findIndex(object => {
		            return object.id === obj.id;
		        });

		        if(index === -1) {
		            locList.push(obj)
		        }
    		}
    	});

    	setServiceLocationList(locList);
    }

    const TabPanel = (index) => {
		return (
			<>
				<div className="container-fluid my-3">
					<div>
						<FilterView 
							onHandleDateChange = {handleDateChange} 
							allDropdownList = {allDropdownList}
							onCheckboxSelect = {onCheckboxSelect}
							isShow = {false}
						/>
					</div>
				</div>
			
				<ClassListView 
					onHandleCTAClick = {(schedule) => onHandleCTAClick(schedule)}
					serviceList = {filterSchedules}
					service_Type = {activeServiceTab}
				/>
			</>
		);
	}

	useEffect(() => {
		setAllServices([]);
		const serviceType = getCatalystDetailsServiceTypeFromActiveTab(activeServiceTab);
		setActiveServiceType(serviceType);
		dispatch(getServicesAction(id, "", serviceType, "", "", "", "active"));
		dispatch(getSchedulesAction(id, "", serviceType, "", startEpoc, startEpoc, "", ""));
	}, [activeServiceTab]);

    useEffect(() => {
    	if(Array.isArray(details)) {
    		setAllServices(details);
    		getBestPackages(details);
    	}
    }, [details]);

    useEffect(() => {
    	createLocationArray();
    	getFilterServiceArray();
    }, [allServices]);

    useEffect(() => {
    	if(Array.isArray(schedulesDetails)) {
    		setAllSchedules(schedulesDetails);
    	}
    }, [schedulesDetails]);

    useEffect(() => {
    	if(!isEmptyJson(scheduleDetails)) {
    		onHandleBookNow();
    	}
    }, [scheduleDetails]);

    useEffect(() => {
    	if(Array.isArray(allSchedules)) {
    		const obj = generateEventsDropdownList(allSchedules);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allSchedules]);

    useEffect(() => {
    	getFilterServiceArray();
    	
    }, [selectedLocationsFilters]);

    const getFilterServiceArray = () => {
    	if(selectedLocationsFilters.length === 0) {
    		setAllFilterServices(allServices);
    		return;
    	}
    	const filterArray = allServices.filter((item, index) => selectedLocationsFilters.includes(item?.serviceLocation?.city));
    	setAllFilterServices(filterArray);
    }

    useEffect(() => {
    	applyFilter();
    }, [selectedFilters]);

	useEffect(() => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	getCatalystById();
    }, []);

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = allSchedules;

    	if(selectedCatalyst.length) {
    		filterArray = allSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterSchedules(filterArray);
    }

    const onHandleScheduleNow = (purchaseInfo = {}) => {
    	setPurchaseId(purchaseInfo?.purchaseId);
		setShowScheduleDialog(true);
	}

     useEffect(() => {
    	if(!isEmptyJson(scheduleDetails)) {
    		onHandleBookNow();
    	}
    }, [scheduleDetails]);

	const onHandleCTAClick = (schedule = {}) => {
		const newSchedule = schedule;
		setScheduleDetails(newSchedule);
	}

	const onHandleBookNow = () => {
    	if(isUsedLoggedIn) {
    		setShowDialog(true);
    	} else {
    		setShowLoginDialog(true);
    	}
    }

	const handlePackageSelect = (pck = {}) => {
		setSelectedPackage(pck);

		const filterSchedule = allSchedules.filter(item => item?.serviceId === pck?.serviceId);
		if(filterSchedule && filterSchedule[0]) {
			setScheduleDetails(filterSchedule[0]);
			//setShowDialog(true);
			onHandleBookNow();
		}
	}

	const ViewTabPanel = (service_Type = "CLASS") => {
		
		return (
			<div className="list_view">
				
				{filterServices?.length === 0 && <div> <Noresult serviceName = {service_Type} onCTAClick = {() => console.log("Classes")} /> </div>}
				
				{filterServices?.length > 0 && filterServices.map((eachCls, indexVal) => {
					const type = eachCls?.serviceType || "";
					const convertedDaysArray = ConvertDaysArray(eachCls, type);
					const timeVal = ConvertPrivateTimesArray(eachCls, type);
					const minPrice = getMinimumTicketPrice(eachCls);
					const serviceLocation = eachCls?.serviceLocation;

					return (
						<>
							<div className="catalyst_service_details_wrapper">
								<div>
									{eachCls?.serviceScheduleDetails?.serviceMode === "physical" && 
										<div className="body-R color-primary-50 py-2 d-flex align-items-center">
											<img src={location} style={{width: '24px', height: '24px', position: 'relative', top: '0px'}} alt="" />
											{serviceLocation?.venueName !== "" ? `${serviceLocation?.venueName}, ${serviceLocation?.city} ` : eachCls?.serviceScheduleDetails?.serviceLocation?.address?.label}
										</div>
									}

									{eachCls?.serviceScheduleDetails?.serviceMode === "online" && 
										<div className="body-R color-primary-50 py-2">
											Online
										</div>
									}
									
									<Link to={`/events-classes/service-details/${eachCls.serviceId}`}
										className="ax-button subtitle-B color-primary-50 text-left" 
									>
										{eachCls?.serviceName}
									</Link>
									
									{type !== "PRIVATE" && <div className="body-R color-primary-50 py-2">{CalculateDuration(eachCls?.startTime,eachCls?.endTime)} - {convertedDaysArray.join(", ")} </div>}
									{type === "PRIVATE" && <div className="body-R color-primary-50 py-2">{timeVal} - {convertedDaysArray.join(", ")} </div>}
								</div>

								<h6 className="heading-6 color-primary-50">{minPrice === "Free" ? minPrice : `Price from ${minPrice}`} </h6>
								
							</div>
						
							{(indexVal !== (filterServices.length - 1)) && 
								<div className="">
									<hr />
								</div>
							}
						</>
					)
				})}
			</div>
		)
	}
    
    const {
	  	name = "",
	  	profileDescription = {},
	  	catalystDetails = {
		    planDetails : {

		    },
	    	languages : [],
	    	mode: "",
		    locations : [
			    {
			      address : "",
			      city : "",
			      country : "",
			      coordinate : {}
			    }
	    	],
	    	serviceCategory : []
	  	}
    } = catalyst || {};

    const {
    	aboutMe = "",
    	publicName = "",
    	profilePic = ""
    } = profileDescription || {};

    useEffect(() => {
    	if(!isShowDialog) {
    		setBookingNavigationStepper(-1);
    	}
    }, [isShowDialog]);

    const isUsedLoggedIn = loggedUser && loggedUser?.userId;

    const descLength = (aboutMe) ? aboutMe.length : 0;

    console.log("catalyst details --->", catalyst);

	return(
		<>
			<HelmetDetails title="Catalyst Details | Minded." />
			<HelmetText />
			
			<div className="main-content-padding">
				<div className="container-fluid">
					<div className="row">
						
						<div className="col-lg-8 col-md-12 col-12">
							<div className="mobile_view">
								<h1 className="heading-1 color-primary-50 text-center my-3 capitalize">{publicName || name || "-"}</h1>
							</div>

							<div className="desktop_view">
								<h1 className="heading-1 color-primary-50 my-3 capitalize">{publicName || name || "-"}</h1>
							</div>

							<div className="color-primary-50 body-R catalyst-details-feature-container m-auto">
								{catalystDetails?.serviceCategory?.slice(0, 4).map((item, index) => <FeatureCard name = {item?.name} />)}
							</div>

							<div className="color-primary-50 body-R w-100 d-flex flex-wrap my-2">
								{catalystDetails?.locations?.map((eachLocation, index) => {
									return (
										<>
											{eachLocation?.city && <div className="color-primary-50 py-1 py-lg-3">
												<div className="px-2">
													<div className="color-primary-50 caption-B text-center">{eachLocation?.city || "-"}</div>
												</div>
											</div>}
											{index !== (catalystDetails?.locations.length - 1) && <div className="d-flex align-items-center py-0">|</div>}
											{index === 0 && !eachLocation?.city && <div className="white-text">-</div>}
										</>
									)
								})}
							</div>
							
							<div className="text-justify px-3 text-justify">
								{/*<h4 className="heading-4 color-primary-50">About</h4>*/}
								<div className="color-primary-50 body-R py-2 text-justify">
									{descLength > 320 ? aboutMe.slice(0, 320) : aboutMe}
									{descLength > 320 && <span>...</span>}

								</div>
								{descLength > 320 && <button className="secondary_btn my-3 color-primary-50 capitalize learn_more" onClick={() => setShowAboutDialog(true)}>
									Learn More
								</button>
								}
							</div>

						</div>

						<div className="col-lg-4 col-md-12 col-12 corners-right flex_container_50">
							<div className="catalyst_details_profile_img">
								<img src={profilePic || avatar} alt="" />
							</div>
							
						</div>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<div className="my-3 w-100 d-flex justify-content-between">
								
								<div className="w-100 d-flex justify-content-between align-items-center">
									<h4 className="heading-4 color-primary-50">Offer/Services</h4>
									<div>
										<CheckPickerView 
											name="location" 
											label = "Locations" 
											list = {serviceLocationList} 
											onCheckboxSelect = {(name, val) => onLocationCheckboxSelect(name, val)} 
										/>
				
			              			</div>
								</div>
							</div>
							<div className="color-secondary-80_bg rounded-4 py-3 px-lg-5 px-md-3 px-4">
								
								<TabView 
									children = {(service_Type) => ViewTabPanel(service_Type)} 
									service_Type = {service_active_Type} 
									tabs = {Catalyst_Details_Service_Tab_List} 
									onTabClick = {(val) => setActiveServiceTab(val)} 
								/>

							</div>
						</div>
					</div>
				</div>
			
				<div className="w-100">
					<h4 className="heading-4 color-primary-50 text-left mt-5 mt-lg-0 mt-md-0">Schedule</h4>
				
					<TabView 
						children = {(service_Type) => TabPanel(service_Type)} 
						tabs={[]}
						service_Type = {service_active_Type}  
						onTabClick = {() => console.log("hi")}  
						isHidden = {true}
					/>
				</div>

				<div className="w-100">
					<h4 className="heading-4 color-primary-50 text-left mb-5">Best Value Packages</h4>

					<div className="best-offer-container">
						{bestPackages && bestPackages.length === 0 && <div className="body-R color-primary-50">No Package available</div>}
						
						{bestPackages && bestPackages.length > 0 && bestPackages.map((item, index) => 
							<div className="flex_container_70 bg-white p-5 rounded-3 text-center">
								<div className="text-center w-100">
									<h5 className="heading-5 color-primary-50">{item?.package?.packageName} | {item?.package?.noOfSessions}x</h5>
									<div className="color-primary-50 caption-2">Includes {item?.package?.noOfSessions} {item?.serviceName}</div>
								</div>

								<div className="w-100">
									<h4 className="heading-4 color-primary-50">{item?.package?.packagePrice} {item?.package?.priceCurrency}</h4>
									<div className="color-primary-50 body-R">Valid for {item?.package?.packageValidityDuration} months</div>
								</div>

								<div className="w-100">
									<button className="tertiary_btn color-primary-50 w-75 m-auto capitalize" onClick = {() => handlePackageSelect(item)}>Buy</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{isShowLoginDialog && <AuthDialog 
				isOpen = {isShowLoginDialog} 
				onHandleClose = {(val) => setShowLoginDialog(false)} 
				onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
			/>}

			{isShowDialog && <PurchaseDialog 
				isOpen = {isShowDialog}
				scheduleDetails = {scheduleDetails} 
				onHandleClose = {() => {setShowDialog(false);setScheduleDetails({});setBookingNavigationStepper(-1)}} 
				onHandleScheduleNow = {(purchaseInfo) => onHandleScheduleNow(purchaseInfo)}
				selectedPackage={selectedPackage}
				bookingNavigationStepper = {bookingNavigationStepper}

			/>}

			{isShowScheduleDialog && <ScheduleDialog 
				isOpen = {isShowScheduleDialog} 
				onHandleClose={() => setShowScheduleDialog(false)}
				purchaseId = {purchaseId}
			/>}

			{isShowAboutDialog && <DialogView onHandleClose = {() => setShowAboutDialog(false)} show = {isShowAboutDialog} about = {profileDescription?.aboutMe} />}

			{(loading || schedulesLoading || isLoading) && <Loading />}
			{(error || schedulesError || isError) && <CustomizedSnackbars isShow={error || schedulesError|| isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}	
		</>
	)
}

export default CatalystDetails;