import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from "rsuite";
import Table from '../../../../../components/table/Table';

import {getDateFromEpoc} from '../../../../../utils/util';
import {headerOption} from 	  '../../../../../utils/DefaultInitialValues';

import {baseURL} from '../../../../../actions/Constants';
import right_arrow from '../../../../../assets/thin arrow - right.png';

const AllPayments = () => {
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [allPayments, setAllPayments] = useState([]);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const columns = [
	    {
	      name: "purchaseId",
	      label: 'ID',
	      options: {
	       filter: true,
	       sort: true,
	      }
	    },
	    {
	      name: "userId",
	      label: 'Name',
	      options: {
       		filter: true,
       		sort: true,
       		customBodyRender: (value, tableMeta, updateValue) => {
              	return (
	                <span className="color-primary-50">
	                  {'Mario Pravto Junior'}
	                </span>
              	);
      		}
	      }
	    },
	    {
	      name: "purchaseDate",
	      label: 'Purchase Date',
	      options: {
	       filter: true,
       		sort: true,
       		customBodyRender: (value, tableMeta, updateValue) => {
              	return (
	                <span className="color-primary-50">
	                  {getDateFromEpoc(value)}
	                </span>
              	);
      		}
	      }
	    },
	    {
	      name: "purchaseAmount",
	      label: 'Amount',
	      options: {
	        filter: true,
	        sort: true,
	        customBodyRender: (value, tableMeta, updateValue) => {
              	return (
	                <span className="color-primary-50">
	                  {value.toFixed(2)} EUR
	                </span>
              	);
      		}
	      }
	    },
	    {
	      name: "purchaseStatus",
	      label: 'Status',
	      options: {
	       filter: true,
	       sort: true
	      }
	    }    
	];

	const getPurchaseDetails = async () => {
	   	setLoading(true);

	    const URL = `${baseURL}/minded-services/getPurchaseDetails?purchaseStatus=pending_confirmation`;
	    
	    return await fetch(URL, headerOption)
	    .then(response => {
	      const resp = response;

	      const {status = ""} = resp;
	      setLoading(false);
	      if(status === 200 || status === 201) {
	        return response.json().then(res => {
	         // setError(false);
	          	setAllPayments(res);
	        });
	        
	      } else {
	        //Error
	       setError(false);
	      }
	    })
	    .catch((error) => {
	      setLoading(true);
	      console.log('error bookSchedule', error);
	      setError(true);
	       
	    });
  	}

    useEffect(() => {
    	getPurchaseDetails();
    }, []);


	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "payments":
					obj.path = "/dashboard/payments";
					obj.label = "All Payments";
					crumbArray.push(obj);
					break;
				default: break;
			}

			setCrumb(crumbArray);
		});
	}

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}}  alt = "" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Payments</h1>
			
			<div className="my-5">
			<Table headers={columns} rowData={allPayments} />
			</div>
		</div>
	)
}

export default AllPayments;

