const ServiceTypeArray = [
	{
	 	title :"1:1 Session",
	 	details: "One-on-One sessions booked based on availability.",
	 	icon: "",
    value : "PRIVATE"
	},
	{
	 	title :"Group Session",
	 	details: "A group session that can recur. Clients book any session they want to join.",
	 	icon: "",
    value : "CLASS"
	},
	{
	 	title :"Event",
	 	details: "Planned ocurrence for interaction, celebration, learning, or engagement.",
	 	icon: "",
    value : "EVENT"
	},
	{
	 	title :"Workshop",
	 	details: "Interactive session for hands-on skill and knowledge acquisition.",
	 	icon: "",
    value : "WORKSHOP"
	}
]

const GuidanceArray = [
	{
	 	title :"Privacy Policy",
	 	details: "Community guidelines and code of conduct",
	 	icon: "",
    path: "https://www.theminded.club/_files/ugd/0c104a_5d596572b9b647a3b5c4a238283bce38.pdf"
	},
	{
	 	title :"Terms & Condition",
	 	details: "Rules and regulations regarding application and usage",
	 	icon: "",
    path: "https://www.theminded.club/_files/ugd/0c104a_e818e156abc641c386fec273c5c3dd97.pdf"
	},
	{
	 	title :"FAQ",
	 	details: "How to dos, and other questions",
	 	icon: ""
	},
	{
	 	title :"Email us",
	 	details: "Community guideline and code of conduct",
	 	icon: "",
    href:"mailto:hello@theminded.club"
	}
]

export {

  GuidanceArray,
	ServiceTypeArray
};