import {useEffect, useRef} from 'react';
import AddGuest from '../../../components/addGuest/AddGuest';

const AdditionalInfo = (props) => {
	const dialogRef = useRef();

	const {
        removeItem,
        guestList = [],
        onHandleItemChange,
        onImageUpdate,
        addGuest
	} = props;

	const uploadProfile = (e, index) => {
        const newPic = e.target.files[0];
        onImageUpdate(index, newPic)
    }

    useEffect(() => {
    	try {
      		dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    	} finally {

    	}
  	}, []);

	return (
		<div className="container dialog_padding" ref={dialogRef}>
      		<div className="row">
        		<div className="col-lg-6 m-auto">
          			<div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 border_50">
			        	<h5 className="heading-5 color-primary-50">3.1 Guest(s)</h5>
			        	
			        	{guestList.map((guest, index) =>
			        		<>
			        			<AddGuest 
			        				guest = {guest}  
			        				index={index}
			        				onHandleItemChange = {onHandleItemChange} 
			        				uploadProfile = {uploadProfile} 
			        				removeItem={removeItem} 
			        			/>
			        			<h5 className="w-100 heading-5 color-primary-50">Profile image guidelines</h5>
      
						      	<div className="w-100 upload_img_container py-2">
							        <ul className="width-50 body-R color-primary-20">
							          <li>At least 250x250 pixels</li>
							          <li>JPG, PNG formats only</li>
							          <li>Maximum size of 2MB</li>
							        </ul>
						       
							        <ul className="width-50 body-R color-primary-20">
							          <li>Do not make photo public without explicit consent</li>
							          <li>Show eyes and face clearly</li>
							        </ul>
						      	</div>
						      	{index !== (guestList.length - 1) && <hr />}
			        		</>
			        	)}


			        	
			        	<div className="w-100">
			        		<button type="button" className="capitalize tertiary_btn color-primary-50 w-100" onClick={addGuest}>Add Guest</button>
			        	</div>
			        	
			        </div>
			    </div>
			</div>
		</div>
	)
}

export default AdditionalInfo;