import React, {useEffect, useState} from 'react';
import { DatePicker } from 'rsuite';
import {getEpocFromMoment} from '../utils/util';

const DatePickerView = (props) => {

  const {onHandleDateUpdate, name = "", value = "", disabledDate = null} = props;
  const [selectedDate, setSelectedDate] = useState(value !== "" ? new Date(value) : null);

  useEffect(() => {
    if(selectedDate) {
      onHandleDateUpdate(name, selectedDate);
    }
  },[selectedDate]);

  useEffect(() => {
    if(value === "") return;

    if(getEpocFromMoment(selectedDate) !== getEpocFromMoment(new Date(value))) {
      setSelectedDate(new Date(value))
    }
  }, [value]);

  const isBeforeCurrentDate = date => {
    const currentDate = new Date();
    var previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);


    var maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() + 1);

    if(date < previousDate) {
      return true;
    }
    else if(date > maxDate) {
      return true;
    }
  }

  // Function to determine whether a date should be disabled
  const disabledDates = date => isBeforeCurrentDate(date);

  return (
   <DatePicker
      value={selectedDate}
      onChange={setSelectedDate}
      cleanable={false} 
      format='dd-MM-yyyy'
      style={{width: '100%'}}
      block
      disabledDate={disabledDates}
    />
  );
};

export default DatePickerView;