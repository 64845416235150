import {useState} from 'react';
import pic_1 from '../../../assets/catalyst_profile.png';
import { Link } from 'react-router-dom';
import ClassBookingDialog from '../../events-classes/servicePurchaseDialog/PurchaseDialog';
import './urban.css';

const EventUrbanNew = (props) => {
	const {name = ""} = props;
	const data = [];
	const [isShowDialog, setShowDialog] = useState(false);

	return (
		<>
			<div className="urban-details">
				<div className="urban-details-image-container">
					<section>
						<h1 className="heading-1">Urban Retreat - After Work</h1>
					</section>
				</div>

				<section>
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-8">
								
								<div className="urban-card">
									<div className="row">
										<div className="col-lg-6 col-md-6">
											<div>
												<p>Timing</p>
												<h5>Event Sat - 19:00 - 20:00</h5>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div>
												<p>Event at</p>
												<h5>Memo | Principe Real</h5>
											</div>
										</div>
									</div>

									<div className="row py-5">
										<div className="col-lg-6 col-md-6">
											<div>
												<p>Ticket from</p>
												<h5>12 EUR</h5>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div className="d-flex align-items-center">
												<button className="ax-button green_text" onClick={()=> setShowDialog(true)}>Book Now</button>
											</div>
										</div>
									</div>
								</div>

								<div className="urban-card my-4">
									<h5>About</h5>
									<div className="py-3">Every Wednesday, we invite you to join us for an evening of rejuvenation and unwind from the day's hustle and bustle. Experience a relaxing after-work session with Isa and her amazing guests.</div>

									<div className="py-3">Join us for an evening of relaxation while having a great time with like-minded people</div>
									<button className="ax-button pt-4 green_text">READ MORE</button>
								</div>

							</div>


							<div className="col-lg-4">
	        					<div className="p-5 urban-card ">
									<div>
										<img src={pic_1} className="w-75 py-3" alt="" />
										<div className="py-3">Maria Fonseca Freitas</div>
										<div className="">a dedicated Ashtanga Yoga teacher who has spent the last 20 years sharing her knowledge and passion for the traditional practice with students around the world</div>
										<button className="ax-button pt-2 green_text">READ MORE</button>
									</div>
	        					</div>
							</div>
						</div>
					</div>
				</section>

				<section>
					<h2>Upcoming guests</h2>
					<div className="start-here-catalyst-card-container py-5">
						{data.map((catalyst, index) => {
							return (
								<div className="p-5 start-here-catalyst-card" key={index}>
									<Link to={`/catalyst/${catalyst.name}`}>
										<img src={pic_1} className="start-here-catalyst-img py-3" alt="" />
										<h5 className="py-3">{catalyst.name}</h5>
										<div>{catalyst.description}</div>
										<button className="ax-button pt-4 green_text">READ MORE</button>
									</Link>
								</div>
							)
						})}

					</div>
				</section>
			</div>

			{isShowDialog && <ClassBookingDialog isOpen = {isShowDialog} onHandleClose={() => setShowDialog(false)} />}
			
		</>
	)
}

export default EventUrbanNew;