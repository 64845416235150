import React, {useState, useEffect} from 'react';
import Noresult from '../../../../../../../components/no-result/Noresult';
import ListView from '../../../../ListView';

const BookingView = () => {
	const [allBookings, setAllBookings] = useState([]);

	return (
		<>
		
			<div className="container-fluid">
				<div className="card-details rounded-4 bg-white p-5">
					{allBookings.length === 0 && <div className="d-flex justify-content-center color-primary-50">Upcoming Feature</div>}
					{/*
					<div className="d-flex justify-content-between align-items-center">
						{allBookings.length === 0 && <div className="d-flex justify-content-center w-100"> <Noresult serviceName = "Classes" onCTAClick = {() => console.log("Classes")} /> </div>}					
						{allBookings.length > 0 && <ListView list = {[]} onHandleEventScheduleClick ={(val) => console.log(val)} onHandleServiceTitleClick = {() => console.log("hello")} />}
					</div>
					*/}
				</div>
			</div>
		</>
	)
}

export default BookingView;
