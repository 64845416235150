import './uploadImg.css';

const UploadImage = (props) => {

	const {isSingle = false, pic = "", uploadPicture, serviceName = "", isDisabled = false} = props;

	const fileChangedHandler = event => {
    	let file = event.target.files[0];
    	let reader = new FileReader();

    	reader.onload = function(event) {
      		//	setFile(e.target.result);
    	};
    	reader.readAsDataURL(file);

 		const extention = file.name.split('.').pop();

	 	if (extention !== "png" && extention !== "jpg" ) {
	 		window.alert("File does not support. You must use .png or .jpg ");
	      	return false;
	   	}
	   	if (file.size > 2097152) {
	    	window.alert("Image size should not be more than 2 MB");
	    	return false;
	   	}
	   	uploadPicture(event);
  	}; 

	return (
		<>
			<div className="upload_img_container my-4 w-100">

				{pic === "" && <div className="profile_img_container"></div>}
				{pic !== "" && <img src={pic} className="profile_img_container" />}

				<form className="mx-0 mx-lg-5 mx-md-5 my-3 mx-auto d-flex align-items-center" method="post" encType="multipart/form-data">
				  <input 
				  	disabled={isDisabled}
				  	name="upload"
				  	id="upload" 
				  	type="file" 
				  	className="ax-button opacity_0 profile_img" 
				  	onChange = {e => fileChangedHandler(e)} 
				  	accept=".jpg, .jpeg, .png" 
				  />
				  <label for="upload">
				    <span type="button" className="tertiary_btn color-primary-100 p-3">CHOOSE IMAGE</span>
				  </label>
				</form>
			</div>
		</>

	)
}

export default UploadImage